// Wallet.js

import React, { useState } from 'react';
import { AiOutlineGift, AiOutlineArrowRight } from 'react-icons/ai';
import { BiDollarCircle } from 'react-icons/bi';
import { useUser } from "../context";
import TransferHistory from './history/TransferHistory';
import TransferDetail from './transactions/TransferDetail';
import {
    FaPiggyBank,
    FaHandHoldingUsd,
    FaRegLightbulb,
    FaRegCalendarAlt,
    FaChartLine,
    FaWallet,
    FaGift,
    FaBell,
    FaUserFriends,
    FaChartPie,
    FaClipboardList,
    FaQuestionCircle,
    FaShieldAlt // Imported FaShieldAlt for security announcement
} from 'react-icons/fa';

function formatCurrency(amount, currencyCode, currencySymbol) {
    // Default to 'USD' if currencyCode is invalid or undefined
    const validCurrencyCode = currencyCode && currencyCode !== 'Currency' ? currencyCode : 'USD';

    // Round the amount to the nearest whole number
    const roundedAmount = Math.round(amount);

    const formatter = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: validCurrencyCode,
        minimumFractionDigits: 0, // Ensure no decimal places
        maximumFractionDigits: 0, // Ensure no decimal places
    });

    // Use formatToParts to break the formatted string into parts
    const parts = formatter.formatToParts(roundedAmount);

    // Adjust the currency symbol and add a space after it
    const formattedParts = parts.map(part => {
        if (part.type === 'currency') {
            // Replace the currency symbol and add a space after it
            return currencySymbol ? currencySymbol + ' ' : part.value + ' ';
        }
        return part.value;
    });

    // Join the parts back into a formatted string
    const formattedAmount = formattedParts.join('');

    return formattedAmount;
}

const Wallet = () => {
    const { user } = useUser();
    const [selectedTransferId, setSelectedTransferId] = useState(null);

    const handleViewTransferDetails = (transferId) => {
        setSelectedTransferId(transferId);
    };

    const handleBackToHistory = () => {
        setSelectedTransferId(null);
    };

    // Find the primary account or default to USD
    const primaryAccount = user?.accounts?.find(account => account.isPrimary);
    const primaryBalance = primaryAccount ? primaryAccount.balance : 0;
    const primaryCurrency = primaryAccount ? primaryAccount.currency : 'USD'; // Default to 'USD'
    const primarySymbol = primaryAccount?.currencySymbol || '$'; // Default to '$'

    // Determine the account with the highest balance
    const highestBalanceAccount = user?.accounts?.reduce((prev, current) => {
        return (prev.balance > current.balance) ? prev : current;
    }, primaryAccount);

    // Check if the highest balance account is not the primary
    const isHighestNotPrimary = highestBalanceAccount && highestBalanceAccount.currency !== primaryCurrency;

    return (
        <div className="flex">
            {/* Main Content Area */}
            <div className="flex-grow">
                {/* Wallet Overview */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    {/* Primary Currency Balance Card */}
                    <div
                        className="relative bg-white p-6 rounded-lg shadow-lg flex items-center"
                        style={{
                            backgroundImage: 'url(/images/balance_background.jpg)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className="relative z-10">
                            <h5 className="text-sm font-semibold text-gray-700">Current Balance</h5>
                            <p className="text-2xl font-bold text-gray-900">
                                {formatCurrency(primaryBalance, primaryCurrency, primarySymbol)}
                            </p>
                        </div>
                        <div className="ml-auto">
                            <div className="rounded-full bg-emerald-500 bg-opacity-20 p-2">
                                <FaWallet className="text-emerald-500 text-xl" />
                            </div>
                        </div>
                        {/* Conditional Span for Highest Balance Currency */}
                        {isHighestNotPrimary && (
                            <div className="absolute bottom-2 left-2 bg-gray-100 text-emerald-700 text-xs px-2 rounded flex items-center space-x-1 shadow-sm">
                                <FaRegLightbulb className="text-emerald-500" />
                                <span>Available: {formatCurrency(highestBalanceAccount.balance, highestBalanceAccount.currency, highestBalanceAccount.currencySymbol)}</span>
                            </div>
                        )}
                        <div
                            className="absolute inset-0 bg-white opacity-50 rounded-lg"
                            style={{ mixBlendMode: 'multiply' }}
                        ></div>
                    </div>

                    {/* Bonus Card */}
                    <div
                        className="relative bg-white p-6 rounded-lg shadow-lg flex items-center"
                        style={{
                            backgroundImage: 'url(/images/bonus_background.jpg)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className="relative z-10">
                            <h5 className="text-sm font-semibold text-gray-700">Bonus Balance</h5>
                            <p className="text-2xl font-bold text-gray-900">
                                {formatCurrency(user ? user.balance : 0, primaryCurrency, primarySymbol)}
                            </p>
                        </div>
                        <div className="ml-auto">
                            <div className="rounded-full bg-emerald-500 bg-opacity-20 p-2">
                                <FaGift className="text-emerald-500 text-xl" />
                            </div>
                        </div>
                        <div
                            className="absolute inset-0 bg-white opacity-50 rounded-lg"
                            style={{ mixBlendMode: 'multiply' }}
                        ></div>
                    </div>
                </div>
                {/* Security Announcement */}
                <div className="mb-6">
                <div className="bg-white border border-gray-100 rounded-lg p-4 shadow-md mb-6  
              bg-gradient-to-br from-green-300/10 via-red-300/10 to-blue-300/20">


                        <h3 className="text-lg font-bold mb-2 flex items-center text-red-800">
                            <FaShieldAlt className="mr-2" /> Security Alert
                        </h3>
                        <p className="text-sm text-red-700">
                            Be cautious of fraudsters. Always seek help through our official customer support channels:
                        </p>
                        <ul className="mt-2 text-sm text-red-700 list-disc pl-5">
                            <li>Live Chat</li>
                            <li>Email: <a href="mailto:support@verdantcharity.org" className="text-blue-600 underline">support@verdantcharity.org</a></li>
                            <li>Phone: <a href="tel:+13852339891" className="text-blue-600 underline">+1 385 233 9891</a></li>
                        </ul>
                    </div>
                </div>


                {/* Promotional Banner - Only visible on small screens */}
                <div className="mb-6 block md:hidden">
                    <img
                        src="/dashboard_images/wallet_ad.jpeg"
                        alt="Verdant Charity Promotion"
                        className="w-full h-48 object-cover rounded-lg shadow-lg"
                    />
                </div>



                {/* Transfer History or Details */}
                {selectedTransferId ? (
                    <TransferDetail transferId={selectedTransferId} onBack={handleBackToHistory} />
                ) : (
                    <TransferHistory onViewDetails={handleViewTransferDetails} />
                )}
            </div>

            {/* Right Sidebar */}
            <div className="hidden lg:block w-64 ml-4">
                {/* Announcements */}
                <div className="bg-white border border-gray-100 rounded-lg p-4 shadow-md mb-6 
                bg-gradient-to-br from-green-300/10 via-red-300/10 to-blue-300/10">

                    <h3 className="text-lg font-bold mb-4 flex items-center">
                        <FaBell className="text-red-500 mr-2" /> Announcements
                    </h3>
                    <ul className="space-y-3 text-sm text-gray-700">
                        <li className="flex items-start">
                            <FaChartLine className="text-green-500 mr-2 mt-1" />
                            <span>New features have been added to your dashboard.</span>
                        </li>
                        <li className="flex items-start">
                            <FaClipboardList className="text-purple-500 mr-2 mt-1" />
                            <span>Your account settings have been updated.</span>
                        </li>
                        <li className="flex items-start">
                            <FaRegCalendarAlt className="text-red-500 mr-2 mt-1" />
                            <span>Don't forget to check out our upcoming events.</span>
                        </li>
                    </ul>
                    <a href="#" className="text-emerald-600 text-sm mt-4 flex items-center hover:underline">
                        View All Announcements <AiOutlineArrowRight className="ml-1" />
                    </a>
                </div>

                {/* Quick Tips */}
                <div className="bg-white p-4 rounded-lg shadow-md mb-6">
                    <h3 className="text-lg font-bold mb-4 flex items-center">
                        <FaRegLightbulb className="text-yellow-500 mr-2" /> Quick Tips
                    </h3>
                    <ul className="space-y-3 text-sm text-gray-700">
                        <li className="flex items-start">
                            <span>Keep your account secure by regularly updating your password.</span>
                        </li>
                        <li className="flex items-start">
                            <span>Explore new features in the settings to customize your experience.</span>
                        </li>
                        <li className="flex items-start">
                            <span>Check out the help center for FAQs and guides.</span>
                        </li>
                    </ul>
                    <a href="#" className="text-emerald-600 text-sm mt-4 flex items-center hover:underline">
                        Learn More Tips <AiOutlineArrowRight className="ml-1" />
                    </a>
                </div>

                {/* Help Center */}
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-lg font-bold mb-4 flex items-center">
                        <FaQuestionCircle className="text-indigo-500 mr-2" /> Help Center
                    </h3>
                    <p className="text-sm text-gray-700 mb-4">
                        Have questions or need assistance? Visit our help center for support.
                    </p>
                    <a href="#" className="text-emerald-600 text-sm flex items-center hover:underline">
                        Go to Help Center <AiOutlineArrowRight className="ml-1" />
                    </a>
                </div>
            </div>
        </div>
    );

};

export default Wallet;
