import React, { useState, useEffect } from 'react';
import { useUser } from "../context";
import { useNavigate } from 'react-router-dom';
import { FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import api from '../../api';
import WalletSidebar from './WalletSidebar';
import Wallet from './Wallet';
import ConversionContainer from './conversion/ConversionContainer';
import Withdraw from './withdrawal/Withdraw';
import CurrenciesContainer from './CurrenciesContainer';
import DepositContainer from './deposits/DepositContainer';
import TransferContainer from './transactions/TransferContainer';
import WithdrawalHistory from './history/WithdrawalHistory';
import HistoryContainer from './history/HistoryContainer';
import MarketPlace from './MarketPlace';
import Kyc from '../Kyc';
import AccessHistory from './history/AccessHistory';
import AccessDetail from './history/AccessDetail';

const WalletDashboard = () => {
    const { user, login } = useUser();
    const navigate = useNavigate();
    const [activeComponent, setActiveComponent] = useState('wallet');
    const [banExpired, setBanExpired] = useState(false);
    const [selectedLoginId, setSelectedLoginId] = useState(null);



    // Handlers for AccessHistory stuff
    const handleViewDetails = (loginId) => {
        setSelectedLoginId(loginId);
        setActiveComponent('accessDetail');
    };
    const handleBackToHistory = () => {
        setSelectedLoginId(null);
        setActiveComponent('accessHistory');
    };


    useEffect(() => {
        if (!user || !user.token) {
            navigate('/login');
        } else {
            // Fetch updated user data when dashboard is visited
            const fetchUserData = async () => {
                try {
                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.token}`
                        }
                    };
                    const response = await api.get('/api/auth/info', config);
                    if (response.status === 200) {
                        // Update context by spreading the existing user and overriding with new data
                        login({ ...user, ...response.data });
                    } else {
                        console.error('Failed to fetch user data, status code:', response.status);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
            fetchUserData();
        }
    }, []);

    useEffect(() => {
        // This useEffect is specifically for setting the active component based on user's primary info
        if (!user?.primaryInfo?.firstName || !user?.primaryInfo?.lastName) {
            setActiveComponent('kyc');
        }
    }, [user]);

    const CountdownTimer = ({ targetDate, onExpired }) => {
        const [timeLeft, setTimeLeft] = useState('');

        useEffect(() => {
            const timer = setInterval(() => {
                const now = new Date();
                const distance = new Date(targetDate) - now;

                if (distance < 0) {
                    clearInterval(timer);
                    setTimeLeft("0d 0h 0m 0s");
                    if (onExpired) {
                        onExpired();
                    }
                } else {
                    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
                }
            }, 1000);

            return () => clearInterval(timer);
        }, [targetDate, onExpired]);

        return <span>{timeLeft}</span>;
    };

    const componentMap = {
        wallet: <Wallet />,
        CurrenciesContainer: <CurrenciesContainer />,
        ConversionContainer: <ConversionContainer setActiveComponent={setActiveComponent} />,
        withdraw: <Withdraw />,
        DepositContainer: <DepositContainer setActiveComponent={setActiveComponent} />,
        TransferContainer: <TransferContainer setActiveComponent={setActiveComponent} />,
        WithdrawalHistory: <WithdrawalHistory />,
        HistoryContainer: <HistoryContainer />,
        marketPlace: <MarketPlace />,
        kyc: <Kyc />,
        accessHistory: <AccessHistory onViewDetails={handleViewDetails} />,
        accessDetail: (
            <AccessDetail
                loginId={selectedLoginId}
                onBack={handleBackToHistory}
                setActiveComponent={setActiveComponent}
            />
        ),
    };




    return (
        <div className="bg-emerald-50 min-h-screen pb-20">
            {user?.isBanned && (
                <>
                    {!user.banReleaseDate ? (
                        <div className="bg-yellow-200 text-yellow-700 p-3 mt-2 flex flex-row items-center justify-start sm:justify-center">
                            <FaExclamationTriangle className="mr-2 text-7xl sm:text-xl" />
                            <span className="text-xs sm:text-sm">
                                Your account is temporarily banned. Please check your email for instructions on how to provide the required documents. Learn more{' '}
                                <a
                                    href="https://www.verdantcharity.org/help/article/how-to-address-account-limitations-on-verdant-charity"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="underline hover:text-yellow-800"
                                >
                                    here
                                </a>.
                            </span>
                        </div>
                    ) : (
                        <div className="bg-red-200 text-red-700 p-3 flex flex-row items-center justify-start sm:justify-center">
                            <FaExclamationTriangle className="mr-2 text-7xl sm:text-xl" />
                            {banExpired ? (
                                <span className="text-xs">
                                    The ban period has ended. Please contact support to regain access to your funds.
                                </span>
                            ) : (
                                <span className="text-xs">
                                    Upon reviewing your recent transactions and documentation, we have decided to place a ban on your account. You will regain access to your funds in <strong><CountdownTimer targetDate={user.banReleaseDate} onExpired={() => setBanExpired(true)} /></strong>, after which your account will be permanently closed.
                                </span>
                            )}
                        </div>
                    )}
                </>
            )}
            {!user?.isBanned && user?.upgradeRequest && !user?.isPremium && (
                <div className="bg-blue-100 border-y border-blue-400 text-blue-700 p-3 mt-2 flex flex-row items-center justify-center">
                    <FaInfoCircle className="mr-2 text-7xl sm:text-xl" />
                    <span className="text-xs">
                        You have been recommended to upgrade your account. Consider our premium tier service which offers numerous benefits, including instant withdrawals, which might be particularly useful to you in managing future transactions more efficiently. Learn more{' '}
                        <a
                            href="https://www.verdantcharity.org/help/article/how-to-upgrade-to-premium-on-verdant-charity"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline hover:text-blue-800"
                        >
                            here
                        </a>.
                    </span>
                </div>
            )}

            <div className="lg:flex lg:flex-row p-4 rounded-lg border border-gray-200">
                {/* Sidebar for the Wallet */}
                <WalletSidebar changeComponent={setActiveComponent} />
                {/* Main content area */}
                <div className="mx-4 mt-8 rounded-lg flex-grow flex flex-col">
                    {/* Render the active component */}
                    {componentMap[activeComponent] || <p>Component not found</p>}
                </div>
            </div>

        </div>
    );
};

export default WalletDashboard;
