import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { FaTrash, FaSpinner, FaTimes, FaInfoCircle, FaMoneyBillWave, FaMobile, FaPaypal, FaUniversity } from 'react-icons/fa';

const UserWithdrawals = ({ userId }) => {
    const { user } = useUser();
    const [withdrawals, setWithdrawals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const fetchWithdrawals = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/api/customer/${userId}/withdrawals`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                if (response.status === 200) {
                    setWithdrawals(response.data);
                } else {
                    toast.error('Failed to load withdrawal data');
                }
            } catch (error) {
                toast.error(`Failed to load withdrawal data: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchWithdrawals();
    }, [userId, user.token]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const getStatusColor = (status) => {
        switch(status.toLowerCase()) {
            case 'completed': return 'bg-green-100 text-green-800';
            case 'failed': return 'bg-red-100 text-red-800';
            case 'pending': return 'bg-yellow-100 text-yellow-800';
            case 'processing': return 'bg-blue-100 text-blue-800';
            case 'cancelled': return 'bg-gray-100 text-gray-800';
            default: return 'bg-gray-100 text-gray-800';
        }
    };

    const getWithdrawalIcon = (withdrawal) => {
        if (withdrawal.provider) return <FaMobile className="text-green-500" />;
        if (withdrawal.email) return <FaPaypal className="text-blue-500" />;
        if (withdrawal.bank) return <FaUniversity className="text-purple-500" />;
        return <FaMoneyBillWave className="text-gray-500" />;
    };

    const getWithdrawalType = (withdrawal) => {
        if (withdrawal.provider) return 'Mobile Money';
        if (withdrawal.email) return 'PayPal';
        if (withdrawal.bank) return 'Bank Transfer';
        return 'Withdrawal';
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            const response = await api.delete(`/api/customer/${userId}/withdrawals/${selectedWithdrawal._id}`, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            if (response.status === 200) {
                toast.success('Withdrawal successfully deleted');
                setWithdrawals(withdrawals.filter(w => w._id !== selectedWithdrawal._id));
                setShowDeleteModal(false);
            } else {
                toast.error('Failed to delete withdrawal');
            }
        } catch (error) {
            toast.error(`Failed to delete withdrawal: ${error.message}`);
        } finally {
            setIsDeleting(false);
        }
    };

    const DetailRow = ({ label, value }) => (
        <div className="border-b border-dashed py-1">
            <div className="grid grid-cols-3 text-xs">
                <span className="font-medium">{label}:</span>
                <span className="col-span-2">{value || 'N/A'}</span>
            </div>
        </div>
    );

    const renderWithdrawalDetails = () => {
        if (!selectedWithdrawal) return null;
        
        // Common fields for all withdrawal types
        const commonFields = [
            { label: 'Withdrawal ID', value: selectedWithdrawal.withdrawalId },
            { label: 'Type', value: getWithdrawalType(selectedWithdrawal) },
            { label: 'Amount', value: `${selectedWithdrawal.currency || '$'} ${selectedWithdrawal.amount?.toFixed(2)}` },
            { label: 'Currency', value: selectedWithdrawal.currency },
            { label: 'Status', value: selectedWithdrawal.status },
            { label: 'Initiated At', value: formatDate(selectedWithdrawal.createdAt) },
            { label: 'First Name', value: selectedWithdrawal.firstName }
        ];

        // Type-specific fields
        const typeSpecificFields = [];
        if (selectedWithdrawal.provider) {
            typeSpecificFields.push(
                { label: 'Provider', value: selectedWithdrawal.provider },
                { label: 'Phone Number', value: selectedWithdrawal.phoneNumber }
            );
        } else if (selectedWithdrawal.email) {
            typeSpecificFields.push(
                { label: 'Email', value: selectedWithdrawal.email }
            );
        } else if (selectedWithdrawal.bank) {
            typeSpecificFields.push(
                { label: 'Bank', value: selectedWithdrawal.bank },
                { label: 'Account Number', value: selectedWithdrawal.accountNo },
                { label: 'Beneficiary Name', value: selectedWithdrawal.beneficiaryName },
                { label: 'Routing Number', value: selectedWithdrawal.routingNumber || 'N/A' }
            );
        }

        return [...commonFields, ...typeSpecificFields];
    };

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Withdrawal History</h3>
            
            <div className="border border-dashed border-gray-200 rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full min-w-[700px]">
                        <thead className="bg-gray-50">
                            <tr>
                                {['Type', 'Withdrawal ID', 'Amount', 'Status', 'Date', 'Actions'].map((header) => (
                                    <th key={header} className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="6" className="px-3 py-4 text-center">
                                        <FaSpinner className="animate-spin mx-auto text-gray-400" />
                                    </td>
                                </tr>
                            ) : withdrawals.length > 0 ? (
                                withdrawals.map((withdrawal) => (
                                    <tr key={withdrawal._id} className="border-t border-dashed hover:bg-gray-50">
                                        <td className="px-3 py-2 text-xs text-gray-700 flex items-center gap-1">
                                            {getWithdrawalIcon(withdrawal)}
                                            {getWithdrawalType(withdrawal)}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">{withdrawal.withdrawalId}</td>
                                        <td className="px-3 py-2 text-xs font-medium text-gray-700">
                                            {withdrawal.currency || '$'} {withdrawal.amount?.toFixed(2)}
                                        </td>
                                        <td className="px-3 py-2">
                                            <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${getStatusColor(withdrawal.status)}`}>
                                                {withdrawal.status}
                                            </span>
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            {formatDate(withdrawal.createdAt)}
                                        </td>
                                        <td className="px-3 py-2 flex gap-2">
                                            <button
                                                onClick={() => setSelectedWithdrawal(withdrawal)}
                                                className="text-xs text-blue-600 hover:text-blue-800 flex items-center gap-1"
                                            >
                                                <FaInfoCircle className="text-xs" />
                                                View
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setSelectedWithdrawal(withdrawal);
                                                    setShowDeleteModal(true);
                                                }}
                                                className="text-xs text-red-600 hover:text-red-800 flex items-center gap-1"
                                            >
                                                <FaTrash className="text-xs" />
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="px-3 py-4 text-center text-xs text-gray-500">
                                        No withdrawal records found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Withdrawal Detail Modal */}
            {selectedWithdrawal && (
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-md border border-dashed border-gray-200 shadow">
                        <div className="flex justify-between items-center mb-3 pb-2 border-b border-dashed">
                            <div className="flex items-center gap-2">
                                <div className="p-1 rounded-full bg-blue-50 text-blue-600">
                                    {getWithdrawalIcon(selectedWithdrawal)}
                                </div>
                                <h3 className="text-sm font-semibold">
                                    {getWithdrawalType(selectedWithdrawal)} Withdrawal Details
                                </h3>
                            </div>
                            <button 
                                onClick={() => setSelectedWithdrawal(null)}
                                className="text-gray-400 hover:text-gray-600"
                            >
                                <FaTimes className="text-sm" />
                            </button>
                        </div>

                        <div className="space-y-2 text-xs">
                            {renderWithdrawalDetails().map((field, index) => (
                                <DetailRow key={index} label={field.label} value={field.value} />
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-md border border-dashed border-gray-200 shadow">
                        <div className="flex items-center gap-2 mb-3">
                            <div className="p-2 rounded-lg bg-red-50 text-red-600">
                                <FaTrash />
                            </div>
                            <h3 className="text-sm font-semibold">Confirm Delete</h3>
                        </div>
                        
                        <p className="mb-4 text-sm text-gray-600">
                            Are you sure you want to delete this {getWithdrawalType(selectedWithdrawal)} withdrawal?
                        </p>

                        <div className="border-t border-dashed pt-3">
                            <div className="flex justify-end gap-2">
                                <button
                                    onClick={() => setShowDeleteModal(false)}
                                    disabled={isDeleting}
                                    className="px-3 py-1.5 text-gray-600 hover:bg-gray-50 rounded-lg text-sm font-medium border border-dashed"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDelete}
                                    disabled={isDeleting}
                                    className={`px-3 py-1.5 bg-red-50 text-red-600 hover:bg-red-100 rounded-lg text-sm font-medium flex items-center gap-1 ${
                                        isDeleting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                >
                                    {isDeleting ? (
                                        <>
                                            <FaSpinner className="animate-spin" />
                                            Deleting...
                                        </>
                                    ) : (
                                        <>
                                            <FaTrash />
                                            Confirm
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserWithdrawals;