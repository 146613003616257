/**
 * TradeOffer.js
 * The "Offer" tab content, refactored into its own component.
 */

/**
 * TradeOffer.js
 * Premium UI redesign with confidence optimization
 */

import React from 'react';
import {
  FaSpinner,
  FaShieldAlt,
  FaRegClock,
  FaRegStar,
  FaCheckCircle,
  FaThumbsUp,
  FaThumbsDown,
  FaCheck,
  FaExchangeAlt,
  FaClock,
  FaShieldCheck,
  FaRocket,
  FaMedal,  
  FaIdCard,
  FaEnvelope,
  FaPhone,
  FaMapMarker,
  FaWhatsapp,
  FaHeart,
  FaStar,
  FaBolt,
  FaInfoCircle,
  FaLock,
  FaHandHoldingUsd,
  FaTachometerAlt,
  FaChartLine
} from 'react-icons/fa';
import {
  HiOutlineExclamationCircle,
  HiOutlineChevronDown,
  HiOutlineChevronUp
} from 'react-icons/hi';
import { useUser } from "../context";

const TradeOffer = ({
  points,
  setPoints,
  loading,
  loadingExchangeRate,
  exchangeRate,
  exchangeRateError,
  handleBuyNowClick,
  navigate
}) => {
  const { user } = useUser();

  const handlePointsChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setPoints(value);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
{/* Trust & Security Header */}
<div className="bg-white rounded-2xl border border-dotted border-green-100/50 p-6 mt-6">
  <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-6">
    {/* Main Security Badge */}
    <div className="flex items-center space-x-4">
      <div className="bg-green-50 p-3 rounded-xl border border-dotted border-green-100">
        <div className="flex items-center space-x-2">
          <FaLock className="text-green-600 text-xl" />
          <span className="text-green-700 font-semibold text-sm">SECURE</span>
        </div>
      </div>
      <div>
        <h2 className="text-lg font-bold text-gray-900">Protected Instant Trading</h2>
        <p className="text-sm text-gray-600 mt-1">Bank-grade encryption with escrow protection</p>
      </div>
    </div>

    {/* Trust Metrics Grid */}
    <div className="w-full md:w-auto grid grid-cols-2 sm:grid-cols-none sm:flex gap-3 border-dotted border-t md:border-t-0 border-indigo-100/30 pt-4 md:pt-0">
      <div className="flex items-center justify-center sm:justify-start space-x-2 bg-green-50 px-3 py-2 rounded-lg">
        <FaCheckCircle className="text-green-600" />
        <div className="text-center sm:text-left">
          <span className="block text-sm font-semibold text-gray-900">98%</span>
          <span className="block text-xs text-gray-600">Success Rate</span>
        </div>
      </div>
      
      <div className="flex items-center justify-center sm:justify-start space-x-2 bg-orange-50 px-3 py-2 rounded-lg">
        <FaExchangeAlt className="text-orange-600" />
        <div className="text-center sm:text-left">
          <span className="block text-sm font-semibold text-gray-900">2,450+</span>
          <span className="block text-xs text-gray-600">Completed Trades</span>
        </div>
      </div>
    </div>
  </div>

{/* Trust Badges for Mobile */}
<div className="md:hidden mt-4 pt-4 border-t border-gray-100 flex justify-center">
  <div className="flex items-center space-x-4 text-sm text-gray-600">
    <span className="flex items-center space-x-1">
      <FaShieldAlt className="text-emerald-600" />
      <span>Verified Seller</span>
    </span>
    <span className="text-gray-300">|</span>
    <span className="flex items-center space-x-1">
      <FaClock className="text-gray-500" />
      <span>24/7 Support</span>
    </span>
   
  </div>
</div>
</div>


{/* Trading Dashboard */}
<div className="bg-white rounded-2xl border border-gray-100">
  <div className="bg-green-50 p-6 rounded-t-2xl border-b border-emerald-100">
    <h1 className="text-xl font-semibold text-emerald-900 flex items-center">
      <FaHandHoldingUsd className="mr-3 text-emerald-700" />
      Impact Points Purchase
    </h1>
  </div>

     {/* Trading Controls */}
<div className="p-6 space-y-6">
  {/* Points Input Section */}
  <div className="grid md:grid-cols-2 gap-6">
    <div className="space-y-2">
      <label className="text-xs font-semibold text-gray-700 flex items-center">
        <div className="bg-purple-100/40 p-2 rounded-full mr-2">
          <FaRocket className="text-purple-600/90" />
        </div>
        Points to Purchase
      </label>
      <div className="relative group">
        <input
          type="number"
          value={points}
          onChange={handlePointsChange}
          className="w-full p-4 pr-16 rounded-xl border-2 border-gray-200/80 focus:border-emerald-500/80 focus:ring-2 focus:ring-emerald-100/40 transition-all"
          placeholder="Enter points"
        />
        <div className="absolute right-4 top-1/2 -translate-y-1/2 flex items-center space-x-2">
          <span className="text-gray-500/90 font-medium">PTS</span>
          <div className="h-6 w-px bg-gray-200/80"></div>
          <button 
            onClick={() => setPoints(1000)}
            className="text-emerald-600/90 hover:text-emerald-700/90 transition-colors"
          >
            MAX
          </button>
        </div>
        <div className="mt-2 flex justify-between items-center px-1">
          <span className={`text-xs ${points < 50 ? 'text-red-500/90' : 'text-gray-500/80'}`}>
            Minimum 50 points
          </span>
          <span className="text-xs text-gray-500/80">
            Available: ∞ points
          </span>
        </div>
      </div>
    </div>

    {/* Conversion Display */}
    <div className="space-y-2">
      <label className="text-xs font-semibold text-gray-700 flex items-center">
        <div className="bg-blue-100/40 p-2 rounded-full mr-2">
          <FaShieldAlt className="text-blue-600/90" />
        </div>
        Total Payment (KES)
      </label>
      <div className="relative">
        <div className="w-full p-4 rounded-xl bg-gray-50/70 border-2 border-gray-200/80">
          <div className="flex justify-between items-center">
            <span className="text-lg font-bold text-emerald-600/90">
              {(points * exchangeRate).toFixed(2)}
            </span>
            <span className="text-gray-600/90 font-medium">KES</span>
          </div>
        </div>
        {loadingExchangeRate && (
          <div className="absolute inset-0 bg-white/80 flex items-center justify-center rounded-xl">
            <FaSpinner className="animate-spin text-emerald-600/90" />
          </div>
        )}
      </div>
      <div className="flex items-center space-x-2 text-xs text-gray-600/90">
        <FaInfoCircle className="text-gray-400/90" />
        <span>Includes all fees • 1 PTS = {exchangeRate} KES</span>
      </div>
    </div>
  </div>

 

  {/* Action Buttons */}
  <div className="grid md:grid-cols-2 gap-4">
  <button
  onClick={handleBuyNowClick}
  disabled={loading || points < 50}
  className={`w-full transition-colors p-3 rounded-xl flex items-center justify-center space-x-2 ${
    loading || points < 50 
      ? 'bg-gray-100/60 cursor-not-allowed text-gray-400'
      : 'bg-green-100/90 hover:bg-green-200/80 active:bg-green-300/70 text-green-800'
  }`}
>
  {loading ? (
    <FaSpinner className="animate-spin" />
  ) : (
    <>
      <FaCheckCircle className="text-lg" />
      <span className="font-medium">Start Secure Trade</span>
    </>
  )}
</button>
    <button
      onClick={() => navigate(-1)}
      className="h-14 rounded-xl border-2 border-gray-300/80 font-bold text-gray-700/90 hover:bg-gray-50/60 transition-colors flex items-center justify-center space-x-2"
    >
      <HiOutlineChevronDown />
      <span>Cancel Transaction</span>
    </button>
  </div>
</div>

        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
{/* Seller Profile Card */}
<div className="bg-white rounded-2xl border border-gray-100 p-6">
  {/* Seller Header with Border Bottom */}
  <div className="flex items-center justify-between mb-6 pb-6 border-b border-gray-200">
    <div className="flex items-center">
      <div className="relative">
        <img
          src="/Lucy-Kiarie.jpg"
          alt="Lucy Kiarie"
          className="w-12 h-12 rounded-full mr-3 border-4 border-white shadow-lg"
        />
      </div>
      <div>
        <h2 className="text-lg font-bold flex items-center">
          Lucy Kiarie
          <svg 
            viewBox="0 0 24 24" 
            className="ml-1.5 w-5 h-5 text-[#1DA1F2]"
            aria-label="Verified Account"
          >
            <g>
              <path 
                d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06.295-.294.77-.294 1.06 0l1.77 1.767 3.825-5.74c.207-.31.582-.412.888-.222.31.19.412.58.222.888z"
                fill="currentColor"
              />
            </g>
          </svg>
        </h2>
        <div className="mt-1">
          <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs flex items-center relative w-fit">
            <span className="w-2 h-2 bg-green-500 rounded-full absolute -top-1 -right-1 animate-ping"></span>
            <span className="w-2 h-2 bg-green-500 rounded-full absolute -top-1 -right-1"></span>
            Online Now
          </span>
        </div>
      </div>
    </div>
    <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-xs flex items-center">
      <FaStar className="mr-1 text-yellow-500" />
      Top 1% Seller
    </span>
  </div>
  

  {/* Seller Metrics */}
  <div className="grid grid-cols-2 gap-4 mb-6">
    <div className="p-4 bg-gray-50 rounded-xl">
      <div className="text-xl font-bold text-emerald-600">98%</div>
      <div className="text-xs text-gray-600">Success Rate</div>
    </div>
    <div className="p-4 bg-gray-50 rounded-xl">
      <div className="text-xl font-bold text-purple-600">2,450+</div>
      <div className="text-xs text-gray-600">Completed Trades</div>
    </div>
  </div>
 {/* Trust Badges - Compact 2-column grid */}
<div className="grid grid-cols-2 gap-3 mb-6">
  <div className="p-3 bg-gray-50 rounded-xl flex items-center space-x-2">
    <div className="bg-blue-600/10 p-1.5 rounded-lg">
      <FaCheck className="text-blue-700/90 text-sm" />
    </div>
    <span className="text-xs font-medium text-blue-900/90">Escrow Protected</span>
  </div>
  
  <div className="p-3 bg-gray-50 rounded-xl flex items-center space-x-2">
    <div className="bg-green-600/10 p-1.5 rounded-lg">
      <FaRegClock className="text-green-700/90 text-sm" />
    </div>
    <span className="text-xs font-medium text-green-900/90">Instant Trade</span>
  </div>
  
  <div className="p-3 bg-gray-50 rounded-xl flex items-center space-x-2">
    <div className="bg-purple-600/10 p-1.5 rounded-lg">
      <FaMedal className="text-purple-700/90 text-sm" />
    </div>
    <span className="text-xs font-medium text-purple-900/90">Top Rated Seller</span>
  </div>
  
  <div className="p-3 bg-gray-50 rounded-xl flex items-center space-x-2">
    <div className="bg-orange-600/10 p-1.5 rounded-lg">
      <FaHeart className="text-orange-700/90 text-sm" />
    </div>
    <span className="text-xs font-medium text-orange-900/90">24/7 Support</span>
  </div>
</div>
  {/* Verification Badges */}
  <div className="bg-blue-50 rounded-xl p-4 mb-6">
    <h3 className="text-xs font-semibold mb-3">Verified Information</h3>
    <div className="grid grid-cols-2 gap-3">
      <div className="flex items-center space-x-2">
        <FaIdCard className="text-blue-600" />
        <span>Government ID</span>
      </div>
      <div className="flex items-center space-x-2">
        <FaPhone className="text-green-600" />
        <span>Phone Number</span>
      </div>
      <div className="flex items-center space-x-2">
        <FaEnvelope className="text-red-600" />
        <span>Email Address</span>
      </div>
      <div className="flex items-center space-x-2">
        <FaMapMarker className="text-purple-600" />
        <span>Residence</span>
      </div>
    </div>
    </div>
 

  {/* Seller Activity */}
  <div className="space-y-4 mb-6">
    <div className="flex items-center justify-between">
      <span className="text-gray-600">Response Time</span>
      <span className="font-medium text-emerald-600">{"<"} 2 mins</span>
    </div>
    <div className="flex items-center justify-between">
      <span className="text-gray-600">Trade Completion</span>
      <span className="font-medium text-emerald-600">98% in 5 mins</span>
    </div>
    <div className="flex items-center justify-between">
      <span className="text-gray-600">Followership</span>
      <span className="font-medium text-emerald-600">1,230 Followers</span>
    </div>
  </div>

{/* WhatsApp Button */}
<a
  href={`https://wa.me/+254112118243?text=Hello%20Lucy%2C%20my%20name%20is%20${encodeURIComponent(user?.primaryInfo?.firstName)}%20from%20Verdant%20Charity%20Marketplace.%20I%20am%20interested%20in%20conducting%20a%20trade%20with%20you.%20Could%20you%20confirm%20your%20availability%3F`}
  target="_blank"
  rel="noopener noreferrer"
  className="w-full bg-green-100/90 hover:bg-green-200/80 transition-colors p-3 rounded-xl flex items-center justify-center space-x-2 text-green-800"
>
  <FaWhatsapp className="text-lg" />
  <span className="font-medium">Message via WhatsApp</span>
</a>
</div>



        {/* Offer Details */}
        <div className="bg-white rounded-2xl border border-gray-100 p-6">
         {/* Rate Comparison */}
          <div className="bg-purple-50 rounded-xl p-4 mb-6">
            <div className="flex items-center justify-between mb-3">
              <span className="font-medium">Lucy's Rate</span>
              <span className="text-lg font-bold text-purple-600">1.00 PTS/KES</span>
            </div>
            <div className="h-1 bg-purple-200 rounded-full mb-2">
              <div className="w-3/4 h-full bg-purple-600 rounded-full"></div>
            </div>
            <div className="flex items-center justify-between text-xs text-gray-600">
              <span>Market Average: 1.85</span>
              <span>Best Rate: 2.10</span>
            </div>
          </div>

          {/* Terms & Conditions */}
          <div className="space-y-4">
            <div className="p-4 bg-orange-50 rounded-xl">
              <h3 className="font-semibold mb-2 flex items-center">
                <FaBolt className="mr-2 text-orange-600" />
                Trade Requirements
              </h3>
              <ul className="list-disc pl-5 space-y-2 text-xs">
                <li>Minimum 50 points per trade</li>
                <li>Maximum 1000 points per transaction</li>
                <li>30 minute trade completion window</li>
              </ul>
            </div>

            <div className="p-4 bg-green-50 rounded-xl">
              <h3 className="font-semibold mb-2 flex items-center">
                <FaShieldAlt className="mr-2 text-green-600" />
                Security Protocol
              </h3>
              <p className="text-xs">
                All trades are protected by our escrow system. Impact points are only released 
                when both parties confirm transaction completion.
              </p>
            </div>

            <div className="p-4 bg-blue-50 rounded-xl">
              <h3 className="font-semibold mb-2 flex items-center">
                <FaRegStar className="mr-2 text-blue-600" />
                Seller Advantages
              </h3>
              <div className="grid grid-cols-2 gap-4 text-xs">
                <div className="flex items-center space-x-2">
                  <FaTachometerAlt className="text-green-600" />
                  <span>Fast Responses</span>
                </div>
                <div className="flex items-center space-x-2">
                  <FaChartLine className="text-purple-600" />
                  <span>Market Insights</span>
                </div>
                <div className="flex items-center space-x-2">
                  <FaHandHoldingUsd className="text-red-600" />
                  <span>Flexible Payments</span>
                </div>
                <div className="flex items-center space-x-2">
                  <FaHeart className="text-pink-600" />
                  <span>Priority Support</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

      {/* Security Assurance */}
<div className="bg-white rounded-2xl  border border-gray-100 p-6">
 
  <div className="grid md:grid-cols-3 gap-6">
  <div className="p-4 bg-gray-50/50 rounded-xl">
  <div className="flex items-center space-x-3 mb-3">
    <div className="bg-blue-600/20 p-2 rounded-lg border border-blue-200/30">
      <FaLock className="text-blue-800/90" />
    </div>
    <h3 className="font-semibold">Funds Protection</h3>
  </div>
  <p className="text-xs text-gray-600">
    In the event of a dispute, lost funds may be reimbursed through the seller’s security deposit, ensuring a secure transaction process.
  </p>
</div>
<div className="p-4 bg-gray-50/50 rounded-xl">
  <div className="flex items-center space-x-3 mb-3">
    <div className="bg-green-600/20 p-2 rounded-lg border border-green-200/30">
      <FaCheckCircle className="text-green-800/90" />
    </div>
    <h3 className="font-semibold">Identity Verification</h3>
  </div>
  <p className="text-xs text-gray-600">
    All sellers undergo a rigorous identity verification process before listing to ensure a safe and trustworthy marketplace.
  </p>
</div>

    <div className="p-4 bg-gray-50/50 rounded-xl">
      <div className="flex items-center space-x-3 mb-3">
   
        <div className="bg-orange-600/20 p-2 rounded-lg border border-orange-200/30">
        <FaShieldAlt className="text-orange-700/90 text-xl" />
        </div>
        <h3 className="font-semibold">Instant Support</h3>
      </div>
      <p className="text-xs text-gray-600">
        24/7 dispute resolution team with average 15min response time
      </p>
    </div>
  </div>
</div>
    </div>
  );
};

export default TradeOffer;