// src/components/Dashboard.jsx
// src/components/Dashboard.jsx
import React, { useState, useEffect } from 'react';
import { useUser } from "./context";
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { FaShieldAlt } from "react-icons/fa";

// Modal imports
import ProfilePictureEnforcementModal from './Profile/ProfilePictureEnforcementModal';
import AntiFraudDisclaimerModal from './AntiFraudDisclaimerModal';

// Other imports remain unchanged
import Sidebar from './Sidebar';
import ForexStrip from './TradingViewWidget';
import DonationsSummary from './DonationsSummary';
import MyDonations from './Donations/DonationCampaignContainer.jsx';
import CampaignsInsideApp from './CampaignsInsideApp';
import VolunteerActivities from './VolunteerActivities';
import CommunityMain from './Community/CommunityMain';
import Profile from './Profile/Profile';
import Membership from './Membership';
import Wallet from './wallet/Wallet';
import Kyc from './Kyc';
import CrowdfundContainer from './crowdfund/CrowdfundContainer';
import GrantsContainer from './grants/GrantsContainer';

const Dashboard = () => {
  const { user, login, logout } = useUser();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState('donationsSummary');
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showProfileEnforcement, setShowProfileEnforcement] = useState(false);

  const refreshUserData = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      };
      const response = await api.get('/api/auth/info', config);
      if (response.status === 200) {
        login({ ...user, ...response.data });
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  };

  useEffect(() => {
    if (!user || !user.token) {
      logout();
      navigate('/login');
    } else {
      refreshUserData();
    }
  }, []);

  useEffect(() => {
    // Check for user or token existence and logout if missing
    if (!user || !user.token) {
      logout();
      navigate('/login');
      return; // Exit early to prevent further execution
    }
  
    if (user.token && (!user.primaryInfo?.countryCode || !user.primaryInfo?.firstName)) {
      setActiveComponent('kyc');
      navigate('/kyc');
    }
  }, [user, navigate, logout]);
  
  // Enhanced modal state management with debugging
  useEffect(() => {
    // Check for user or token existence and logout if missing
    if (!user || !user.token) {
      logout();
      navigate('/login');
      return; // Exit early to prevent further execution
    }
  
    const checkProfilePhoto = () => {
      try {
        const rawProfileUrl = user?.primaryInfo?.profilePhotoUrl || '';
        const profileUrl = rawProfileUrl.toLowerCase();
  
        return !rawProfileUrl || profileUrl.includes('default') || profileUrl.includes('placeholder');
      } catch {
        return true; // Fail-safe in case of an error
      }
    };
  
    if (user?.antiFraudDisclaimer?.accepted === false) {
      setShowDisclaimer(true);
      setShowProfileEnforcement(false);
    } else if (checkProfilePhoto()) {
      setShowProfileEnforcement(true);
      setShowDisclaimer(false);
    } else {
      setShowProfileEnforcement(false);
      setShowDisclaimer(false);
    }
  }, [user, navigate, logout]);


  // Component map remains unchanged
  const componentMap = {
    donationsSummary: <DonationsSummary setActiveComponent={setActiveComponent} />,
    myDonations: <MyDonations />,
    campaigns: <CampaignsInsideApp />,
    volunteerActivities: <VolunteerActivities />,
    community: <CommunityMain />,
    profile: <Profile />,
    membership: <Membership />,
    wallet: <Wallet />,
    kyc: <Kyc />,
    crowdfunding: <CrowdfundContainer />,
    grants: <GrantsContainer />,
  };

  return (
    <div className="bg-emerald-50 min-h-screen pb-20 font-harmattan font-12">
      <ForexStrip />

      {/* Modal handling */}
      {showDisclaimer && (
        <AntiFraudDisclaimerModal
          user={user}
          onClose={() => {
            setShowDisclaimer(false);
            refreshUserData();
          }}
          refreshUser={refreshUserData}
        />
      )}

      {!showDisclaimer && showProfileEnforcement && (
        <ProfilePictureEnforcementModal
          open={showProfileEnforcement}
          onSuccess={() => {
            setShowProfileEnforcement(false);
            refreshUserData();
          }}
        />
      )}

<div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center text-xs" role="alert">
 
  <span className="block sm:inline">
    You can now call us for assistance. Reach out to our support team:  
    <a href="tel:+13852339891" className="underline">+1 385 233 9891</a> (US) |  
    <a href="tel:+14382314783" className="underline">+1 438 231 4783</a> 
    <span className="relative inline-flex items-center">
      (Canada)
      <span className="flex absolute h-2 w-2 -right-3 -top-1">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
      </span>
    </span>
  </span>
</div>


      <div
        className="bg-yellow-50 border border-yellow-300 text-yellow-800 px-4 py-3 rounded relative text-center text-xs mt-4 flex items-center justify-center"
        role="alert"
      >
        <FaShieldAlt className="text-yellow-800 mr-2" size={20} />
        <span className="block sm:inline">
          <strong>Stay Safe!</strong> Verdant Charity does not send messages or process transactions via WhatsApp. If you receive any unexpected requests for personal information or funds, please ignore them and verify directly with our support team.
          <span
            className="text-blue-600 font-bold cursor-pointer underline ml-1"
            onClick={() => window.open('https://www.verdantcharity.org/help/article/recognizing-and-avoiding-common-scammer-tricks', '_blank')}
          >
            Click here for security tips.
          </span>
        </span>
      </div>
      <div className="lg:flex lg:flex-row p-4 rounded-lg border border-gray-200">
        <Sidebar changeComponent={setActiveComponent} />
        <div className="mx-4 mt-8 rounded-lg flex-grow flex flex-col font-harmattan font-12">
          {componentMap[activeComponent]}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;