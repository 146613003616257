// Description: This component allows users to upload and update their profile or cover photos.
// It includes drag-and-drop functionality, file validation, and client-side image compression.
//scr/components/Profile/ProfilePhotoUploader.js
import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FaCloudUploadAlt, FaSpinner, FaLightbulb, FaTimes } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import imageCompression from 'browser-image-compression';
import api from '../../api';
import { useUser } from '../context';

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];
const COMPRESSION_TARGET_PROFILE_MB = 2;  // Target compression size for profile photos
const COMPRESSION_TARGET_COVER_MB = 2;    // Target compression size for cover photos
const MAX_PROFILE_UPLOAD_MB = 10;        // Max allowed size after compression for profile
const MAX_COVER_UPLOAD_MB = 10;            // Max allowed size after compression for cover

const ProfilePhotoUploader = ({
  photoType = 'profile',
  existingPhotoUrl = '',
  onSave,
  onCancel,
  onError,
  showCancel = true,
  enforceRequirements = false
}) => {
  const { user, login, logout } = useUser();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(existingPhotoUrl);
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [compressionProgress, setCompressionProgress] = useState(0);

  const dropzoneRef = useRef(null);
  const fileInputRef = useRef(null);

  const refreshUserData = async () => {
    try {
      const config = {
        headers: { 'Authorization': `Bearer ${user.token}` },
      };
      const response = await api.get('/api/auth/info', config);

      if (response.status === 200 && response.data?.primaryInfo) {
        const updatedUser = {
          ...user,
          ...response.data,
          primaryInfo: { ...user.primaryInfo, ...response.data.primaryInfo }
        };

        login(updatedUser);
        return true;
      }
      throw new Error('Invalid user data response');
    } catch (error) {
      console.error('Error refreshing user data:', error);
      if (onError) {
        onError({
          type: 'refresh_error',
          message: 'Failed to refresh user data',
          error: error.response?.data || error.message
        });
      }
      return false;
    }
  };

  const validateAndCompressFile = useCallback(async (file) => {
    try {
      // Validate file type
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        throw new Error('Only JPEG or PNG images are allowed');
      }

      // Initial size check before compression
      const initialSizeMB = file.size / (1024 * 1024);
      const maxAllowedMB = photoType === 'profile' ? MAX_PROFILE_UPLOAD_MB : MAX_COVER_UPLOAD_MB;

      if (initialSizeMB > maxAllowedMB * 2) { // Give buffer before compression
        throw new Error(
          `File is too large (${initialSizeMB.toFixed(2)}MB). ` +
          `Maximum allowed after compression: ${maxAllowedMB}MB`
        );
      }

      // Configure compression
      const options = {
        maxSizeMB: photoType === 'profile' ? COMPRESSION_TARGET_PROFILE_MB : COMPRESSION_TARGET_COVER_MB,
        maxWidthOrHeight: photoType === 'profile' ? 800 : 1920,
        useWebWorker: true,
        onProgress: (percentage) => setCompressionProgress(percentage),
      };

      const compressedFile = await imageCompression(file, options);
      const compressedSizeMB = compressedFile.size / (1024 * 1024);

      if (compressedSizeMB > maxAllowedMB) {
        throw new Error(
          `File size after compression: ${compressedSizeMB.toFixed(2)}MB. ` +
          `Maximum allowed: ${maxAllowedMB}MB`
        );
      }

      // Create preview and update state
      const reader = new FileReader();
      reader.onload = (event) => setPreviewUrl(event.target.result);
      reader.readAsDataURL(compressedFile);

      setSelectedFile(compressedFile);
      setError('');
    } catch (err) {
      console.error('Error processing image:', err);
      setError(err.message);
      if (fileInputRef.current) fileInputRef.current.value = '';
    } finally {
      setCompressionProgress(0);
    }
  }, [photoType]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files[0]) {
      validateAndCompressFile(e.dataTransfer.files[0]);
    }
  }, [validateAndCompressFile]);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      validateAndCompressFile(e.target.files[0]);
    }
  };

  // Helper function to replace S3 URL with CloudFront URL
  const replaceWithCloudFrontUrl = (url) => {
    if (!url || typeof url !== "string") return url; 
    return url.replace(
      "verdantcharity.s3.amazonaws.com",
      "d30sleee6j32ev.cloudfront.net"
    );
  };

  const handleSaveClick = async () => {
    if (!user || !user.token) {
      logout();
      navigate('/login');
      return; // Exit early to prevent further execution
    }
    if (!selectedFile) {
      setError('Please select a file first');
      return;
    }
  
    setIsSaving(true);
    setError('');
  
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('type', photoType);
  
      // Add enforcement flag for profile photos
      if (photoType === 'profile') {
        formData.append('enforceRequirements', 'true');
      }
  
      const uploadResponse = await api.put('/api/uploads/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user.token}`,
        },
      });
  
      if (uploadResponse.status !== 200) {
        throw new Error(uploadResponse.data?.message || 'Upload failed');
      }
  
      const newImageUrl = replaceWithCloudFrontUrl(uploadResponse.data.imageUrl);
  
      // Immediate UI update while refreshing data in background
      setPreviewUrl(newImageUrl);
      onSave(photoType, newImageUrl, { success: true });
  
      toast.success(`${photoType === 'profile' ? 'Profile' : 'Cover'} photo updated successfully!`, {
        icon: photoType === 'profile' ? '👤' : '🖼️',
      });
  
      // Optional: Force refresh if needed
      await refreshUserData();
  
    } catch (err) {
      console.error('Upload error:', err);
      const errorData = err.response?.data || {};
      const errorMessage = errorData.message || err.message || 'Upload failed';
  
      // Special handling for validation errors
      if (errorData.type === 'validation_failed') {
        toast.error(`Validation Error: ${errorMessage}`, {
          icon: '⚠️',
          duration: 5000,
        });
        setError(errorMessage);
      } else {
        // Generic error handling
        toast.error(errorMessage);
        setError(errorMessage.startsWith('Validation Error') ? 
          errorMessage : `Upload failed: ${errorMessage}`);
      }
  
      if (onError) {
        onError({
          type: errorData.type || 'upload_error',
          message: errorMessage,
          status: err.response?.status,
          details: errorData.details
        });
      }
  
      // Reset preview on serious errors
      if (err.response?.status >= 500) {
        setPreviewUrl(existingPhotoUrl);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancelClick = () => {
    setSelectedFile(null);
    setPreviewUrl(existingPhotoUrl);
    setError('');
    if (onCancel) onCancel();
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center p-3 sm:p-4 md:p-6 rounded-lg">
      <Toaster
  position="top-center"
  gutter={8}
  toastOptions={{
    className: "",
    duration: 3000,
    style: {
      background: "linear-gradient(135deg, rgba(255,255,255,0.95), rgba(245,245,245,0.95))",
      backdropFilter: "blur(12px)",
      borderRadius: "16px",
      boxShadow: "0 8px 32px rgba(0,0,0,0.1)",
      border: "1px solid rgba(255,255,255,0.3)",
      padding: "12px 24px",
      color: "#333",
      fontSize: "14px",
      fontWeight: 600,
      animation: "slideIn 0.3s ease-out, fadeOut 0.3s ease-in forwards 2.7s"
    },
    success: {
      icon: "🎉",
      style: {
        borderLeft: "4px solid #4BB543",
      }
    },
    error: {
      icon: "❌",
      style: {
        borderLeft: "4px solid #ff3333",
      }
    },
    loading: {
      style: {
        borderLeft: "4px solid #3498db",
      }
    }
  }}
/>

<style jsx global>{`
  .toast {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    overflow: hidden;
  }

  .toast::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background: rgba(0,0,0,0.1);
    animation: progress ${({duration}) => duration || 3000}ms linear forwards;
  }

  @keyframes slideIn {
    from {
      transform: translateY(-100px) scale(0.8);
      opacity: 0;
    }
    to {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from { opacity: 1 }
    to { opacity: 0 }
  }

  @keyframes progress {
    from { width: 100% }
    to { width: 0% }
  }

  .toast:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 40px rgba(0,0,0,0.15);
    transition: all 0.2s ease;
  }
`}</style>
      <h2 className="text-lg sm:text-xl font-bold text-emerald-700 mb-3 sm:mb-4">
        {photoType === 'cover' ? 'Update Cover Photo' : 'Update Profile Photo'}
      </h2>

      {/* DRAG & DROP ZONE */}
      <div
        ref={dropzoneRef}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`
          w-full h-32 sm:h-40 flex items-center justify-center rounded-lg border-2 border-dashed cursor-pointer 
          transition-colors 
          ${isDragging ? 'border-emerald-500 bg-emerald-50' : 'border-emerald-300 bg-white'}
        `}
        onClick={() => fileInputRef.current?.click()}
      >
        {previewUrl ? (
          <div className="relative w-full h-full">
            <img
              src={previewUrl}
              alt="Preview"
              className="h-full w-full object-cover rounded"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveImage();
              }}
              className="absolute top-2 right-2 bg-black/50 text-white p-1 rounded-full hover:bg-black/70"
            >
              <FaTimes size={14} />
            </button>
          </div>
        ) : (
          <div className="text-center text-gray-500 px-2">
            <FaCloudUploadAlt className="text-2xl sm:text-3xl mx-auto mb-1 sm:mb-2" />
            <p className="text-xs sm:text-sm">Drag & drop or click to select a file</p>
            <p className="text-xs mt-1">
              Max size: {photoType === 'profile' ? MAX_PROFILE_UPLOAD_MB : MAX_COVER_UPLOAD_MB}MB
            </p>
          </div>
        )}
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpeg,.png,.jpg"
          onChange={handleFileChange}
          className="hidden"
        />
      </div>

      {/* Compression Progress */}
      {compressionProgress > 0 && (
        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
          <div
            className="bg-emerald-600 h-2.5 rounded-full"
            style={{ width: `${compressionProgress}%` }}
          ></div>
        </div>
      )}

      {/* SELECT FILE BUTTON - PLACE IT RIGHT HERE */}
      <div className="mt-3 sm:mt-4">
        <label className="flex items-center justify-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded border border-emerald-500 text-emerald-500 cursor-pointer hover:bg-emerald-50 hover:border-emerald-700 hover:text-emerald-700 text-sm sm:text-base">
          <FaCloudUploadAlt className="text-sm sm:text-base" /> Select File
          <input
            type="file"
            accept=".jpeg,.png,.jpg"
            onChange={handleFileChange}
            className="hidden"
          />
        </label>
      </div>

      {/* ERROR MESSAGE */}
      {error && (
        <p className="text-red-600 text-sm mt-2 flex items-center">
          <FaTimes className="mr-1" /> {error}
        </p>
      )}

      {/* INFORMATIONAL TEXT */}
      <div className="w-full mt-4 sm:mt-6">
        <div className="flex items-center text-xs text-gray-500">
          <FaLightbulb className="text-yellow-400 mr-1 sm:mr-2 flex-shrink-0" />
          <p>
            {enforceRequirements && photoType === 'profile' ? (
              'For verification: Clear face photo, no filters, good lighting'
            ) : (
              'For best results, use high-quality images'
            )}
          </p>
        </div>
        <hr className="my-3 sm:my-4 border-gray-300" />
      </div>

      {/* ACTION BUTTONS */}
      <div className="grid place-items-center w-full mt-4">
        <div className={`grid ${showCancel ? 'grid-cols-2' : 'grid-cols-1'} gap-4 w-full`}>
          <button
            onClick={handleSaveClick}
            disabled={isSaving || !selectedFile}
            className={`px-4 sm:px-6 py-2 rounded border border-emerald-500 text-emerald-500 hover:bg-emerald-50 hover:border-emerald-700 hover:text-emerald-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center text-sm sm:text-base ${!showCancel ? 'w-full' : ''}`}
          >
            {isSaving ? (
              <>
                <FaSpinner className="animate-spin mr-2 text-sm sm:text-base" /> Saving...
              </>
            ) : (
              'Save'
            )}
          </button>
          {showCancel && (
            <button
              onClick={handleCancelClick}
              disabled={isSaving}
              className="px-4 sm:px-6 py-2 rounded border border-gray-500 text-gray-500 hover:bg-gray-100 hover:border-gray-700 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed text-sm sm:text-base"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

ProfilePhotoUploader.propTypes = {
  photoType: PropTypes.oneOf(['profile', 'cover']),
  existingPhotoUrl: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  showCancel: PropTypes.bool,
  enforceRequirements: PropTypes.bool
};

export default ProfilePhotoUploader;