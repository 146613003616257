import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './context';

// Example icons for demonstration (swap them as you like)
import {
  FaTachometerAlt,
  FaHandHoldingHeart,
  FaHandsHelping,
  FaInfoCircle,
  FaPhoneAlt,
  FaSignOutAlt,
  FaBars,
  FaTimes,
  FaQuestionCircle, // Help Icon
} from 'react-icons/fa';

const Navbar = () => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  return (
    <nav
      className="
        fixed
        top-0
        left-0
        w-full
        z-50
        bg-gradient-to-r from-green-300 to-green-200
        bg-opacity-20
        backdrop-filter 
        backdrop-blur-lg
        p-2
        text-black
        shadow-sm
        transition-colors
        duration-300
      "
    >
      <div className="container mx-auto flex justify-between items-center">
        {/* Brand / Logo */}
        <div className="flex items-center space-x-2">
          <Link to="/" className="flex items-center hover:opacity-90" onClick={closeMobileMenu}>
            <img
              src="/dashboard_images/verdant_design.png"
              alt="Verdant Charity"
              className="h-12 w-auto transition-transform duration-300 hover:scale-105"
            />
          </Link>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex space-x-4 text-sm">
          <Link
            to="/"
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaTachometerAlt />
            <span>Dashboard</span>
          </Link>
          <Link
            to="/Crowdfund"
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaHandHoldingHeart />
            <span>Crowdfund</span>
          </Link>
          <Link
            to="/donations"
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaHandsHelping />
            <span>Donations</span>
          </Link>
          <Link
            to="/learn-more"
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaInfoCircle />
            <span>About</span>
          </Link>
          <Link
            to="/learn-more/contact-us"
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaPhoneAlt />
            <span>Contact Us</span>
          </Link>
          <Link
            to="/help"
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaQuestionCircle />
            <span>Help</span>
          </Link>
          {user && (
            <button
              onClick={handleLogout}
              className="
                flex
                items-center
                space-x-1
                px-3 py-2
                rounded-md
                hover:text-white
                transition-colors
                border border-transparent
                hover:border-white
              "
            >
              <FaSignOutAlt />
              <span>Logout</span>
            </button>
          )}
        </div>

        {/* Mobile Menu Toggle */}
        <div
          className="md:hidden cursor-pointer p-2"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? (
            <FaTimes className="w-6 h-6 hover:text-white transition-colors" />
          ) : (
            <FaBars className="w-6 h-6 hover:text-white transition-colors" />
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          isMobileMenuOpen ? 'block' : 'hidden'
        } md:hidden bg-gradient-to-r from-green-300 to-green-100 bg-opacity-70 backdrop-filter backdrop-blur-md transition-all`}
      >
        <hr className="border-t border-gray-300 my-2 opacity-50" />
        <div className="px-4 pt-2 pb-3 space-y-2 text-sm ">
          <Link
            to="/"
            onClick={closeMobileMenu}
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaTachometerAlt />
            <span>Dashboard</span>
          </Link>
          <Link
            to="/Crowdfund"
            onClick={closeMobileMenu}
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaHandHoldingHeart />
            <span>Crowdfund</span>
          </Link>
          <Link
            to="/donations"
            onClick={closeMobileMenu}
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaHandsHelping />
            <span>Donations</span>
          </Link>
          <Link
            to="/learn-more"
            onClick={closeMobileMenu}
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaInfoCircle />
            <span>About</span>
          </Link>
          <Link
            to="/learn-more/contact-us"
            onClick={closeMobileMenu}
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaPhoneAlt />
            <span>Contact Us</span>
          </Link>
          <Link
            to="/help"
            onClick={closeMobileMenu}
            className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors"
          >
            <FaQuestionCircle />
            <span>Help</span>
          </Link>
          {user && (
            <button
              onClick={() => {
                handleLogout();
                closeMobileMenu();
              }}
              className="flex items-center space-x-1 px-3 py-2 rounded-md hover:text-white transition-colors border border-transparent hover:border-white"
            >
              <FaSignOutAlt />
              <span>Logout</span>
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;