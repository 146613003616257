// Description: Profile component for user profile management, including personal info, notifications, and community standards.
//src/components/Profile/Profile.js
import React, { useState, useEffect } from 'react';
import {
  FaMapMarkerAlt,
  FaCrown,
  FaBusinessTime,
  FaUser,
  FaShieldAlt,
  FaInfoCircle,
  FaHandsHelping,
  FaMedal,
  FaCalendarAlt,
  FaUserFriends,
  FaTrophy,
  FaBell,
  FaDonate,
  FaLock,
  FaHome,
  FaThumbsUp,
  FaAward,
} from 'react-icons/fa';
import { AiOutlineArrowRight } from 'react-icons/ai';

import PersonalInfo from './PersonalInfo';
import NotificationSettings from './NotificationSettings';
import Authentication from './Authentication';
import TermsOfService from './TermsOfService';
import CommunityStandards from './CommunityStandards';
import UserBadges from './UserBadges';
import ChangePassword from '../auth/ChangePassword'; // Separate file for community features

import { useUser } from '../context';
import api from '../../api';

// Our photo uploader can handle both profile & cover
import ProfilePhotoUploader from './ProfilePhotoUploader';

const Profile = () => {
  const { user } = useUser();

  // Track active section
  const [activeSection, setActiveSection] = useState('Overview');

  // For location data
  const [mostRecentLocation, setMostRecentLocation] = useState('Location unavailable');

  // Default images
  const defaultProfilePhotoUrl =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLgeB7GPHeXY4XZB6p_Gb1rQOjdofnQ4y3FAU3X7LKhWK12meoTCsZPva7eVicCJ9x-6M&usqp=CAU';

  // Helper function to replace S3 URL with CloudFront URL
  const replaceWithCloudFrontUrl = (url) => {
    if (!url || typeof url !== "string") return url; // Validate URL
    return url.replace(
      "verdantcharity.s3.amazonaws.com",
      "d30sleee6j32ev.cloudfront.net"
    );
  };

  // If the user has a cover photo, we use it; otherwise a default
  const [coverPhotoUrl, setCoverPhotoUrl] = useState(
    replaceWithCloudFrontUrl(user?.primaryInfo.coverPhotoUrl) ||
    'https://www.lesecologycenter.org/wp-content/uploads/page/Vol-IMG_4255-2048x1536.jpg'
  );

  // If the user has a profile image, we use it; otherwise a default
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(
    replaceWithCloudFrontUrl(user?.primaryInfo?.profilePhotoUrl) || defaultProfilePhotoUrl
  );


  // editingPhotoType = null, 'profile', or 'cover'
  const [editingPhotoType, setEditingPhotoType] = useState(null);

  // Navigation
  const handleNavClick = (sectionValue) => {
    setActiveSection(sectionValue);
    window.scrollTo({ top: document.body.scrollHeight * 0.75, behavior: 'smooth' });
  };

  // Fetch location (example)
  useEffect(() => {
    const fetchRecentLoginHistory = async () => {
      try {
        const response = await api.get('/api/auth/recent-login-history', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        });
        const history = response.data;
        if (history.length > 0) {
          const { locationName } = history[0];
          setMostRecentLocation(locationName || 'Location unavailable');
        }
      } catch (error) {
        console.error('Failed to fetch login history:', error);
      }
    };

    if (user?.token) {
      fetchRecentLoginHistory();
    }
  }, [user]);

  // Render main content
  const renderActiveSection = () => {
    switch (activeSection) {
      case 'Overview':
        return (
          <div className="w-full">
            <div className="rounded-lg bg-gradient-to-r from-emerald-50 to-white shadow p-6 mb-6">
              <h2 className="text-2xl font-semibold mb-4">Overview</h2>
              <p className="text-gray-700">
                Welcome to your personal dashboard! Here you can manage your profile,
                check notifications, see upcoming events, and connect with the community.
              </p>
            </div>
            <PersonalInfo />
          </div>
        );
      case 'PersonalInfo':
        return <PersonalInfo />;
      case 'Badges':
        return <UserBadges />;
      case 'Notifications':
        return <NotificationSettings />;
      case 'Authentication':
        return <Authentication />;
      case 'TermsOfService':
        return <TermsOfService />;
      case 'CommunityStandards':
        return <CommunityStandards />;
      case 'ChangePassword':
        return <ChangePassword />;
      default:
        return null;
    }
  };

  // Save callback from PhotoUploader
  const handleSavePhoto = (photoType, newImageUrl) => {
    if (photoType === 'profile') {
      setProfilePhotoUrl(newImageUrl);
    } else if (photoType === 'cover') {
      setCoverPhotoUrl(newImageUrl);
    }
    setEditingPhotoType(null);
  };

  // Cancel callback
  const handleCancelPhoto = () => {
    setEditingPhotoType(null);
  };

  // Nav items
  const navItems = [
    { label: 'Overview', value: 'Overview', icon: <FaHome /> },
    { label: 'Personal Info', value: 'PersonalInfo', icon: <FaUser /> },
    { label: 'Badges', value: 'Badges', icon: <FaMedal /> },
    { label: 'Notifications', value: 'Notifications', icon: <FaBell /> },
    { label: 'Authentication', value: 'Authentication', icon: <FaShieldAlt /> },
    { label: 'Terms of Service', value: 'TermsOfService', icon: <FaInfoCircle /> },
    { label: 'Community Standards', value: 'CommunityStandards', icon: <FaHandsHelping /> },
    { label: 'Change Password', value: 'ChangePassword', icon: <FaLock /> },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-white">
      <div className="max-w-7xl mx-auto py-8 flex flex-col lg:flex-row gap-6">
        {/* Left Sidebar */}
        <aside className="flex-none w-full lg:w-64 space-y-6">
          {/* Profile Card */}
          <div className="bg-white rounded-lg shadow relative">
            {/* 1) If editing ANY photo, show *only* the uploader */}
            {editingPhotoType ? (
              <div className="p-4 min-h-[300px] flex items-center justify-center">
                <ProfilePhotoUploader
                  photoType={editingPhotoType}
                  // If editing cover, show coverPhotoUrl; otherwise show profilePhotoUrl
                  existingPhotoUrl={
                    editingPhotoType === 'cover' ? coverPhotoUrl : profilePhotoUrl
                  }
                  onSave={handleSavePhoto}
                  onCancel={handleCancelPhoto}
                />
              </div>
            ) : (
              // 2) NOT editing => show normal cover & profile layout
              <>
                {/* Cover Photo & Edit Cover Button */}
                <div className="relative">
                  <img
                    src={coverPhotoUrl}
                    alt="Cover"
                    className="w-full h-32 object-cover rounded-t-lg"
                  />
                  <button
                    onClick={() => setEditingPhotoType('cover')}
                    className="absolute top-2 right-2 bg-gradient-to-r from-green-400 to-emerald-600
            text-white text-xs px-2 py-1 rounded hover:from-green-500 
            hover:to-emerald-700 shadow"
                  >
                    Edit Cover
                  </button>

                  {/* Profile Photo Overlapping the Cover */}
                  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
                    <div className="relative">
                      <img
                        src={profilePhotoUrl}
                        alt="Profile"
                        className="h-24 w-24 rounded-full border-4 border-white object-cover"
                      />
                      <button
                        onClick={() => setEditingPhotoType('profile')}
                        className="absolute bottom-0 right-0 bg-gradient-to-r from-green-400 to-emerald-600
                text-white text-xs px-2 py-1 rounded hover:from-green-500 
                hover:to-emerald-700 shadow"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>

                {/* Info Section Below */}
                <div className="mt-16 p-4 text-center">
                  <h2 className="text-lg font-bold text-gray-800 flex items-center justify-center">
                    {user?.primaryInfo?.firstName} {user?.primaryInfo?.lastName}
                    {user?.isPremium && (
                      <FaCrown className="ml-2 text-yellow-500" title="Premium Member" />
                    )}
                  </h2>
                  <p className="text-gray-600 text-sm mt-1 mb-2">
                    {user?.accountType === 'business' ? (
                      <>
                        <FaBusinessTime className="inline-block mr-1" /> Business(NGO) Account
                      </>
                    ) : (
                      <>
                        <FaUser className="inline-block mr-1" /> Individual Account
                      </>
                    )}
                  </p>
                  <p className="text-gray-500 text-xs mb-1">
                    Joined on{' '}
                    {new Date(user?.primaryInfo?.createdAt).toLocaleDateString(undefined, {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>
                  <div className="flex justify-center items-center text-sm">
                    <FaMapMarkerAlt className="text-red-500 mr-1" />
                    <span className="text-gray-500">{mostRecentLocation}</span>
                  </div>
                </div>

                <hr />

                <div className="px-4 py-3 flex justify-around text-center text-sm text-gray-600">
                  <div className="flex flex-col items-center">
                    <FaUserFriends className="text-gray-500 text-sm mb-1" />
                    <p className="font-bold">0</p>
                    <p>Followers</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <FaThumbsUp className="text-gray-500 text-sm mb-1" />
                    <p className="font-bold">0</p>
                    <p>Endorsements</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <FaAward className="text-gray-500 text-sm mb-1" />
                    <p className="font-bold">0</p>
                    <p>Badges</p>
                  </div>
                </div>
              </>
            )}
          </div>


          {/* Navigation Links */}
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-gray-800 font-semibold mb-2">Profile Sections</h3>
            <ul className="space-y-2">
              {navItems.map((item) => {
                const isActive = activeSection === item.value;
                return (
                  <li key={item.value}>
                    <button
                      className={`
                        w-full text-left px-3 py-2 flex items-center rounded transition 
                        ${isActive
                          ? 'bg-emerald-50 font-semibold text-emerald-700 border-l-4 border-emerald-500'
                          : 'hover:bg-emerald-50 text-gray-600'
                        }
                      `}
                      onClick={() => handleNavClick(item.value)}
                    >
                      <span className="text-sm mr-2">{item.icon}</span>
                      {item.label}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </aside>

        {/* Main Content */}
        <main className="flex-grow">{renderActiveSection()}</main>

        {/* Right Sidebar */}
        <aside className="flex-none w-full lg:w-60 space-y-6 bg-gradient-to-b from-white to-emerald-50 p-1 rounded-md">
          {/* Notifications */}
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-bold mb-4 flex items-center">
              <FaBell className="mr-2 text-yellow-500" /> Notifications
            </h3>
            <ul className="text-sm text-gray-600 space-y-3">
              <li className="flex items-start">
                <FaUserFriends className="text-blue-500 mr-2 mt-1" />
                <span>
                  <strong>Verdant Charity</strong> started following you.
                </span>
              </li>
              <li className="flex items-start">
                <FaDonate className="text-green-500 mr-2 mt-1" />
                <span>
                  Your donation to <strong>Clean Water Initiative</strong> was successful.
                </span>
              </li>
              <li className="flex items-start">
                <FaTrophy className="text-orange-500 mr-2 mt-1" />
                <span>
                  You earned the <strong>Donor</strong> badge.
                </span>
              </li>
            </ul>
          </div>
          {/* Upcoming Events */}
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-bold mb-4 flex items-center">
              <FaCalendarAlt className="text-emerald-600 mr-2" /> Upcoming Events
            </h3>
            <ul className="text-sm text-gray-600 space-y-3">
              <li className="flex items-center">
                <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  Nov 20
                </span>
                Charity Run
              </li>
              <li className="flex items-center">
                <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  Dec 5
                </span>
                Food Drive
              </li>
              <li className="flex items-center">
                <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  Dec 18
                </span>
                Holiday Gala
              </li>
            </ul>
            <a
              href="#"
              className="text-emerald-600 text-sm mt-4 flex items-center hover:underline"
            >
              View All Events <AiOutlineArrowRight className="ml-1" />
            </a>
          </div>
          {/* Trending Campaigns */}
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-bold mb-4 flex items-center">
              <FaTrophy className="mr-2 text-purple-500" /> Trending Campaigns
            </h3>
            <ul className="text-sm text-gray-600 space-y-3">
              <li>
                <p className="font-bold">Education for All</p>
                <p className="text-gray-500 text-xs">
                  Providing education to underprivileged children.
                </p>
              </li>
              <li>
                <p className="font-bold">Disaster Relief Fund</p>
                <p className="text-gray-500 text-xs">
                  Supporting communities affected by natural disasters.
                </p>
              </li>
              <li>
                <p className="font-bold">Wildlife Conservation</p>
                <p className="text-gray-500 text-xs">
                  Protecting endangered species and habitats.
                </p>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Profile;
