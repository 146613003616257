// File: DonationCampaignWizard.jsx
import React, { useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import 'react-quill/dist/quill.snow.css';
import {
    FaArrowLeft,
    FaArrowRight,
    FaSave,
    FaSpinner,
    FaInfoCircle,
    FaTimesCircle,
    FaShareSquare,
    FaCopy,
} from 'react-icons/fa';

import FileUploadWithPreview from './FileUploadWithPreview';
import api from '../../api';
import { useUser } from '../context';

const stepsTitles = [
    'Intro',
    'Campaign Basics',
    'Funding & Timeline',
    'Media Uploads',
    'Review & Submit',
    // Step 5 is "Success" (not shown in the step indicator, but we handle it in code)
];

// Default form data – note `fundingGoal` is now 0 to force user input
const initialForm = {
    campaignTitle: '',
    campaignDescription: '',
    campaignType: 'donation-based',
    fundingGoal: 0, // changed from 1000
    currency: 'USD',
    fundingModel: 'flexible',
    startDate: '',
    endDate: '',
    pitchDeckUrl: '',
    promoVideoUrl: '',
    tags: '',
};

/**
 * StepIndicator – memoized to avoid frequent re-renders
 */
const StepIndicator = memo(function StepIndicator({ step }) {
    // We only display steps 0-4 in the indicator. Step 5 is a success screen.
    return (
        <div className="mb-6 overflow-x-auto flex items-center justify-center">
            <div className="flex items-center justify-center min-w-[400px]">
                {stepsTitles.slice(0, 5).map((title, idx) => (
                    <div key={idx} className="flex items-center">
                        <div
                            className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full flex items-center justify-center text-xs sm:text-sm font-bold
                ${step === idx
                                    ? 'bg-emerald-400 text-white border border-emerald-500'
                                    : idx < step
                                        ? 'bg-emerald-100 text-emerald-700 border border-emerald-200'
                                        : 'bg-gray-200 text-gray-600 border border-gray-300'
                                }`}
                        >
                            {idx + 1}
                        </div>
                        {idx < 4 && (
                            <div className="w-6 sm:w-8 h-[2px] bg-gray-200 mx-1 sm:mx-2" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
});

/**
 * Container – memoized container component
 */
const Container = memo(function Container({ step, children }) {
    return (
        <div className="max-w-3xl mx-auto mt-6 bg-white border border-emerald-200 rounded-md shadow-md p-6">
            <StepIndicator step={step} />
            {children}
        </div>
    );
});

const DonationCampaignWizard = ({ existingCampaign, onCancel, onSuccess }) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState(initialForm);
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [copied, setCopied] = useState(false);

    // For file uploads
    const [coverImageFiles, setCoverImageFiles] = useState([]);
    const [galleryImagesFiles, setGalleryImagesFiles] = useState([]);

    // For success step (step = 5)
    const [createdCampaign, setCreatedCampaign] = useState(null);

useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
      return;
    }
  }, []);


    useEffect(() => {
        (async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            setFormData((prev) => ({ ...prev, fingerprint: result.visitorId }));
        })();
    }, []);

    // --- If editing an existing campaign ---
    useEffect(() => {
        if (existingCampaign) {
            setFormData({
                campaignTitle: existingCampaign.campaignTitle || '',
                campaignDescription: existingCampaign.campaignDescription || '',
                campaignType: existingCampaign.campaignType || 'donation-based',
                fundingGoal: existingCampaign.fundingGoal || 0,
                currency: existingCampaign.currency || 'USD',
                fundingModel: existingCampaign.fundingModel || 'flexible',
                startDate: existingCampaign.startDate
                    ? new Date(existingCampaign.startDate).toISOString().split('T')[0]
                    : '',
                endDate: existingCampaign.endDate
                    ? new Date(existingCampaign.endDate).toISOString().split('T')[0]
                    : '',
                pitchDeckUrl: existingCampaign.pitchDeckUrl || '',
                promoVideoUrl: existingCampaign.promoVideoUrl || '',
                tags: existingCampaign.tags || '',
            });
        }
    }, [existingCampaign]);

    // --- Update form field helper ---
    const updateField = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    /**
     * validateStep checks the current step for required fields and custom validations.
     */
    const validateStep = (idx) => {
        const newErrors = {};
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);

        // Step 1: Campaign Basics
        if (idx === 1) {
            // Title must be at least 25 characters
            if (!formData.campaignTitle.trim() || formData.campaignTitle.trim().length < 25) {
                newErrors.campaignTitle = 'Campaign title must be at least 25 characters.';
            }
            // Remove HTML tags from description before validating length
            const plainTextDescription = formData.campaignDescription.replace(/<[^>]+>/g, '').trim();
            if (plainTextDescription.length < 200) {
                newErrors.campaignDescription = 'Description must be at least 200 characters.';
            }
        }

        // Step 2: Funding & Timeline
        if (idx === 2) {
            const goal = Number(formData.fundingGoal);
            if (isNaN(goal) || goal < 1000 || goal > 50000) {
                newErrors.fundingGoal = 'Funding goal must be between USD 1000 and 50000.';
            }
            // Validate start date: must be today or in the future.
            if (formData.startDate) {
                const startDateVal = new Date(formData.startDate);
                if (startDateVal < todayDate) {
                    newErrors.startDate = 'Start date must be today or in the future.';
                }
            }
            // End date must be provided and be between 7 and 120 days after start date.
            if (!formData.endDate) {
                newErrors.endDate = 'An end date is required.';
            } else {
                const start = formData.startDate ? new Date(formData.startDate) : todayDate;
                const end = new Date(formData.endDate);
                const diffDays = (end - start) / (1000 * 60 * 60 * 24);
                if (diffDays < 7) {
                    newErrors.endDate = 'End date must be at least 7 days after the start date.';
                }
                if (diffDays > 120) {
                    newErrors.endDate = 'End date cannot be more than 120 days after the start date.';
                }
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // --- Navigation (Next / Prev) ---
    const handleNext = () => {
        // Validate steps 1 and 2.
        if ([1, 2].includes(step)) {
            if (!validateStep(step)) return;
        }
        // For step 3 (Media Uploads), check that cover image is provided.
        if (step === 3) {
            if (coverImageFiles.length === 0) {
                setErrors({ coverImage: 'Cover image is required.' });
                return;
            } else {
                setErrors((prev) => {
                    const { coverImage, ...rest } = prev;
                    return rest;
                });
            }
        }
        setStep((s) => s + 1);
    };

    const handlePrev = () => {
        setStep((s) => s - 1);
    };

    // --- Date Constraints ---
    const todayStr = new Date().toISOString().split('T')[0];

    const minEndDate = formData.startDate
        ? (() => {
            const start = new Date(formData.startDate);
            start.setDate(start.getDate() + 7);
            return start.toISOString().split('T')[0];
        })()
        : (() => {
            const d = new Date();
            d.setDate(d.getDate() + 7);
            return d.toISOString().split('T')[0];
        })();

    const handleSubmit = async () => {
        setSubmitting(true);
        setErrors({});

        try {
            const dataToSend = new FormData();
            dataToSend.append('fingerprint', formData.fingerprint || '');
            dataToSend.append('campaignTitle', formData.campaignTitle);
            dataToSend.append('campaignDescription', formData.campaignDescription);
            dataToSend.append('campaignType', formData.campaignType);
            dataToSend.append('fundingGoal', formData.fundingGoal);
            dataToSend.append('currency', formData.currency);
            dataToSend.append('fundingModel', formData.fundingModel);
            dataToSend.append('startDate', formData.startDate || '');
            dataToSend.append('endDate', formData.endDate);
            dataToSend.append('pitchDeckUrl', formData.pitchDeckUrl);
            dataToSend.append('promoVideoUrl', formData.promoVideoUrl);
            dataToSend.append('tags', formData.tags);

            // Cover image
            if (coverImageFiles.length > 0) {
                dataToSend.append('coverImage', coverImageFiles[0]);
            }
            // Gallery images
            galleryImagesFiles.forEach((file) => {
                dataToSend.append('images', file);
            });

            const endpoint = '/api/donationcampaigns/campaign-create';
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            };

            const response = await api.post(endpoint, dataToSend, config);

            if (response.data.success) {
                // 1) store campaign in state
                setCreatedCampaign(response.data.data);
                // 2) move to success step
                setStep(5);
            } else {
                throw new Error(response.data.message || 'Failed to create campaign.');
            }
        } catch (error) {
            console.error('❌ Campaign submit error:', error);
            let userFriendlyMessage = 'Something went wrong. Please remove some Gallery Images and retry';
            if (error.response?.data?.message) {
                userFriendlyMessage = error.response.data.message;
            }
            setErrors({ submitError: userFriendlyMessage });
        } finally {
            setSubmitting(false);
        }
    };

    // --- Renders Steps ---
    // Step 0: Intro
    if (step === 0) {
        return (
            <div className="max-w-2xl mx-auto mt-6 bg-white border border-emerald-200 rounded-md p-6 shadow-lg">
                <div className="flex items-center mb-4 text-emerald-700">
                    <FaInfoCircle className="mr-2 text-xl" />
                    <h2 className="text-lg font-bold">🚀 Ready to Launch Your Donation Campaign?</h2>
                </div>

                <p className="text-sm text-gray-700 leading-relaxed mb-4">
                    Create a campaign that inspires donations to support your cause. Your story, a clear funding goal,
                    and engaging media are key!
                </p>

                <div className="bg-emerald-50 border-l-4 border-emerald-400 p-4 mb-4 text-xs text-emerald-700">
                    <p className="font-semibold">💡 Pro Tip: Successful Donation Campaigns Have</p>
                    <ul className="list-disc list-inside mt-2 space-y-1">
                        <li><strong>A Compelling Title:</strong> At least 10 characters, keep it clear.</li>
                        <li><strong>A Detailed Description:</strong> At least 200 characters explaining your cause.</li>
                        <li><strong>Clear Funding Goal:</strong> Must be between 1000 and 50000 USD.</li>
                        <li><strong>High-Quality Media:</strong> A captivating cover image is required.</li>
                    </ul>
                </div>

                <div className="flex items-center gap-3">
                    <button
                        onClick={() => setStep(1)}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-600 hover:bg-emerald-700 text-white border border-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-400 transition-all duration-150"
                    >
                        Let's Get Started!
                        <FaArrowRight className="ml-2" />
                    </button>
                    <button
                        onClick={onCancel}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700 text-sm"
                    >
                        <FaTimesCircle className="mr-1" />
                        Cancel
                    </button>
                </div>
            </div>
        );
    }

    // Step 1: Campaign Basics
    if (step === 1) {
        return (
            <Container step={step}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 1: Campaign Basics
                </h2>

                {/* Campaign Title */}
                <div className="mb-4">
                    <label htmlFor="campaignTitle" className="block mb-1 font-medium text-gray-700">
                        Campaign Title <span className="text-red-500">*</span>
                    </label>
                    <input
                        id="campaignTitle"
                        type="text"
                        placeholder="E.g. 'Support Our Community Project'"
                        value={formData.campaignTitle}
                        onChange={(e) => updateField('campaignTitle', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                    <span className="text-xs text-gray-500">
                        Must be at least 25 characters.
                    </span>
                    {errors.campaignTitle && (
                        <p className="text-red-600 text-sm mt-1">{errors.campaignTitle}</p>
                    )}
                </div>

                {/* Campaign Description */}
                <div className="mb-4">
                    <label htmlFor="campaignDescription" className="block mb-1 font-medium text-gray-700">
                        Campaign Description <span className="text-red-500">*</span>
                    </label>
                    <span className="text-xs text-gray-500 block mb-1">
                        Explain your cause in detail (at least 200 characters).
                    </span>
                    <div className="border border-gray-200 rounded p-2 mb-2">
                        <ReactQuill
                            id="campaignDescription"
                            theme="snow"
                            value={formData.campaignDescription}
                            onChange={(val) => updateField('campaignDescription', val)}
                            style={{ minHeight: '150px' }}
                        />
                    </div>
                    {errors.campaignDescription && (
                        <p className="text-red-600 text-sm mt-1">{errors.campaignDescription}</p>
                    )}
                </div>

                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                        disabled={step === 0}
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        onClick={handleNext}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    >
                        Next
                        <FaArrowRight className="ml-2" />
                    </button>
                </div>
            </Container>
        );
    }

    // Step 2: Funding & Timeline
    if (step === 2) {
        return (
            <Container step={step}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 2: Funding &amp; Timeline
                </h2>

                {/* Funding Goal with USD indicator */}
                <div className="mb-4">
                    <label htmlFor="fundingGoal" className="block mb-1 font-medium text-gray-700">
                        Funding Goal <span className="text-red-500">*</span>
                    </label>
                    <span className="text-xs text-gray-500">
                        Must be between 1000 and 50000 USD.
                    </span>
                    <div className="relative mt-1">
                        <input
                            id="fundingGoal"
                            type="number"
                            min="0"
                            value={formData.fundingGoal}
                            onChange={(e) => updateField('fundingGoal', e.target.value)}
                            className="w-full border border-gray-300 rounded p-2 pr-16 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-600 bg-gray-50 px-2 rounded-r">
                            USD
                        </div>
                    </div>
                    {errors.fundingGoal && (
                        <p className="text-red-600 text-sm mt-1">{errors.fundingGoal}</p>
                    )}
                </div>

                {/* Funding Model */}
                <div className="mb-4">
                    <label htmlFor="fundingModel" className="block mb-1 font-medium text-gray-700">
                        Funding Model
                    </label>
                    <span className="text-xs text-gray-500">
                        “Flexible” allows partial withdrawals; “All or Nothing” requires full goal.
                    </span>
                    <select
                        id="fundingModel"
                        value={formData.fundingModel}
                        onChange={(e) => updateField('fundingModel', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300 mt-1"
                    >
                        <option value="flexible">Flexible</option>
                        <option value="all-or-nothing">All or Nothing</option>
                    </select>
                </div>

                {/* Start and End Dates */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <div>
                        <label htmlFor="startDate" className="block mb-1 font-medium text-gray-700">
                            Start Date
                        </label>
                        <span className="text-xs text-gray-500">
                            Optional. Defaults to today if not set.
                        </span>
                        <input
                            id="startDate"
                            type="date"
                            value={formData.startDate}
                            onChange={(e) => updateField('startDate', e.target.value)}
                            min={todayStr}
                            className="w-full mt-1 border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        />
                        {errors.startDate && (
                            <p className="text-red-600 text-sm mt-1">{errors.startDate}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="endDate" className="block mb-1 font-medium text-gray-700">
                            End Date <span className="text-red-500">*</span>
                        </label>
                        <span className="text-xs text-gray-500">
                            Must be at least 7 days after start date.
                        </span>
                        <input
                            id="endDate"
                            type="date"
                            value={formData.endDate}
                            onChange={(e) => updateField('endDate', e.target.value)}
                            min={minEndDate}
                            className="w-full mt-1 border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                        />
                        {errors.endDate && (
                            <p className="text-red-600 text-sm mt-1">{errors.endDate}</p>
                        )}
                    </div>
                </div>

                {/* Pitch Deck & Promo Video URLs */}
                <div className="mb-4">
                    <label htmlFor="pitchDeckUrl" className="block mb-1 font-medium text-gray-700">
                        Pitch Deck URL <span className="text-xs text-gray-400">(optional)</span>
                    </label>
                    <span className="text-xs text-gray-500">
                        Provide additional campaign details via PDF, etc.
                    </span>
                    <input
                        id="pitchDeckUrl"
                        type="url"
                        placeholder="https://example.com/pitch.pdf"
                        value={formData.pitchDeckUrl}
                        onChange={(e) => updateField('pitchDeckUrl', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300 mt-1"
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="promoVideoUrl" className="block mb-1 font-medium text-gray-700">
                        Promo Video URL <span className="text-xs text-gray-400">(optional)</span>
                    </label>
                    <span className="text-xs text-gray-500">
                        Link to YouTube or another video platform.
                    </span>
                    <input
                        id="promoVideoUrl"
                        type="url"
                        placeholder="https://youtube.com/..."
                        value={formData.promoVideoUrl}
                        onChange={(e) => updateField('promoVideoUrl', e.target.value)}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300 mt-1"
                    />
                </div>

                {/* Tags */}
                <div className="mb-4">
                    <label htmlFor="tags" className="block mb-1 font-medium text-gray-700">
                        Tags (comma-separated)
                    </label>
                    <span className="text-xs text-gray-500">
                        E.g. charity, education, environment
                    </span>
                    <input
                        id="tags"
                        type="text"
                        placeholder="e.g. charity, community"
                        value={formData.tags}
                        onChange={(e) => updateField('tags', e.target.value)}
                        className="w-full mt-1 border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-emerald-300"
                    />
                </div>

                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        onClick={handleNext}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    >
                        Next
                        <FaArrowRight className="ml-2" />
                    </button>
                </div>
            </Container>
        );
    }

    // Step 3: Media Uploads
    if (step === 3) {
        return (
            <Container step={step}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 3: Media Uploads
                </h2>
                <p className="text-sm text-gray-500 mb-4 text-center">
                    Upload a cover image <strong>(required)</strong> and additional gallery images <em>(up to 5)</em>.
                </p>
                <FileUploadWithPreview
                    label="Cover Image"
                    accept="image/*"
                    multiple={false}
                    files={coverImageFiles}
                    setFiles={setCoverImageFiles}
                />
                {errors.coverImage && (
                    <p className="text-red-600 text-sm mt-1">{errors.coverImage}</p>
                )}
                <FileUploadWithPreview
                    label="Gallery Images (up to 5)"
                    accept="image/*"
                    multiple={true}
                    maxFiles={5}
                    files={galleryImagesFiles}
                    setFiles={setGalleryImagesFiles}
                />

                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        onClick={handleNext}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    >
                        Next
                        <FaArrowRight className="ml-2" />
                    </button>
                </div>
            </Container>
        );
    }

    // Step 4: Review & Submit
    if (step === 4) {
        return (
            <Container step={step}>
                <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
                    Step 4: Review &amp; Submit
                </h2>
                <p className="text-gray-600 text-center mb-6">
                    Please review your details below.
                </p>

                <div className="text-sm leading-relaxed space-y-2 mb-4">
                    <p>
                        <strong>Title:</strong> {formData.campaignTitle}
                    </p>
                    <p>
                        <strong>Type:</strong> {formData.campaignType}
                    </p>
                    <p>
                        <strong>Goal:</strong> {formData.fundingGoal} {formData.currency}
                    </p>
                    <p>
                        <strong>Funding Model:</strong> {formData.fundingModel}
                    </p>
                    <p>
                        <strong>Start:</strong> {formData.startDate || '(not set)'}{' '}
                        <strong>End:</strong> {formData.endDate}
                    </p>
                    <p>
                        <strong>Pitch Deck:</strong> {formData.pitchDeckUrl || 'N/A'}
                    </p>
                    <p>
                        <strong>Promo Video:</strong> {formData.promoVideoUrl || 'N/A'}
                    </p>
                    <p>
                        <strong>Tags:</strong> {formData.tags || 'N/A'}
                    </p>
                </div>

                <div className="border border-emerald-100 bg-emerald-50 rounded p-3 my-4 text-sm text-gray-700 max-h-40 overflow-y-auto">
                    <strong className="block mb-1">Campaign Description:</strong>
                    {formData.campaignDescription ? (
                        <div
                            className="prose prose-sm max-w-none"
                            dangerouslySetInnerHTML={{ __html: formData.campaignDescription }}
                        />
                    ) : (
                        <p className="italic text-gray-500">No description entered</p>
                    )}
                </div>

                {errors.submitError && (
                    <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-md text-sm">
                        <FaTimesCircle className="inline-block mr-2" />
                        {errors.submitError}
                    </div>
                )}

                <div className="flex justify-between mt-8">
                    <button
                        onClick={handlePrev}
                        className="inline-flex items-center text-gray-500 hover:text-gray-700"
                    >
                        <FaArrowLeft className="mr-2" />
                        Back
                    </button>
                    <button
                        onClick={handleSubmit}
                        disabled={submitting}
                        className="inline-flex items-center px-4 py-2 rounded-md bg-emerald-50 hover:bg-emerald-100 text-emerald-700 border border-emerald-100 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                    >
                        {submitting ? (
                            <>
                                <FaSpinner className="mr-2 animate-spin" />
                                Submitting...
                            </>
                        ) : (
                            <>
                                Submit
                                <FaSave className="ml-2" />
                            </>
                        )}
                    </button>
                </div>
            </Container>
        );
    }

    if (step === 5 && createdCampaign) {
        const campaignUrl = `${window.location.origin}/donation/${createdCampaign.slug}`;
        
    
        const copyLink = () => {
            navigator.clipboard.writeText(campaignUrl)
                .then(() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 3000); // Hide after 3 sec
                })
                .catch(() => console.error("Failed to copy link."));
        };
    
        const shareLink = () => {
            if (navigator.share) {
                navigator.share({
                    title: "Check out my campaign!",
                    text: "Here is my donation campaign link:",
                    url: campaignUrl,
                }).catch((err) => console.error("Share failed:", err));
            } else {
                copyLink();
            }
        };
    
        const finishWizard = () => {
            onSuccess && onSuccess(createdCampaign);
        };
    
        return (
            <div className="max-w-md mx-auto mt-6 bg-white border border-emerald-200 rounded-md p-4 sm:p-6 shadow-xl text-center">
                <h2 className="text-xl sm:text-2xl font-bold text-emerald-700 mb-2 flex items-center justify-center">
                    <span className="text-2xl sm:text-3xl mr-2">🎉</span>
                    Hooray! Your Campaign Is Live!
                    <span className="text-2xl sm:text-3xl ml-2">🎉</span>
                </h2>
                <p className="text-gray-800 text-xs sm:text-sm mt-2 mb-4 leading-relaxed">
                    You did it—your donation campaign has been successfully created.
                    Spread the word to start receiving support from your donors!
                </p>
    
                <div className="bg-emerald-50 border-l-4 border-emerald-400 p-3 sm:p-4 mb-4 text-xs sm:text-sm text-emerald-700 rounded-md shadow-sm">
                    <p className="mb-2 font-semibold">Your Campaign Link:</p>
                    <div className="flex flex-col sm:flex-row items-center bg-white border border-emerald-200 rounded p-2 justify-between">
                        <span className="text-xs sm:text-sm break-all w-full sm:w-auto mb-2 sm:mb-0">{campaignUrl}</span>
                        <div className="flex gap-2">
                            <button
                                onClick={shareLink}
                                className="text-emerald-600 hover:text-emerald-800 p-2"
                                title="Share"
                            >
                                <FaShareSquare size={18} />
                            </button>
                            <button
                                onClick={copyLink}
                                className="text-emerald-600 hover:text-emerald-800 p-2 relative"
                                title="Copy"
                            >
                                <FaCopy size={18} />
                                {copied && (
                                    <span className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded-md">
                                        Link copied!
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
    
                <button
                    onClick={finishWizard}
                    className="inline-flex items-center px-4 py-2 sm:px-5 sm:py-3 mt-4 rounded-md bg-emerald-600 hover:bg-emerald-700 text-white border border-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-400 transition-all duration-150 text-xs sm:text-sm font-semibold"
                >
                    Go to My Campaigns
                </button>
            </div>
        );
    }
    return null;
};

export default DonationCampaignWizard;
