import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from '../../context';
import { useNavigate } from 'react-router-dom';
import { 
  FaSpinner, 
  FaEye, 
  FaCircle,
  FaLink
} from 'react-icons/fa';

const UserDonationLinks = ({ userId }) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [donationLinks, setDonationLinks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchDonationLinks = async () => {
            try {
                const response = await api.get(`/api/admin/user-donation-links/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                setDonationLinks(response.data);
            } catch (error) {
                console.error('Error fetching donation links:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDonationLinks();
    }, [userId, user.token]);

    const getStatusColor = (status) => {
        const colors = {
            active: 'bg-green-100 text-green-800',
            approved: 'bg-blue-100 text-blue-800',
            completed: 'bg-gray-100 text-gray-800',
            inactive: 'bg-yellow-100 text-yellow-800',
            cancelled: 'bg-red-100 text-red-800',
            rejected: 'bg-red-200 text-red-900',
            test: 'bg-purple-100 text-purple-800'
        };
        return colors[status] || 'bg-gray-100 text-gray-800';
    };

    const getStatusIcon = (status) => {
        const colors = {
            active: 'text-green-500',
            approved: 'text-blue-500',
            completed: 'text-gray-500',
            inactive: 'text-yellow-500',
            cancelled: 'text-red-500',
            rejected: 'text-red-700',
            test: 'text-purple-500'
        };
        return <FaCircle className={`inline-block mr-1 text-xs ${colors[status] || 'text-gray-500'}`} />;
    };

    return (
        <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-4 mt-5">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-gray-800 flex items-center">
                    <FaLink className="mr-2 text-emerald-500" />
                    Donation Links
                </h2>
                <span className="text-xs text-gray-500">
                    {donationLinks.length} {donationLinks.length === 1 ? 'link' : 'links'}
                </span>
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center py-8">
                    <FaSpinner className="animate-spin text-2xl text-emerald-500" />
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="w-full min-w-max">
                        <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
                            <tr>
                                <th className="px-4 py-3 text-left">Title</th>
                                <th className="px-4 py-3 text-left">ID</th>
                                <th className="px-4 py-3 text-right">Target</th>
                                <th className="px-4 py-3 text-right">Raised</th>
                                <th className="px-4 py-3 text-center">Status</th>
                                <th className="px-4 py-3 text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {donationLinks.length > 0 ? (
                                donationLinks.map((link) => (
                                    <tr key={link._id} className="hover:bg-gray-50">
                                        <td className="px-4 py-3 text-xs max-w-[150px]">
                                            <div className="truncate" title={link.title}>
                                                {link.title}
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 text-xs text-gray-500">
                                            <div className="truncate max-w-[80px]" title={link.uniqueIdentifier}>
                                                {link.uniqueIdentifier}
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 text-xs text-right font-medium">
                                            {link.targetAmount}
                                        </td>
                                        <td className="px-4 py-3 text-xs text-right">
                                            {link.totalDonations}
                                        </td>
                                        <td className="px-4 py-3 text-center">
                                            <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getStatusColor(link.status)}`}>
                                                {getStatusIcon(link.status)}
                                                {link.status}
                                            </span>
                                        </td>
                                        <td className="px-4 py-3 text-right">
                                            <button
                                                onClick={() => navigate(`/single-donation-link-details/${link._id}`)}
                                                className="inline-flex items-center px-3 py-1 border border-gray-200 rounded-full text-xs text-gray-600 hover:bg-gray-100 hover:text-gray-800 transition-colors"
                                                title="View details"
                                            >
                                                <FaEye className="mr-1" />
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="px-4 py-6 text-center text-xs text-gray-500" colSpan="6">
                                        No donation links found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default UserDonationLinks;