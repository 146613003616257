import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { FaTrashAlt, FaSpinner } from 'react-icons/fa';

const UserConversions = ({ userId }) => {
    const { user } = useUser();
    const [conversions, setConversions] = useState([]);
    const [selectedConversions, setSelectedConversions] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchConversions = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/api/customer/conversions/${userId}`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                if (response.status === 200) {
                    setConversions(response.data);
                } else {
                    toast.error('Failed to load conversion data');
                }
            } catch (error) {
                toast.error(`Failed to load conversion data: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchConversions();
    }, [userId, user.token]);

    const handleCheckboxChange = (conversionId) => {
        setSelectedConversions(prev => 
            prev.includes(conversionId) 
                ? prev.filter(id => id !== conversionId) 
                : [...prev, conversionId]
        );
    };

    const handleDeleteClick = () => {
        if (selectedConversions.length > 0) {
            setShowDeleteModal(true);
        } else {
            toast.error("Please select at least one conversion to delete");
        }
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            const response = await api.delete(`/api/customer/conversions/${userId}`, {
                headers: { Authorization: `Bearer ${user.token}` },
                data: { conversionIds: selectedConversions },
            });
            if (response.status === 200) {
                toast.success(`${selectedConversions.length} conversion(s) deleted`);
                setConversions(conversions.filter(conv => !selectedConversions.includes(conv._id)));
                setSelectedConversions([]);
                setShowDeleteModal(false);
            } else {
                toast.error('Failed to delete conversions');
            }
        } catch (error) {
            toast.error(`Failed to delete conversions: ${error.message}`);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-gray-800">Conversions</h3>
                {selectedConversions.length > 0 && (
                    <button
                        onClick={handleDeleteClick}
                        disabled={isDeleting}
                        className={`bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1.5 rounded-lg text-sm font-medium flex items-center gap-1 ${
                            isDeleting ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        <FaTrashAlt className="text-xs" />
                        <span>Delete ({selectedConversions.length})</span>
                    </button>
                )}
            </div>

            <div className="border border-dashed border-gray-200 rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full min-w-[600px]">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            setSelectedConversions(e.target.checked 
                                                ? conversions.map(conv => conv._id) 
                                                : []);
                                        }}
                                        checked={selectedConversions.length === conversions.length && conversions.length > 0}
                                    />
                                </th>
                                {['Transaction ID', 'From', 'To', 'From Amount', 'To Amount', 'Rate', 'Date'].map((header) => (
                                    <th key={header} className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="8" className="px-3 py-4 text-center">
                                        <FaSpinner className="animate-spin mx-auto text-gray-400" />
                                    </td>
                                </tr>
                            ) : conversions.length > 0 ? (
                                conversions.map((conversion) => (
                                    <tr key={conversion._id} className="border-t border-dashed hover:bg-gray-50">
                                        <td className="px-3 py-2">
                                            <input
                                                type="checkbox"
                                                checked={selectedConversions.includes(conversion._id)}
                                                onChange={() => handleCheckboxChange(conversion._id)}
                                                className="rounded"
                                            />
                                        </td>
                                        <td className="px-3 py-2 text-sm text-gray-700">{conversion.transactionId}</td>
                                        <td className="px-3 py-2 text-sm text-gray-700">{conversion.fromCurrency}</td>
                                        <td className="px-3 py-2 text-sm text-gray-700">{conversion.toCurrency}</td>
                                        <td className="px-3 py-2 text-sm text-gray-700">{conversion.fromAmount}</td>
                                        <td className="px-3 py-2 text-sm text-gray-700">{conversion.toAmount}</td>
                                        <td className="px-3 py-2 text-sm text-gray-700">{conversion.conversionRate}</td>
                                        <td className="px-3 py-2 text-sm text-gray-700">
                                            {new Date(conversion.createdAt).toLocaleString()}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="px-3 py-4 text-center text-sm text-gray-500">
                                        No conversions found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-md border border-dashed border-gray-200 shadow">
                        <div className="flex items-center gap-2 mb-3">
                            <div className="p-2 rounded-lg bg-red-50 text-red-600">
                                <FaTrashAlt />
                            </div>
                            <h3 className="text-lg font-semibold text-gray-800">Confirm Delete</h3>
                        </div>
                        
                        <p className="mb-4 text-sm text-gray-600">
                            Are you sure you want to delete {selectedConversions.length} selected conversion(s)?
                        </p>

                        <div className="border-t border-dashed pt-3">
                            <div className="flex justify-end gap-2">
                                <button
                                    onClick={() => setShowDeleteModal(false)}
                                    disabled={isDeleting}
                                    className="px-3 py-1.5 text-gray-600 hover:bg-gray-50 rounded-lg text-sm font-medium border border-dashed"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDelete}
                                    disabled={isDeleting}
                                    className={`px-3 py-1.5 bg-red-50 text-red-600 hover:bg-red-100 rounded-lg text-sm font-medium flex items-center gap-1 ${
                                        isDeleting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                >
                                    {isDeleting ? (
                                        <>
                                            <FaSpinner className="animate-spin" />
                                            Deleting...
                                        </>
                                    ) : (
                                        <>
                                            <FaTrashAlt />
                                            Confirm
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserConversions;