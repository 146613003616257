import React from 'react';
import { BiDollarCircle } from 'react-icons/bi';
import { AiFillAlipayCircle, AiOutlineGift } from 'react-icons/ai';
import { MdOutlineVolunteerActivism } from 'react-icons/md';
import { useUser } from "./context";

const formatCurrency = (amount, currencyCode, currencySymbol) => {
  const roundedAmount = Math.round(amount);
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyCode || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const parts = formatter.formatToParts(roundedAmount);
  const formattedParts = parts.map(part => (part.type === 'currency' ? (currencySymbol || part.value) + ' ' : part.value));
  return formattedParts.join('');
};

const SummaryCard = ({ title, value, icon, color = 'emerald' }) => (
<div className="relative group overflow-hidden px-6 sm:px-3 py-3 rounded-2xl transition-all duration-300 hover:shadow-xl hover:-translate-y-1.5"
 style={{
      background: `
        linear-gradient(145deg, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0.45) 100%),
        repeating-linear-gradient(
          -60deg,
          rgba(16, 185, 129, 0.08) 0px,
          rgba(16, 185, 129, 0.08) 2px,
          transparent 2px,
          transparent 6px
        )
      `,
      boxShadow: '0 8px 32px rgba(5, 79, 57, 0.12)',
      border: '1px solid rgba(167, 243, 208, 0.3)'
    }}>
    
    {/* Animated Background Pattern */}
    <div 
      className="absolute inset-0 opacity-30 mix-blend-overlay"
      style={{
        backgroundImage: `
          radial-gradient(circle at 50% 50%,
            rgba(16, 185, 129, 0.15) 0%,
            transparent 15%),
          linear-gradient(135deg,
            transparent 49%,
            rgba(220, 252, 231, 0.3) 50%,
            transparent 51%)
        `,
        animation: 'float 12s infinite ease-in-out',
      }}
    />
    
    {/* Content Container */}
    <div className="relative z-10 flex items-center justify-between">
      {/* Text Content */}
      <div className="space-y-1.5">
        <h5 className="text-xs font-semibold tracking-wide uppercase text-emerald-800/90 opacity-80">
          {title}
        </h5>
        <p className="text-lg font-bold text-emerald-900/90">
          {value}
        </p>
      </div>

      {/* Icon Container with Depth Effect */}
      <div className="relative">
        <div className="absolute inset-0 bg-emerald-200/40 blur-md rounded-full animate-pulse-slow" />
        <div className="relative p-2 transition-transform duration-300 bg-gradient-to-br from-emerald-50 to-emerald-100 rounded-full shadow-inner group-hover:rotate-[15deg]">
          {icon}
        </div>
      </div>
    </div>

    {/* Border Glow Effect */}
    <div className="absolute inset-0 pointer-events-none border border-emerald-100/50 rounded-2xl" />
  </div>
);

const SummaryCards = () => {
  const { user } = useUser();

  // Original account balance calculations remain the same
  const usdAccount = user?.accounts?.find(account => account.currency === "USD");
  const usdBalance = usdAccount ? usdAccount.balance : 0;
  const usdSymbol = usdAccount?.currencySymbol || "$";

  const primaryAccount = user?.accounts?.find(account => account.isPrimary && account.currency !== "USD") ||
    user?.accounts?.find(account => account.currency === "GBP");
  const primaryCurrency = primaryAccount ? primaryAccount.currency : 'N/A';
  const primaryBalance = primaryAccount ? primaryAccount.balance : 0;
  const primarySymbol = primaryAccount?.currencySymbol || "$";
  const primaryCurrencyCode = primaryAccount?.currency || 'USD';

  return (
    <div className="relative p-4 space-y-4 rounded-lg md:grid md:grid-cols-4 md:gap-4 md:space-y-0"
      style={{
        backgroundImage: `
          linear-gradient(150deg, #f0fff4 0%, #d1fae5 100%),
          repeating-linear-gradient(
            -45deg,
            transparent,
            transparent 3px,
            rgba(167, 243, 208, 0.2) 3px,
            rgba(167, 243, 208, 0.2) 6px
          )
        `,
        backgroundBlendMode: 'soft-light',
        backdropFilter: 'blur(20px)',
        boxShadow: '0 4px 24px rgba(5, 79, 57, 0.08)',
        border: '1px solid rgba(167, 243, 208, 0.4)'
      }}>
      
      {/* USD Balance Card */}
      <SummaryCard 
        title="USD Balance"
        value={formatCurrency(usdBalance, "USD", usdSymbol)}
        icon={<BiDollarCircle className="text-2xl text-emerald-700/90" />}
      />

      {/* Primary Currency Card */}
      <SummaryCard 
        title={`${primaryCurrency} Balance`}
        value={formatCurrency(primaryBalance, primaryCurrencyCode, primarySymbol)}
        icon={<AiFillAlipayCircle className="text-2xl text-emerald-700/90" />}
      />

      {/* Active Fundraisers Card */}
      <SummaryCard 
        title="Fundraisers"
        value="0"
        icon={<MdOutlineVolunteerActivism className="text-2xl text-emerald-700/90" />}
      />

      {/* Impact Points Card */}
      <SummaryCard 
        title="Impact Points"
        value={user?.points}
        icon={<AiOutlineGift className="text-2xl text-emerald-700/90" />}
      />
    </div>
  );
};

export default SummaryCards;