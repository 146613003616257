import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaTrash,
  FaPlus,
  FaEye,
  FaHeart,
  FaArrowLeft,
  FaUserFriends,
  FaCheck,
  FaTimes,
  FaHourglassHalf,
  FaPlay,
  FaLock,
  FaTrophy,
  FaCopy,
  FaShareSquare
} from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import api from "../../api";
import { useUser } from "../context";
import DeleteConfirmationModal from "./Modals/DeleteConfirmationModal";
import DonationCampaignLikesModal from "./Modals/DonationCampaignLikesModal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FOUNDER_PLACEHOLDER = "https://cdn-icons-png.flaticon.com/512/3990/3990850.png";

// A simple domain for sharing
const FRONTEND_URL = window.location.origin;
// or import from config, e.g. import { FRONTEND_URL } from '../../config';

const MyDonationCampaignList = ({ onBack, onCreateCampaign, onEditCampaign }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countdowns, setCountdowns] = useState({});

  // State for delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // State for the Likes Modal
  const [showLikersModal, setShowLikersModal] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  // State for campaign settings modal
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [settingsCampaign, setSettingsCampaign] = useState(null);

  const [isTogglingNotifications, setIsTogglingNotifications] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [showUrlField, setShowUrlField] = useState(false);


  const replaceWithCloudFrontUrl = (url) => {
    if (!url || typeof url !== "string") return url; // Validate URL
    return url.replace(
      "verdantcharity.s3.amazonaws.com",
      "d30sleee6j32ev.cloudfront.net"
    );
  };


  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      const res = await api.get(`/api/donationcampaigns/user/${user._id}`, config);
      if (res.data.success) {
        const campaignsData = res.data.data;
        // For each campaign, fetch the first 3 likers
        const campaignsWithLikers = await Promise.all(
          campaignsData.map(async (camp) => {
            try {
              const resLikes = await api.get(`/api/donationcampaigns/${camp._id}/likes`, {
                params: { page: 1, limit: 3 },
              });
              if (resLikes.data.success) {
                camp.likers = resLikes.data.data.likers;
                camp.likeCount = resLikes.data.data.totalLikes;
              } else {
                camp.likers = [];
                camp.likeCount = 0;
              }
            } catch (err) {
              console.error("Error fetching likers for campaign:", camp._id, err);
              camp.likers = [];
              camp.likeCount = 0;
            }
            return camp;
          })
        );

        // Set campaigns
        setCampaigns(campaignsWithLikers);

        // Initialize countdowns for each campaign
        const initialCountdowns = {};
        campaignsWithLikers.forEach((camp) => {
          initialCountdowns[camp._id] = getCountdown(camp.startDate);
        });
        setCountdowns(initialCountdowns);
      }
    } catch (err) {
      console.error("Error fetching my donation campaigns:", err);
    } finally {
      setLoading(false);
    }
  };

  const getCountdown = (startDate) => {
    if (!startDate) return "N/A";

    const now = new Date();
    const start = new Date(startDate);
    const diff = start - now;

    if (diff <= 0) return "Starting Soon"; // Campaign is live

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);

    return `${days}d ${hours}h ${minutes}m`;
  };



  useEffect(() => {
    if (campaigns.length === 0) return;

    const updateCountdowns = () => {
      setCountdowns((prev) => {
        const updated = { ...prev };
        campaigns.forEach((camp) => {
          updated[camp._id] = getCountdown(camp.startDate);
        });
        return updated;
      });
    };

    updateCountdowns(); // Run immediately

    const interval = setInterval(updateCountdowns, 60000); // Update every 60 seconds
    return () => clearInterval(interval);
  }, [campaigns]); // Depend on campaigns



  const handleDeleteClick = (campaign) => {
    setCampaignToDelete(campaign);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!campaignToDelete) return;
    setIsDeleting(true);
    try {
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      await api.delete(`/api/donationcampaigns/${campaignToDelete._id}`, config);
      setCampaigns((prev) => prev.filter((c) => c._id !== campaignToDelete._id));
      toast.success("Campaign deleted!");
    } catch (err) {
      console.error("Error deleting campaign:", err);
      toast.error("Failed to delete campaign.");
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
      setCampaignToDelete(null);
    }
  };

  const cancelDelete = () => {
    if (isDeleting) return;
    setShowDeleteModal(false);
    setCampaignToDelete(null);
  };

  const getDaysLeft = (endDate) => {
    if (!endDate) return 0;
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;
    return diff > 0 ? Math.ceil(diff / (1000 * 60 * 60 * 24)) : 0;
  };

  const getProgress = (goal, current) => {
    if (!goal || goal <= 0) return 0;
    const ratio = (current / goal) * 100;
    return ratio > 100 ? 100 : ratio;
  };

  const getStatusBadgeClasses = (status) => {
    switch (status) {
      case "pending_approval":
        return "bg-yellow-100 text-yellow-800";
      case "approved":
        return "bg-blue-100 text-blue-800";
      case "rejected":
        return "bg-red-100 text-red-800";
      case "live":
        return "bg-green-100 text-green-800";
      case "closed":
        return "bg-gray-100 text-gray-800";
      case "successful":
        return "bg-purple-100 text-purple-800";
      case "paused":
        return "bg-orange-100 text-orange-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "pending_approval":
        return <FaHourglassHalf className="mr-1 inline-block" />;
      case "approved":
        return <FaCheck className="mr-1 inline-block" />;
      case "rejected":
        return <FaTimes className="mr-1 inline-block" />;
      case "live":
        return <FaPlay className="mr-1 inline-block" />;
      case "closed":
        return <FaLock className="mr-1 inline-block" />;
      case "successful":
        return <FaTrophy className="mr-1 inline-block" />;
      case "paused":
        return <FaHourglassHalf className="mr-1 inline-block" />;
      default:
        return null;
    }
  };

  // Copy/Share link
  const handleShareLink = () => {
    if (!settingsCampaign) return;
    const link = `${FRONTEND_URL}/donation/${settingsCampaign.slug}`;

    // If Web Share API is available:
    if (navigator.share) {
      navigator
        .share({
          title: "Check out my campaign",
          text: "Support my donation campaign!",
          url: link,
        })
        .then(() => {
          toast.success("Shared successfully!");
        })
        .catch((err) => {
          console.log("Share failed:", err);
        });
    } else {
      // fallback - copy to clipboard
      navigator.clipboard.writeText(link).then(
        () => {
          toast.success("Link copied to clipboard!");
        },
        () => {
          toast.error("Failed to copy link.");
        }
      );
    }
  };

  const handleCopyLink = () => {
    if (!settingsCampaign) return;
    const link = `${FRONTEND_URL}/donation/${settingsCampaign.slug}`;
    navigator.clipboard.writeText(link).then(
      () => {
        toast.success("Link copied to clipboard!");
      },
      () => {
        toast.error("Failed to copy link.");
      }
    );
  };

  const handleViewDetails = (campSlug) => {
    navigate(`/donation/${campSlug}`);
  };

  const openLikersModal = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setShowLikersModal(true);
  };

  // =============== Settings Modal Handlers ===============
  const openSettingsModal = (campaign) => {
    setSettingsCampaign(campaign);
    setShowSettingsModal(true);
  };

  const closeSettingsModal = () => {
    setSettingsCampaign(null);
    setShowSettingsModal(false);
  };

  // Toggle notifications
  const handleToggleNotifications = async () => {
    if (!settingsCampaign) return;
    try {
      setIsTogglingNotifications(true); // Start loading
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      const res = await api.put(
        `/api/donationcampaigns/${settingsCampaign._id}/toggle-notifications`,
        {},
        config
      );
      if (res.data.success) {
        const updated = res.data.data;
        setCampaigns((prev) =>
          prev.map((c) => (c._id === updated._id ? updated : c))
        );
        setSettingsCampaign(updated);
        toast.success(
          updated.notificationsEnabled
            ? "Notifications enabled!"
            : "Notifications disabled!"
        );
      } else {
        toast.error(res.data.message || "Failed to toggle notifications.");
      }
    } catch (err) {
      console.error("Error toggling notifications:", err);
      toast.error("Error toggling notifications.");
    } finally {
      setIsTogglingNotifications(false); // Stop loading (always runs)
    }
  };

  // Pause/Resume
  const handlePauseResume = async () => {
    if (!settingsCampaign) return;
    const isPause = ["live", "approved"].includes(settingsCampaign.status);
    const endpoint = isPause ? "pause" : "resume";

    try {
      setIsUpdatingStatus(true); // Start loading
      const config = { headers: { Authorization: `Bearer ${user.token}` } };
      const res = await api.put(
        `/api/donationcampaigns/${settingsCampaign._id}/${endpoint}`,
        {},
        config
      );
      if (res.data.success) {
        const updated = res.data.data;
        setCampaigns((prev) =>
          prev.map((c) => (c._id === updated._id ? updated : c))
        );
        setSettingsCampaign(updated);
        toast.success(isPause ? "Campaign paused." : "Campaign resumed!");
      } else {
        toast.error(res.data.message || "Failed to update campaign status.");
      }
    } catch (err) {
      console.error("Error pausing/resuming campaign:", err);
      toast.error("Error pausing/resuming campaign.");
    } finally {
      setIsUpdatingStatus(false); // Stop loading (always runs)
    }
  };

  // Edit Campaign
  const handleEditCampaign = () => {
    if (!settingsCampaign) return;
    onEditCampaign(settingsCampaign._id);
    closeSettingsModal();
  };

  // Modified share section with URL preview
  const renderShareSection = () => {
    const link = `${FRONTEND_URL}/donation/${settingsCampaign.slug}`;




    return (
      <div className="mt-4 border-t border-gray-200 pt-4">
        <p className="text-sm font-semibold text-gray-700 mb-3">Share Campaign</p>

        {showUrlField && (
          <div className="mb-3">
            <input
              type="text"
              value={link}
              readOnly
              className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md mb-2"
              onClick={(e) => e.target.select()}
            />
            <p className="text-xs text-gray-500 text-center">
              This is your campaign's public URL
            </p>
          </div>
        )}

        <div className="flex items-center gap-3">
          <button
            onClick={() => {
              setShowUrlField(true);
              handleShareLink();
            }}
            className="flex items-center gap-1 px-3 py-2 text-sm rounded-full bg-blue-500 text-white hover:bg-blue-600 disabled:bg-blue-300"
            disabled={isTogglingNotifications || isUpdatingStatus}
          >
            <FaShareSquare />
            {showUrlField ? 'Shared URL' : 'Share'}
          </button>
          <button
            onClick={() => {
              setShowUrlField(true);
              handleCopyLink();
            }}
            className="flex items-center gap-1 px-3 py-2 text-sm rounded-full bg-gray-200 text-gray-800 hover:bg-gray-300 disabled:bg-gray-100"
            disabled={isTogglingNotifications || isUpdatingStatus}
          >
            <FaCopy />
            {showUrlField ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      </div>
    );
  };
  // =============== End of Settings Modal Handlers ===============

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-emerald-50">
        <svg
          className="animate-spin h-10 w-10 text-emerald-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z" />
        </svg>
        <p className="mt-4 text-lg text-emerald-600">Loading your donation campaigns...</p>
      </div>
    );
  }

  if (campaigns.length === 0) {
    return (
      <div className="max-w-xl mx-auto p-4 text-center">
        <button
          onClick={onBack}
          className="text-sm text-gray-600 hover:text-gray-800 inline-flex items-center mb-4"
        >
          <FaArrowLeft className="mr-1" />
          Back
        </button>
        <div className="bg-white p-6 rounded-md border border-gray-200">
          <h2 className="text-xl font-bold text-gray-800 mb-3">
            You haven't created any donation campaigns yet.
          </h2>
          <p className="text-sm text-gray-600 mb-4">
            Start by creating your very first campaign.
          </p>
          <button
            onClick={onCreateCampaign}
            className="inline-flex items-center gap-2 px-4 py-2 rounded-md border border-gray-200 bg-white text-gray-700 hover:bg-gray-50"
          >
            <FaPlus />
            Create Campaign
          </button>
        </div>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-4">
      <ToastContainer />

      {showDeleteModal && (
        <DeleteConfirmationModal
          message={`Are you sure you want to delete "${campaignToDelete?.campaignTitle || ""}"?`}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          isDeleting={isDeleting}
        />
      )}

      <button
        onClick={onBack}
        className="text-sm text-gray-600 hover:text-gray-800 inline-flex items-center mb-4"
      >
        <FaArrowLeft className="mr-1" />
        Back
      </button>

      <div className="flex justify-between items-center mb-6 flex-wrap gap-y-3">
        <h2 className="text-2xl font-bold text-gray-800">My Donation Campaigns</h2>
        <button
          onClick={onCreateCampaign}
          className="inline-flex items-center gap-2 px-4 py-2 rounded-md border border-gray-200 bg-white text-gray-700 hover:bg-gray-50"
        >
          <FaPlus />
          Create Campaign
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {campaigns.map((camp) => {
          const now = new Date();
          const startDate = new Date(camp.startDate);
          const endDate = new Date(camp.endDate);
          const isUpcoming = now < startDate;
          const isEnded = now > endDate;
          const isActive = !isUpcoming && !isEnded;
          const daysLeft = getDaysLeft(camp.endDate);
          const progress = getProgress(camp.fundingGoal, camp.currentAmount);
          const shortDesc = camp.campaignDescription?.replace(/<[^>]*>?/gm, "") || "No description";
          const tagsArr = camp.tags ? camp.tags.split(",").map((t) => t.trim()) : [];

          return (
            <div
              key={camp._id}
              className="border border-gray-200 rounded-md bg-gradient-to-r from-emerald-50 to-blue-50 relative cursor-pointer shadow-sm hover:shadow-md transition"
              onClick={() => handleViewDetails(camp.slug)}
            >
              {/* Cover Image */}
              {camp.coverImage ? (
                <img
                  src={replaceWithCloudFrontUrl(camp.coverImage)}
                  alt={camp.campaignTitle}
                  className="w-full h-44 object-cover rounded-t-md"
                />
              ) : (
                <div className="w-full h-44 bg-gray-100 flex items-center justify-center text-gray-500 text-sm rounded-t-md">
                  No Image
                </div>
              )}

              {/* Founder info */}
              <div className="absolute top-2 left-2 flex items-center space-x-2 bg-white bg-opacity-90 rounded-full px-2 py-1 shadow-sm">
                <img
                  src={FOUNDER_PLACEHOLDER}
                  alt="Founder Avatar"
                  className="w-6 h-6 rounded-full object-cover"
                />
                <span className="text-xs text-gray-600">
                  {user?.primaryInfo?.firstName || "user"}
                </span>
              </div>

              {/* Days Left Pill */}
              <div className="absolute top-2 right-2">
                {isUpcoming ? (
                  <div className="px-3 py-1 rounded-full text-xs font-bold bg-yellow-100 text-yellow-700 shadow">
                    Upcoming
                  </div>
                ) : isEnded ? (
                  <div className="px-3 py-1 rounded-full text-xs font-bold bg-red-100 text-red-600 shadow">
                    Ended
                  </div>
                ) : (
                  <div className="h-10 w-10 flex items-center justify-center rounded-full text-xs font-bold bg-emerald-100 text-emerald-700 shadow">
                    {daysLeft}d
                  </div>
                )}
              </div>

              {/* Card Body */}
              <div className="p-4 space-y-2">
                {/* Title */}
                <h3 className="text-sm sm:text-base font-semibold text-gray-800 line-clamp-2">
                  {camp.campaignTitle}
                </h3>

                {/* Status Pill */}
                {camp.status && (
                  <div>
                    <span
                      className={`inline-block text-xs px-2 py-1 rounded-full font-medium ${getStatusBadgeClasses(
                        camp.status
                      )}`}
                    >
                      {getStatusIcon(camp.status)}
                      {camp.status.replace(/_/g, " ")}
                    </span>
                  </div>
                )}

                <p className="text-xs text-gray-600 line-clamp-2">{shortDesc}</p>

                {tagsArr.length > 0 && (
                  <div className="flex flex-wrap gap-1 mt-1">
                    {tagsArr.map((tag, i) => (
                      <span
                        key={i}
                        className="text-xs bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                )}

                {/* Funding progress */}
                <div className="mt-3">
                  <div className="flex justify-between text-xs text-gray-500 mb-1">
                    <span>Raised: ${camp.currentAmount || 0}</span>
                    <span>Goal: ${camp.fundingGoal}</span>
                  </div>
                  <div className="w-full h-2 bg-gray-200 rounded">
                    <div
                      className="h-full bg-emerald-500 rounded"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>

                <div className="flex justify-between items-center mt-3 text-xs text-gray-400">
                  <div className="flex items-center space-x-2">
                    <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        openLikersModal(camp._id);
                      }}
                    >
                      <FaHeart className="text-red-500" />
                      <span>{camp.likeCount || 0}</span>
                    </div>
                    <div className="flex items-center space-x-1">
                      <FaEye />
                      <span>{camp.views || 0}</span>
                    </div>
                  </div>
                  {isUpcoming ? (
                    <span className="text-yellow-600 font-semibold">
                      Starts in {countdowns[camp._id] ?? "Loading..."}
                    </span>
                  ) : isActive ? (
                    <span className="text-emerald-600 font-semibold">
                      {daysLeft} days left
                    </span>
                  ) : null}
                </div>
                {/* Show some user avatars (likers) */}
                {camp.likeCount > 0 && (
                  <div className="mt-2 bg-white p-2 rounded-lg">
                    <div className="flex -space-x-2 overflow-hidden">
                      {camp.likers &&
                        camp.likers.slice(0, 3).map((liker) => (
                          <img
                            key={liker._id}
                            src={replaceWithCloudFrontUrl(liker.fakeUser.profilePicture)}
                            alt={`${liker.fakeUser.firstName} ${liker.fakeUser.lastName}`}
                            className="inline-block h-6 w-6 rounded-full ring-2 ring-white object-cover"
                          />
                        ))}

                      {camp.likeCount > 3 && (
                        <span className="inline-block h-6 w-6 rounded-full ring-2 ring-white bg-gray-200 text-gray-600 flex items-center justify-center text-xs">
                          +{camp.likeCount - 3}
                        </span>
                      )}
                    </div>
                    <div className="mt-2 flex items-center">
                      <FaUserFriends className="text-emerald-600 mr-1" />
                      <span className="text-xs text-gray-600">
                        Liked by{" "}
                        {camp.likers &&
                          camp.likers.slice(0, 2).map((liker) => liker.fakeUser.firstName).join(", ")}
                        {camp.likeCount > 2 ? " and others" : ""}
                      </span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openLikersModal(camp._id);
                        }}
                        className="ml-auto text-xs text-blue-500 hover:underline flex items-center"
                      >
                        See All <AiOutlineArrowRight className="ml-1" />
                      </button>
                    </div>
                  </div>
                )}

                {/* Settings / Delete Buttons */}
                <div className="flex justify-between items-center pt-3 border-t border-gray-100">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      openSettingsModal(camp);
                    }}
                    className="text-sm text-indigo-600 hover:text-indigo-800 flex items-center font-semibold"
                  >
                    <svg
                      className="h-4 w-4 mr-1"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 3a1.75 1.75 0 011.75-1.75h1a1.75 1.75 0 011.75 1.75v.98a7.36 7.36 0 014.24 4.24h.98a1.75 1.75 0 011.75 1.75v1a1.75 1.75 0 01-1.75 1.75h-.98a7.36 7.36 0 01-4.24 4.24v.98a1.75 1.75 0 01-1.75 1.75h-1A1.75 1.75 0 019.75 18.2v-.98a7.36 7.36 0 01-4.24-4.24h-.98A1.75 1.75 0 012.8 11.25v-1A1.75 1.75 0 014.55 8.5h.98A7.36 7.36 0 019.75 4.25V3z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 12a3.75 3.75 0 007.5 0 3.75 3.75 0 00-7.5 0z"
                      />
                    </svg>
                    Settings
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(camp);
                    }}
                    className="text-sm text-red-500 hover:text-red-700 flex items-center"
                  >
                    <FaTrash className="mr-1" />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Likers Modal */}
      <DonationCampaignLikesModal
        show={showLikersModal}
        campaignId={selectedCampaignId}
        onClose={() => {
          setShowLikersModal(false);
          setSelectedCampaignId(null);
        }}
      />

      {/* Settings Modal */}
      {showSettingsModal && settingsCampaign && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="relative w-full max-w-md mx-auto rounded-xl bg-white shadow-lg overflow-hidden">
            {/* Header */}
            <div className="border-b border-gray-200 p-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold text-gray-800"> Settings</h2>
              <button
                onClick={closeSettingsModal}
                className="p-2 text-gray-600 hover:text-gray-800 rounded-full transition-all duration-200 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                aria-label="Close modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-6">
              {/* Notifications Toggle with Explanation */}
              <div className="mb-6">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm font-medium text-gray-700">
                    Notifications
                    <span className="ml-2 text-blue-500 cursor-help" title="You'll still receive major event notifications">
                      ⓘ
                    </span>
                  </span>
                  <button
                    onClick={handleToggleNotifications}
                    className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none disabled:opacity-50"
                    style={{
                      backgroundColor: settingsCampaign.notificationsEnabled ? "#10b981" : "#d1d5db",
                    }}
                    disabled={isTogglingNotifications}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform bg-white rounded-full transition ${settingsCampaign.notificationsEnabled ? "translate-x-6" : "translate-x-1"
                        }`}
                    />
                  </button>
                </div>
                <p className="text-xs text-gray-500">
                  When disabled, you won’t receive notifications about donations made to this campaign.
                </p>
              </div>

              {/* Pause/Resume with Explanation */}
              {(settingsCampaign.status === "live" ||
                settingsCampaign.status === "approved" ||
                settingsCampaign.status === "paused") && (
                  <div className="mb-6">
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm font-medium text-gray-800">
                        {["live", "approved"].includes(settingsCampaign.status)
                          ? "Pause Campaign"
                          : "Resume Campaign"}
                        <span className="ml-2 text-blue-600 cursor-help" title="Paused campaigns are hidden from public view">
                          ⓘ
                        </span>
                      </span>
                      <button
                        onClick={handlePauseResume}
                        className={`px-4 py-1 text-sm rounded-full text-white ${["live", "approved"].includes(settingsCampaign.status)
                          ? "bg-red-500 hover:bg-red-600 disabled:bg-red-300"
                          : "bg-green-500 hover:bg-green-600 disabled:bg-green-300"
                          }`}
                        disabled={isUpdatingStatus}
                      >
                        {isUpdatingStatus ? "Processing..." :
                          ["live", "approved"].includes(settingsCampaign.status) ? "Pause" : "Resume"}
                      </button>
                    </div>
                    <p className="text-xs text-gray-600">
                      {["live", "approved"].includes(settingsCampaign.status)
                        ? "Pausing will hide your campaign and stop donations."
                        : "Resuming will make your campaign public again."}
                    </p>
                  </div>
                )}

              {/* Edit Campaign */}
              <div className="mb-6">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-gray-700">Edit Campaign Details</span>
                  <button
                    onClick={handleEditCampaign}
                    className="px-4 py-1 text-sm rounded-full text-white bg-indigo-500 hover:bg-indigo-600"
                  >
                    Edit
                  </button>
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  Update title, description, goals, and other campaign details
                </p>
              </div>

              {/* Share Section */}
              {renderShareSection()}
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default MyDonationCampaignList;
