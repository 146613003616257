// File: PublicCampaignDetails.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FaArrowLeft,
  FaHeart,
  FaEye,
  FaGlobe,
  FaShareAlt,
  FaBookmark,
  FaRegBookmark,
  FaUserFriends,
  FaVideo,
  FaExternalLinkAlt,
  FaCamera,
  FaPlus,
  FaTrash
} from 'react-icons/fa';
import { useUser } from '../context';
import api from '../../api';
import CrowdfundPaymentModal from './Modals/CrowdfundPaymentModal';
import CrowdfundLikesModal from './Modals/CrowdfundLikesModal';

// Assume these modal components exist (similar to your BusinessDetails implementation)
import UploadSingleImageModal from './Modals/UploadSingleImageModal';
import UploadGalleryModal from './Modals/UploadGalleryModal';

const PLACEHOLDER_LOGO = 'https://via.placeholder.com/60x60.png?text=Logo';

const PublicCampaignDetails = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { user } = useUser();

  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [likeCount, setLikeCount] = useState(0);
  const [likers, setLikers] = useState([]);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  // Payment modal state
  const [showModal, setShowModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [amount, setAmount] = useState('');
  const [transactionComplete, setTransactionComplete] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // Likers modal state
  const [showLikersModal, setShowLikersModal] = useState(false);

  // Owner-only image editing modals
  const [showCampaignCoverModal, setShowCampaignCoverModal] = useState(false);
  const [showCampaignGalleryModal, setShowCampaignGalleryModal] = useState(false);
  const [isUploadingGallery, setIsUploadingGallery] = useState(false);

  // Determine if the current user is the owner of the campaign
  const isOwner =
    user &&
    campaign &&
    campaign.owner &&
    ((campaign.owner._id && campaign.owner._id.toString() === user._id) ||
      (typeof campaign.owner === 'string' && campaign.owner === user._id));

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch(err => console.error('Error copying URL:', err));
  };

  const handleBookmark = () => {
    setIsBookmarked(prev => !prev);
  };

  // Fetch campaign details by slug
  useEffect(() => {
    const fetchCampaign = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/api/crowdfund/campaigns/slug/${slug}`);
        if (res.data.success) {
          setCampaign(res.data.data);
        }
      } catch (error) {
        console.error('Error fetching campaign details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCampaign();
  }, [slug]);

  // Fetch likers if campaign is loaded
  useEffect(() => {
    if (campaign && campaign._id) {
      const fetchLikers = async () => {
        try {
          const resLikes = await api.get(
            `/api/crowdfund/campaigns/${campaign._id}/likes`,
            { params: { page: 1, limit: 3 } }
          );
          if (resLikes.data.success) {
            setLikers(resLikes.data.data.likers);
            setLikeCount(resLikes.data.data.totalLikes);
          }
        } catch (err) {
          console.error("Error fetching likers:", err);
        }
      };
      fetchLikers();
    }
  }, [campaign]);

  const description = campaign?.campaignDescription?.trim() || "";
  const maxLength = 800;
  const toggleReadMore = () => setIsExpanded(!isExpanded);
  const getDaysLeft = (endDate) => {
    if (!endDate) return 0;
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;
    return diff > 0 ? Math.ceil(diff / (1000 * 60 * 60 * 24)) : 0;
  };
  const getProgress = (goal, current) => {
    if (!goal || goal <= 0) return 0;
    const ratio = (current / goal) * 100;
    return ratio > 100 ? 100 : ratio;
  };

  const daysLeft = campaign ? getDaysLeft(campaign.endDate) : 0;
  const progress = campaign ? getProgress(campaign.fundingGoal, campaign.currentAmount) : 0;
  const tagsArr = campaign?.tags ? campaign.tags.split(',').map(t => t.trim()) : [];

  const openModal = () => {
    setShowModal(true);
    setSelectedPaymentMethod(null);
    setAmount('');
    setTransactionComplete(false);
  };

  const closeModal = () => setShowModal(false);

  const handlePaymentSubmit = () => {
    if (!selectedPaymentMethod || !amount) {
      alert('Please select a payment method and amount.');
      return;
    }
    setTimeout(() => setTransactionComplete(true), 1000);
  };

  // --- New functions for owner image editing ---

  // Upload new cover image for the campaign
  const uploadCampaignCover = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      };
      // Endpoint: ?type=cover tells the server to update the campaign cover
      const res = await api.post(
        `/api/crowdfund/campaigns/${campaign._id}/upload-single-image?type=cover`,
        formData,
        config
      );
      if (res.data.success) {
        // Update campaign cover image URL
        setCampaign((prev) => ({ ...prev, coverImage: res.data.url }));
        setShowCampaignCoverModal(false);
      }
    } catch (err) {
      console.error('Error uploading campaign cover image:', err);
    }
  };

  // Upload new gallery images for the campaign
  const uploadCampaignGalleryImages = async (files) => {
    setIsUploadingGallery(true);
    const formData = new FormData();
    Array.from(files).forEach((f) => formData.append('galleryImages', f));
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await api.post(
        `/api/crowdfund/campaigns/${campaign._id}/upload-gallery-images`,
        formData,
        config
      );
      if (res.data.success) {
        // Append new gallery images to existing ones
        setCampaign((prev) => ({
          ...prev,
          images: [...(prev.images || []), ...res.data.urls],
        }));
        setShowCampaignGalleryModal(false);
      }
    } catch (err) {
      console.error('Error uploading campaign gallery images:', err);
    } finally {
      setIsUploadingGallery(false);
    }
  };

  // --- End new owner functions ---

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-emerald-50">
        <svg
          className="animate-spin h-10 w-10 text-emerald-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
        </svg>
        <p className="mt-4 text-lg text-emerald-600">Loading campaign details...</p>
      </div>
    );
  }

  if (!campaign) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-center bg-emerald-50">
        <svg
          className="w-16 h-16 text-emerald-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13l3 3L22 4M2 12a10 10 0 0110-10h1a10 10 0 010 20h-1a10 10 0 01-10-10z" />
        </svg>
        <h2 className="mt-4 text-2xl font-bold text-emerald-700">Campaign Not Found</h2>
        <p className="mt-2 text-emerald-600">We couldn't find the campaign you were looking for.</p>
        <button
          onClick={() => navigate('/crowdfund')}
          className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700"
        >
          <FaArrowLeft className="mr-2" />
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-r from-emerald-50 to-white">
      {/* Owner-only modals for editing cover and gallery */}
      {showCampaignCoverModal && (
        <UploadSingleImageModal
          title="Upload New Cover Image"
          onUpload={uploadCampaignCover}
          onClose={() => setShowCampaignCoverModal(false)}
        />
      )}
      {showCampaignGalleryModal && (
        <UploadGalleryModal
          title="Upload Gallery Images"
          onUpload={uploadCampaignGalleryImages}
          onClose={() => setShowCampaignGalleryModal(false)}
          isUploading={isUploadingGallery}
        />
      )}

      <div className="max-w-6xl mx-auto px-4 py-6">
        {/* Header */}
        <div className="flex flex-wrap items-center justify-between mb-4">
          <button onClick={() => navigate('/crowdfund')} className="text-sm text-gray-600 hover:text-gray-800 inline-flex items-center mb-2 sm:mb-0">
            <FaArrowLeft className="mr-1" />
            Back
          </button>
          <div className="flex items-center space-x-4">
            <button onClick={handleShare} className="text-sm text-blue-600 hover:underline flex items-center gap-1 transition">
              <FaShareAlt /> Share
            </button>
            <button onClick={handleBookmark} className="text-sm text-blue-600 hover:underline flex items-center gap-1 transition">
              {isBookmarked ? <FaBookmark /> : <FaRegBookmark />} Bookmark
            </button>
            {copySuccess && <span className="text-xs text-green-600">Link copied!</span>}
          </div>
        </div>

        {/* Two-column layout */}
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Right column: Funding & Business Info */}
          <div className="w-full lg:w-96 lg:flex-shrink-0 space-y-4 order-1 lg:order-2">
            {/* Business Profile Card */}
            <div className="bg-gradient-to-r from-green-100 to-green-200 p-4 rounded-md shadow">
              <div className="flex items-center gap-3">
                <img src={campaign.businessProfile?.companyLogo || PLACEHOLDER_LOGO} alt="Business Logo" className="w-12 h-12 rounded-full object-cover" />
                <div>
                  <h3 className="text-sm font-bold text-gray-800">{campaign.businessProfile?.businessName || 'Business Name'}</h3>
                  <p className="text-xs text-gray-700">{campaign.businessProfile?.businessIndustry || 'Industry not specified'}</p>
                </div>
              </div>
              {campaign.businessProfile?.website && (
                <div className="mt-2">
                  <a href={campaign.businessProfile.website} target="_blank" rel="noopener noreferrer" className="text-xs text-blue-600 underline">
                    Visit Website
                  </a>
                </div>
              )}
              {campaign.businessProfile?.socialMediaLinks && campaign.businessProfile.socialMediaLinks.length > 0 && (
                <div className="mt-2 flex space-x-2">
                  {campaign.businessProfile.socialMediaLinks.map((link, index) => (
                    <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="text-xs text-blue-600 underline">
                      {link.platform}
                    </a>
                  ))}
                </div>
              )}
            </div>

            {/* Funding Progress & CTA */}
            <div className="bg-white border border-gray-100 rounded-xl p-6 hover:shadow-xl transition-shadow">
              <div className="space-y-4 mb-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <svg className="w-5 h-5 text-emerald-600" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
                    </svg>
                    <span className="text-gray-500 font-medium">Raised</span>
                  </div>
                  <span className="font-bold text-lg text-gray-800">
                    ${campaign.currentAmount?.toLocaleString() || 0}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <svg className="w-5 h-5 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                    </svg>
                    <span className="text-gray-500 font-medium">Goal</span>
                  </div>
                  <span className="font-bold text-lg text-gray-800">
                    ${campaign.fundingGoal?.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="relative pt-4">
                <div className="flex mb-2 items-center justify-between">
                  <div className="flex-grow">
                    <div className="h-3 bg-gray-100 rounded-full overflow-hidden">
                      <div className="h-full bg-gradient-to-r from-emerald-400 to-emerald-600 rounded-full transition-all duration-500 ease-out" style={{ width: `${progress}%` }}>
                        <div className="h-full w-4 bg-white opacity-20 float-right animate-pulse"></div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-4">
                    <span className="font-semibold text-emerald-700">{Math.round(progress)}%</span>
                  </div>
                </div>
              </div>
              <div className="mt-6 space-y-4">
                <div className="flex items-center justify-center space-x-2 bg-amber-50 p-3 rounded-lg">
                  <svg className="w-5 h-5 text-amber-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <p className="text-sm font-medium text-amber-700">
                    {daysLeft > 0 ? `${daysLeft} days left to reach our goal!` : 'Campaign ended - Thanks for your support!'}
                  </p>
                </div>
                {daysLeft > 0 ? (
                  <button
                    onClick={openModal}
                    className="w-full py-3.5 font-bold text-white bg-gradient-to-r from-emerald-500 to-emerald-600 rounded-xl hover:shadow-lg transform transition-all duration-200 hover:-translate-y-0.5 hover:scale-[1.01]"
                  >
                    {campaign.campaignType === 'equity-based' ? 'Invest Now ➔' : 'Contribute Now ➔'}
                  </button>
                ) : (
                  <div className="flex items-center justify-center space-x-2 bg-red-50 p-3 rounded-lg">
                    <svg className="w-5 h-5 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <span className="text-sm font-medium text-red-700">Funding Closed</span>
                  </div>
                )}
              </div>
            </div>

            {/* Media & Links */}
            <div className="bg-white border border-gray-200 rounded-md p-4 space-y-3 shadow">
              <h3 className="text-sm font-semibold text-gray-800 flex items-center gap-2">
                <FaVideo /> Media &amp; Links
              </h3>
              {campaign.pitchDeckUrl ? (
                <a
                  href={campaign.pitchDeckUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-blue-600 underline flex items-center gap-1"
                >
                  View Pitch Deck <FaExternalLinkAlt size={12} />
                </a>
              ) : (
                <p className="text-xs text-gray-500">No pitch deck provided.</p>
              )}
              {campaign.promoVideoUrl ? (
                <a
                  href={campaign.promoVideoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-blue-600 underline flex items-center gap-1"
                >
                  Watch Promo Video <FaExternalLinkAlt size={12} />
                </a>
              ) : (
                <p className="text-xs text-gray-500">No promo video provided.</p>
              )}
            </div>
          </div>

          {/* Left column: Campaign Details */}
          <div className="flex-1 order-2 lg:order-1">
            {/* Cover Image with optional edit button if owner */}
            <div className="relative mb-4">
              {campaign.coverImage ? (
                <img
                  src={campaign.coverImage}
                  alt={campaign.campaignTitle}
                  className="w-full h-60 object-cover rounded-md"
                />
              ) : (
                <div className="w-full h-60 bg-gray-200 rounded-md flex items-center justify-center text-gray-500">
                  No Cover Image
                </div>
              )}
              {isOwner && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowCampaignCoverModal(true);
                  }}
                  className="absolute top-2 right-2 bg-white bg-opacity-70 p-1 rounded text-xs text-gray-700 hover:bg-opacity-90 flex items-center"
                >
                  <FaCamera className="inline-block mr-1" />
                  Edit Cover
                </button>
              )}
            </div>

            {/* Campaign Title */}
            <h1 className="text-2xl font-extrabold text-gray-800 mb-2">
              {campaign.campaignTitle}
            </h1>
            {/* Campaign Info (Likes, Views, Days Left) */}
            <div className="flex flex-wrap items-center space-x-4 text-sm text-gray-400 mb-2">
              <div className="flex items-center gap-1 cursor-pointer" onClick={() => setShowLikersModal(true)}>
                <FaHeart className="text-red-500" />
                <span>{likeCount}</span>
              </div>
              <div className="flex items-center gap-1">
                <FaEye />
                <span>{campaign?.views}</span>
              </div>
              <div className="flex items-center gap-1">
                <FaGlobe />
                <span>{daysLeft <= 0 ? 'Ended' : `${daysLeft}d left`}</span>
              </div>
            </div>

            {/* Likers Section */}
            {likeCount > 0 && (
              <div className="mt-2 mb-4 bg-white p-2 rounded-lg">
                <div className="flex -space-x-2 overflow-hidden">
                  {likers &&
                    likers.slice(0, 3).map((liker) => (
                      <img
                        key={liker._id}
                        src={liker.fakeUser.profilePicture}
                        alt={`${liker.fakeUser.firstName} ${liker.fakeUser.lastName}`}
                        className="inline-block h-6 w-6 rounded-full ring-2 ring-white object-cover"
                      />
                    ))}
                  {likeCount > 3 && (
                    <span className="inline-block h-6 w-6 rounded-full ring-2 ring-white bg-gray-200 text-gray-600 flex items-center justify-center text-xs">
                      +{likeCount - 3}
                    </span>
                  )}
                </div>
                <div className="mt-2 flex items-center">
                  <FaUserFriends className="text-emerald-600 mr-1" />
                  <span className="text-xs text-gray-600">
                    Liked by{' '}
                    {likers && likers.slice(0, 2).map((liker) => liker.fakeUser.firstName).join(", ")}
                    {likeCount > 2 ? ' and others' : ''}
                  </span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowLikersModal(true);
                    }}
                    className="ml-auto text-xs text-blue-500 hover:underline flex items-center"
                  >
                    See All <FaArrowLeft className="ml-1" />
                  </button>
                </div>
              </div>
            )}

            {/* Description Section */}
            <div className="bg-white border border-gray-200 rounded-md p-4 text-sm text-gray-700 mb-4">
              <h2 className="text-lg font-semibold text-gray-800 mb-2">Description</h2>
              {description ? (
                <>
                  <div
                    className="max-w-none text-gray-800"
                    dangerouslySetInnerHTML={{
                      __html: isExpanded
                        ? description
                        : `${description.substring(0, maxLength)}${description.length > maxLength ? "..." : ""}`,
                    }}
                  />
                  {description.length > maxLength && (
                    <button
                      onClick={toggleReadMore}
                      className="text-blue-600 hover:underline mt-2 block"
                    >
                      {isExpanded ? "Read Less" : "Read More"}
                    </button>
                  )}
                </>
              ) : (
                <p className="italic text-gray-500">No detailed description provided.</p>
              )}
            </div>

            {/* Gallery Section - always display header with Add Images button */}
            <div className="bg-white border border-gray-200 rounded-md p-4 mb-4">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-sm font-semibold text-gray-800">Gallery</h2>
                {isOwner && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowCampaignGalleryModal(true);
                    }}
                    disabled={isUploadingGallery}
                    className="text-xs text-blue-600 hover:underline inline-flex items-center"
                  >
                    {isUploadingGallery ? (
                      <span className="animate-pulse">Uploading...</span>
                    ) : (
                      <>
                        <FaPlus className="mr-1" />
                        Add Images
                      </>
                    )}
                  </button>
                )}
              </div>
              {campaign.images && campaign.images.length > 0 ? (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                  {campaign.images.map((imgUrl, idx) => (
                    <div key={idx} className="relative group">
                      <img
                        src={imgUrl}
                        alt={`gallery-${idx}`}
                        className="w-full h-32 object-cover rounded shadow"
                      />
                      {isOwner && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            // Delete gallery image API call
                            api.delete(`/api/crowdfund/campaigns/${campaign._id}/gallery-image`, {
                              data: { imageUrl: imgUrl },
                              headers: { Authorization: `Bearer ${user.token}` }
                            })
                              .then((res) => {
                                if (res.data.success) {
                                  setCampaign((prev) => ({
                                    ...prev,
                                    images: prev.images.filter((i) => i !== imgUrl),
                                  }));
                                }
                              })
                              .catch(err => console.error('Error deleting gallery image:', err));
                          }}
                          className="absolute top-1 right-1 bg-black bg-opacity-50 text-white p-1 rounded hidden group-hover:block"
                          title="Delete image"
                        >
                          <FaTrash size={12} />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 text-sm">No gallery images yet.</p>
              )}
            </div>
          </div>
        </div>

        {/* Payment Modal */}
        <CrowdfundPaymentModal
          show={showModal}
          onClose={closeModal}
          campaign={campaign}
          transactionComplete={transactionComplete}
          amount={amount}
          setAmount={setAmount}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          handlePaymentSubmit={handlePaymentSubmit}
        />

        {/* Likers Modal */}
        <CrowdfundLikesModal
          campaignId={campaign._id}
          show={showLikersModal}
          onClose={() => setShowLikersModal(false)}
        />
      </div>
    </div>
  );
};

export default PublicCampaignDetails;
