// Description: This component displays a modal with an anti-fraud disclaimer for users. It includes a checkbox for users to confirm they have read the disclaimer and a button to submit their confirmation. The modal also handles loading states and error messages.
// This component is part of a larger application that manages user accounts and transactions, and it is designed to help prevent fraud by educating users about potential scams.
import React, { useState } from 'react';
import api from '../api';

const AntiFraudDisclaimerModal = ({ user, onClose, refreshUser }) => {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const firstName = user?.primaryInfo?.firstName || '';

  const handleConfirm = async () => {
    if (!checked) {
      setError('Please check the box indicating you have read and understand the guidelines.');
      return;
    }

    setLoading(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await api.patch('/api/auth/confirm-antifraud', {}, config);

      refreshUser();
      onClose();
    } catch (err) {
      console.error('Error confirming disclaimer:', err);
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="relative bg-white w-full max-w-md p-5 rounded-md shadow-md">
        <h2 className="text-lg font-bold text-center text-red-600 mb-3">
          Important Anti-Fraud Disclaimer
        </h2>

        <div className="text-sm text-gray-700 mb-4 overflow-y-auto max-h-60 leading-relaxed">
          {firstName && (
            <p className="mb-2">
              Hello, <span className="font-medium">{firstName}</span>!
            </p>
          )}
          <p className="mb-2">
            We have seen a <strong>rise in fraudulent activities</strong> where scammers
            reach out through platforms like WhatsApp, Telegram, or unverified calls
            claiming to fix <strong>“account limitations”</strong> or offering
            “secret deals.” These are not official Verdant Charity communications.
          </p>
          <ul className="list-disc ml-5 mb-2">
          <li className="mb-1">
              <strong>There is no such thing as “Invitation” in Verdant Charity.</strong>
              Any claims involving invitations or exclusive access are fraudulent.
            </li>
            <li className="mb-1">
              If someone claims to be from Verdant Charity and asks for payment
              or your private info outside our official app/website,
              it is almost certainly a <span className="text-red-500 font-bold">scam</span>.
            </li>
            <li className="mb-1">
              We <strong>do not</strong> use random WhatsApp/Telegram numbers for support
              or for resolving account issues.
            </li>
            <li className="mb-1">
              <strong>Never</strong> share your password, OTP, or private details with anyone.
            </li>
          
            <li className="mb-1">
              Only trust our <strong>official phone</strong>
              <em> (+1 385 233 9891)</em>, <strong>email</strong> <em>(support@verdantcharity.org)</em>,
              or the <strong>in-app live chat</strong> for help.
            </li>
          </ul>
          <p className="mb-2">
            We discourage all communication about account limitations or
            verifications outside our official channels, because scammers
            typically prey on urgent requests.
          </p>
          <p className="mb-2">
            By continuing, <strong>you acknowledge</strong> that you will be
            vigilant, <strong>avoid</strong> direct dealings with unverified
            parties, and <strong>contact us</strong> at our official phone,
            email, or live chat for any concerns.
          </p>
        </div>

        <label className="flex items-start text-sm text-gray-800 mb-3">
          <input
            type="checkbox"
            className="h-4 w-4 mt-1 mr-2 text-emerald-600"
            checked={checked}
            onChange={() => {
              setChecked(!checked);
              setError('');
            }}
          />
          <span>
            I have read and understand these guidelines, and will only
            communicate through official Verdant Charity channels.
          </span>
        </label>

        {error && (
          <div className="text-red-600 text-xs mb-2">
            {error}
          </div>
        )}

        <button
          onClick={handleConfirm}
          disabled={loading}
          className={`w-full text-sm py-2 px-4 rounded transition ${
            loading
              ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
              : 'bg-emerald-500 hover:bg-emerald-600 text-white'
          }`}
        >
          {loading ? (
            <span className="flex items-center justify-center">
              <i className="fas fa-spinner fa-spin mr-2"></i> Submitting...
            </span>
          ) : (
            'I Understand'
          )}
        </button>
      </div>
    </div>
  );
};

export default AntiFraudDisclaimerModal;
