// WalletSidebar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../api';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../context';
import ReviewModal from './ReviewModal';
import { FiSend } from "react-icons/fi";
import {
  AiOutlineSwap,
  AiOutlineHistory,
  AiOutlineDollarCircle,
  AiOutlineShoppingCart,
  AiOutlineQuestionCircle,
  AiOutlineHome,
  AiOutlineStar,
  AiFillAlipayCircle,
} from 'react-icons/ai';

const WalletSidebar = ({ changeComponent }) => {
  const { user, logout } = useUser();
  const navigate = useNavigate();

  const [activeComponent, setActiveComponent] = useState('donationsSummary');
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [isLoadingReviews, setIsLoadingReviews] = useState(false);

  // 1. Helper for scrolling to bottom
  const scrollToBottom = () => {
    window.scrollTo({
      top: 9999999, // big number to ensure it reaches bottom
      behavior: 'smooth',
    });
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
    // If you want to scroll when logging out, uncomment:
    // scrollToBottom();
  };

  // 2. Whenever we click a sidebar item -> setActive, changeComponent, scroll
  const handleClick = (componentName) => {
    setActiveComponent(componentName);
    changeComponent(componentName);
    scrollToBottom();
  };

  // 3. Same logic if we want to open the modal & fetch reviews
  const handleReviewsClick = () => {
    setShowReviewModal(true);
    // If you also want to scroll after opening reviews:
    // scrollToBottom();
  };

  // Only fetch reviews if the review modal is open
  useEffect(() => {
    if (!showReviewModal) return;
    const fetchReviews = async () => {
      setIsLoadingReviews(true);
      try {
        const headers = {};
        if (user?.token) {
          headers['Authorization'] = `Bearer ${user.token}`;
        }
        const response = await api.get('/api/reviews', { headers });
        if (response.status === 200) {
          setReviews(response.data);
        } else {
          throw new Error(response.statusText || "Unknown Error");
        }
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
        toast.error(`Failed to fetch reviews: ${error.message || "Unknown error"}`);
      } finally {
        setIsLoadingReviews(false);
      }
    };
    fetchReviews();
  }, [showReviewModal, user]);

  return (
    <div
      className="bg-white p-4 rounded-lg shadow-xl mx-4 lg:mx-8 lg:h-full lg:w-64 mt-4 md:mt-8 lg:mt-16"
      style={{ backgroundImage: 'url(/wwwhirl.svg)', backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <nav className="flex flex-col space-y-3">
        
        {/* Home / Dashboard link */}
        {/* Using Link here, but intercepting the click so we can scroll */}
        <Link
          to="/dashboard"
          onClick={(e) => {
            e.preventDefault();
            handleClick('donationsSummary');
            navigate('/dashboard');
          }}
          className={`flex items-center space-x-3 cursor-pointer ${
            activeComponent === 'donationsSummary'
              ? 'p-2 rounded-md text-emerald-700 bg-emerald-100'
              : 'text-gray-700'
          }`}
        >
          <AiOutlineHome className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Home</span>
        </Link>

        {/* Withdraw */}
        <div
          onClick={() => handleClick('withdraw')}
          className={`flex items-center space-x-3 cursor-pointer ${
            activeComponent === 'withdraw' ? 'text-emerald-500' : 'text-gray-700'
          }`}
        >
          <AiOutlineDollarCircle className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Withdraw</span>
        </div>

        {/* Convert */}
        <div
          onClick={() => handleClick('ConversionContainer')}
          className={`flex items-center space-x-3 cursor-pointer ${
            activeComponent === 'ConversionContainer' ? 'text-emerald-500' : 'text-gray-700'
          }`}
        >
          <AiOutlineSwap className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Convert</span>
        </div>

        {/* Transfer Funds */}
        <div
          onClick={() => handleClick('TransferContainer')}
          className={`flex items-center space-x-3 cursor-pointer ${
            activeComponent === 'TransferContainer' ? 'text-emerald-500' : 'text-gray-700'
          }`}
        >
          <FiSend className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Transfer Funds</span>
        </div>

        {/* History */}
        <div
          onClick={() => handleClick('HistoryContainer')}
          className={`flex items-center space-x-3 cursor-pointer ${
            activeComponent === 'HistoryContainer' ? 'text-emerald-500' : 'text-gray-700'
          }`}
        >
          <AiOutlineHistory className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Transactions History</span>
        </div>

        {/* Deposit */}
        <div
          onClick={() => handleClick('DepositContainer')}
          className={`flex items-center space-x-3 cursor-pointer ${
            activeComponent === 'DepositContainer' ? 'text-emerald-500' : 'text-gray-700'
          }`}
        >
          <AiOutlineDollarCircle className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Deposit</span>
        </div>

        {/* Currencies Section */}
        <div
          onClick={() => handleClick('CurrenciesContainer')}
          className={`flex items-center space-x-3 cursor-pointer ${
            activeComponent === 'CurrenciesContainer' ? 'text-emerald-500' : 'text-gray-700'
          }`}
        >
          <AiFillAlipayCircle className="text-blue-500 text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Currencies</span>
        </div>

        {/* Access History */}
        <div
          onClick={() => handleClick('accessHistory')}
          className={`flex items-center space-x-3 cursor-pointer ${
            activeComponent === 'accessHistory' ? 'text-emerald-500' : 'text-gray-700'
          }`}
        >
          <AiOutlineHistory className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Access History</span>
        </div>

        {/* Market Place (navigate directly) */}
        <div
          onClick={() => {
            navigate('/purchase');
            scrollToBottom();
          }}
          className="flex items-center space-x-3 cursor-pointer"
        >
          <AiOutlineShoppingCart className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Market Place</span>
        </div>

        {/* Reviews (opens modal, no scroll by default) */}
        <div
          onClick={handleReviewsClick}
          className={`flex items-center space-x-3 cursor-pointer ${
            activeComponent === 'reviews' ? 'text-emerald-500' : 'text-gray-700'
          }`}
        >
          <AiOutlineStar className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm">Reviews</span>
        </div>

        {/* Help (navigate directly) */}
        <div
          onClick={() => {
            navigate('/help');
            scrollToBottom();
          }}
          className="flex items-center space-x-3 cursor-pointer"
        >
          <AiOutlineQuestionCircle className="text-xl sm:text-2xl" />
          <span className="text-xs sm:text-sm pulse-dot-green">Help</span>
        </div>
      </nav>

      {/* Review Modal */}
      {showReviewModal && (
        <ReviewModal
          onClose={() => setShowReviewModal(false)}
          reviews={reviews}
          isLoading={isLoadingReviews}
        />
      )}
    </div>
  );
};

export default WalletSidebar;
