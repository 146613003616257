import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { 
  FaSpinner, 
  FaTimes, 
  FaInfoCircle, 
  FaCheck, 
  FaTrash,
  FaMoneyBillWave,
  FaMobile,
  FaPaypal,
  FaUniversity
} from 'react-icons/fa';

const PendingWithdrawals = () => {
    const { user } = useUser();
    const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
    const [showActionModal, setShowActionModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [modalAction, setModalAction] = useState(null); // 'approve' or 'delete'
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        const fetchPendingWithdrawals = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/api/customer/pending-withdrawals`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                if (response.status === 200) {
                    setPendingWithdrawals(response.data);
                } else {
                    toast.error('Failed to load pending withdrawal data');
                }
            } catch (error) {
                toast.error(`Failed to load pending withdrawal data: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPendingWithdrawals();
    }, [user.token]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const calculateHoursSince = (date) => {
        const now = new Date();
        const createdAt = new Date(date);
        const hours = Math.floor((now - createdAt) / (1000 * 60 * 60));
        return `${hours} Hrs`;
    };

    const getWithdrawalIcon = (withdrawal) => {
        if (withdrawal.provider) return <FaMobile className="text-green-500" />;
        if (withdrawal.email) return <FaPaypal className="text-blue-500" />;
        if (withdrawal.bank) return <FaUniversity className="text-purple-500" />;
        return <FaMoneyBillWave className="text-gray-500" />;
    };

    const getWithdrawalType = (withdrawal) => {
        if (withdrawal.provider) return 'Mobile Money';
        if (withdrawal.email) return 'PayPal';
        if (withdrawal.bank) return 'Bank Transfer';
        return 'Withdrawal';
    };

    const handleApprove = async () => {
        setIsProcessing(true);
        try {
            const response = await api.post('/api/admin/withdrawals/approve', 
                { withdrawalId: selectedWithdrawal._id }, 
                {
                    headers: { Authorization: `Bearer ${user.token}` },
                }
            );
            if (response.status === 200) {
                toast.success('Withdrawal approved successfully');
                setPendingWithdrawals(pendingWithdrawals.filter(w => w._id !== selectedWithdrawal._id));
                setShowActionModal(false);
            } else {
                toast.error(`Failed to approve withdrawal: ${response.data.message}`);
            }
        } catch (error) {
            toast.error(`Failed to approve withdrawal: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleDelete = async () => {
        setIsProcessing(true);
        try {
            const response = await api.delete(
                `/api/admin/withdrawals/${selectedWithdrawal._id}`,
                { headers: { Authorization: `Bearer ${user.token}` } }
            );
            if (response.status === 200) {
                toast.success('Withdrawal deleted successfully');
                setPendingWithdrawals(pendingWithdrawals.filter(w => w._id !== selectedWithdrawal._id));
                setShowActionModal(false);
            } else {
                toast.error(`Failed to delete withdrawal: ${response.data.message}`);
            }
        } catch (error) {
            toast.error(`Failed to delete withdrawal: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    const DetailRow = ({ label, value }) => (
        <div className="border-b border-dashed py-1">
            <div className="grid grid-cols-3 text-xs">
                <span className="font-medium">{label}:</span>
                <span className="col-span-2">{value || 'N/A'}</span>
            </div>
        </div>
    );

    const renderWithdrawalDetails = () => {
        if (!selectedWithdrawal) return null;
        
        return [
            { label: 'Withdrawal ID', value: selectedWithdrawal.withdrawalId },
            { label: 'Type', value: getWithdrawalType(selectedWithdrawal) },
            { label: 'Amount', value: `${selectedWithdrawal.currency} ${selectedWithdrawal.amount}` },
            { label: 'Status', value: selectedWithdrawal.status },
            { label: 'Initiated', value: formatDate(selectedWithdrawal.createdAt) },
            { label: 'First Name', value: selectedWithdrawal.firstName },
            { label: 'Last Name', value: selectedWithdrawal.lastName || 'N/A' },
            ...(selectedWithdrawal.provider ? [
                { label: 'Provider', value: selectedWithdrawal.provider },
                { label: 'Phone', value: selectedWithdrawal.phoneNumber }
            ] : []),
            ...(selectedWithdrawal.email ? [
                { label: 'Email', value: selectedWithdrawal.email }
            ] : []),
            ...(selectedWithdrawal.bank ? [
                { label: 'Bank', value: selectedWithdrawal.bank },
                { label: 'Account', value: selectedWithdrawal.accountNo },
                { label: 'Beneficiary', value: selectedWithdrawal.beneficiaryName }
            ] : [])
        ];
    };

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Pending Withdrawals</h3>
            
            <div className="border border-dashed border-gray-200 rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full min-w-[700px]">
                        <thead className="bg-gray-50">
                            <tr>
                                {['Type', 'Withdrawal ID', 'Amount', 'Initiated', 'User', 'Actions'].map((header) => (
                                    <th key={header} className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="6" className="px-3 py-4 text-center">
                                        <FaSpinner className="animate-spin mx-auto text-gray-400" />
                                    </td>
                                </tr>
                            ) : pendingWithdrawals.length > 0 ? (
                                pendingWithdrawals.map((withdrawal) => (
                                    <tr key={withdrawal._id} className="border-t border-dashed hover:bg-gray-50">
                                        <td className="px-3 py-2 text-xs text-gray-700 flex items-center gap-1">
                                            {getWithdrawalIcon(withdrawal)}
                                            {getWithdrawalType(withdrawal)}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">{withdrawal.withdrawalId}</td>
                                        <td className="px-3 py-2 text-xs font-medium text-gray-700">
                                            {withdrawal.currency} {withdrawal.amount}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            {calculateHoursSince(withdrawal.createdAt)}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            {withdrawal.firstName}
                                        </td>
                                        <td className="px-3 py-2 flex gap-2">
                                            <button
                                                onClick={() => {
                                                    setSelectedWithdrawal(withdrawal);
                                                    setShowDetailsModal(true);
                                                }}
                                                className="text-xs text-blue-600 hover:text-blue-800 flex items-center gap-1"
                                            >
                                                <FaInfoCircle className="text-xs" />
                                                Details
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setSelectedWithdrawal(withdrawal);
                                                    setModalAction('approve');
                                                    setShowActionModal(true);
                                                }}
                                                className="text-xs text-green-600 hover:text-green-800 flex items-center gap-1"
                                            >
                                                <FaCheck className="text-xs" />
                                                Approve
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setSelectedWithdrawal(withdrawal);
                                                    setModalAction('delete');
                                                    setShowActionModal(true);
                                                }}
                                                className="text-xs text-red-600 hover:text-red-800 flex items-center gap-1"
                                            >
                                                <FaTrash className="text-xs" />
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="px-3 py-4 text-center text-xs text-gray-500">
                                        No pending withdrawals found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Withdrawal Detail Modal */}
            {showDetailsModal && selectedWithdrawal && (
                <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-md border border-dashed border-gray-200 shadow">
                        <div className="flex justify-between items-center mb-3 pb-2 border-b border-dashed">
                            <div className="flex items-center gap-2">
                                <div className="p-1 rounded-full bg-blue-50 text-blue-600">
                                    {getWithdrawalIcon(selectedWithdrawal)}
                                </div>
                                <h3 className="text-sm font-semibold">
                                    {getWithdrawalType(selectedWithdrawal)} Withdrawal Details
                                </h3>
                            </div>
                            <button 
                                onClick={() => setShowDetailsModal(false)}
                                className="text-gray-400 hover:text-gray-600"
                            >
                                <FaTimes className="text-sm" />
                            </button>
                        </div>

                        <div className="space-y-2 text-xs">
                            {renderWithdrawalDetails().map((field, index) => (
                                <DetailRow key={index} label={field.label} value={field.value} />
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {/* Action Confirmation Modal */}
            {showActionModal && selectedWithdrawal && (
                <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-md border border-dashed border-gray-200 shadow">
                        <div className="flex items-center gap-2 mb-3">
                            <div className={`p-2 rounded-lg ${
                                modalAction === 'approve' 
                                    ? 'bg-green-50 text-green-600' 
                                    : 'bg-red-50 text-red-600'
                            }`}>
                                {modalAction === 'approve' ? <FaCheck /> : <FaTrash />}
                            </div>
                            <h3 className="text-sm font-semibold">
                                Confirm {modalAction === 'approve' ? 'Approval' : 'Deletion'}
                            </h3>
                        </div>
                        
                        <p className="mb-4 text-sm text-gray-600">
                            Are you sure you want to {modalAction} this {getWithdrawalType(selectedWithdrawal)} withdrawal of{' '}
                            <span className="font-medium">
                                {selectedWithdrawal.currency} {selectedWithdrawal.amount}
                            </span>?
                        </p>

                        <div className="border-t border-dashed pt-3">
                            <div className="flex justify-end gap-2">
                                <button
                                    onClick={() => setShowActionModal(false)}
                                    disabled={isProcessing}
                                    className="px-3 py-1.5 text-gray-600 hover:bg-gray-50 rounded-lg text-sm font-medium border border-dashed"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={modalAction === 'approve' ? handleApprove : handleDelete}
                                    disabled={isProcessing}
                                    className={`px-3 py-1.5 rounded-lg text-sm font-medium flex items-center gap-1 ${
                                        modalAction === 'approve'
                                            ? 'bg-green-50 text-green-600 hover:bg-green-100'
                                            : 'bg-red-50 text-red-600 hover:bg-red-100'
                                    } ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {isProcessing ? (
                                        <>
                                            <FaSpinner className="animate-spin" />
                                            {modalAction === 'approve' ? 'Approving...' : 'Deleting...'}
                                        </>
                                    ) : (
                                        <>
                                            {modalAction === 'approve' ? <FaCheck /> : <FaTrash />}
                                            Confirm {modalAction}
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PendingWithdrawals;