import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLock, 
  faBolt, 
  faCreditCard, 
  faUserShield, 
  faCircleCheck,
  faPause,
  faPen,
  faXmark,
  faCoins,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';

const UserAccounts = ({ userId }) => {
    const { user } = useUser();
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [actionType, setActionType] = useState(null);

    useEffect(() => { fetchAccounts(); }, [userId, user.token]);

    const fetchAccounts = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`/api/customer/${userId}/accounts`, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            response.status === 200 ? setAccounts(response.data) : toast.error('Failed to load accounts');
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAction = async () => {
        setIsProcessing(true);
        try {
            const endpoints = {
                hold: `/api/customer/account/${selectedAccount._id}/toggle-held`,
                active: `/api/customer/account/${selectedAccount._id}/toggle-active`,
                balance: `/api/customer/account/${selectedAccount._id}/update-balance`
            };
            
            const payloads = {
                hold: { isHeld: !selectedAccount.isHeld },
                active: { isActive: !selectedAccount.isActive },
                balance: { balance: selectedAccount.balance }
            };

            await api.patch(endpoints[actionType], payloads[actionType], {
                headers: { Authorization: `Bearer ${user.token}` }
            });
            
            toast.success('Update successful');
            fetchAccounts();
        } catch (error) {
            toast.error(`Update failed: ${error.message}`);
        } finally {
            setIsProcessing(false);
            resetModal();
        }
    };

    const resetModal = () => {
        setIsModalOpen(false);
        setActionType(null);
        setSelectedAccount(null);
    };

    const getModalConfig = () => {
        const baseConfig = {
            hold: {
                title: `${selectedAccount?.isHeld ? 'Release' : 'Hold'} Account`,
                icon: selectedAccount?.isHeld ? <FontAwesomeIcon icon={faLock} className="text-amber-500" /> : <FontAwesomeIcon icon={faPause} className="text-blue-500" />,
                message: `Are you sure you want to ${selectedAccount?.isHeld ? 'release' : 'hold'} this ${selectedAccount?.currency} account?`,
                button: selectedAccount?.isHeld ? 'Release' : 'Hold',
                buttonClass: selectedAccount?.isHeld ? 'bg-blue-500 hover:bg-blue-600' : 'bg-amber-500 hover:bg-amber-600'
            },
            active: {
                title: `Mark ${selectedAccount?.isActive ? 'Inactive' : 'Active'}`,
                icon: selectedAccount?.isActive ? <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" /> : <FontAwesomeIcon icon={faXmark} className="text-red-500" />,
                message: `Confirm you want to mark this ${selectedAccount?.currency} account as ${selectedAccount?.isActive ? 'inactive' : 'active'}`,
                button: 'Confirm',
                buttonClass: selectedAccount?.isActive ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
            },
            balance: {
                title: 'Update Balance',
                icon: <FontAwesomeIcon icon={faCoins} className="text-emerald-500" />,
                message: (
                    <div className="relative mt-2">
                        <input
                            type="number"
                            value={selectedAccount?.balance || ''}
                            onChange={(e) => setSelectedAccount(prev => ({
                                ...prev,
                                balance: e.target.value
                            }))}
                            className="w-full px-3 py-2 border-b-2 border-dashed focus:border-emerald-500 outline-none text-md"
                            placeholder="Enter new balance"
                        />
                        <span className="absolute right-0 top-2 text-gray-400">
                            {selectedAccount?.currencySymbol || '$'}
                        </span>
                    </div>
                ),
                button: 'Update',
                buttonClass: 'bg-emerald-500 hover:bg-emerald-600'
            }
        };
        return baseConfig[actionType] || {};
    };

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <h2 className="text-lg font-bold text-gray-800 mb-4 flex items-center gap-2">
                <span className="bg-emerald-50 text-emerald-600 rounded-lg p-2">
                    <FontAwesomeIcon icon={faUserShield} />
                </span>
                Account Management
            </h2>
            
            {/* Accounts Table */}
            <div className="border border-dashed border-gray-200 rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full min-w-[600px]">
                        <thead className="bg-gray-50">
                            <tr>
                                {['Currency', 'Balance', 'Status', 'Hold', 'Actions'].map((header) => (
                                    <th key={header} className="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {accounts.map((account) => (
                                <tr key={account._id} className="border-t border-dashed hover:bg-gray-50">
                                    <td className="px-4 py-2">
                                        <div className="flex items-center gap-2">
                                            <span className="w-6 h-6 rounded bg-emerald-50 flex items-center justify-center">
                                                {account.currencySymbol || <FontAwesomeIcon icon={faCreditCard} className="text-emerald-500 text-sm" />}
                                            </span>
                                            <span className="text-sm font-medium text-gray-700">
                                                {account.currencyName || account.currency}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium text-gray-700">
                                        {account.currencySymbol || '$'}{(account.balance ?? 0).toFixed(2)}
                                    </td>
                                    <td className="px-4 py-2">
                                        <button
                                            onClick={() => {
                                                setSelectedAccount(account);
                                                setActionType('active');
                                                setIsModalOpen(true);
                                            }}
                                            disabled={isProcessing}
                                            className={`px-3 py-1 rounded-lg text-xs font-medium whitespace-nowrap flex items-center gap-1 ${
                                                account.isActive 
                                                    ? 'bg-green-50 text-green-700 hover:bg-green-100' 
                                                    : 'bg-rose-50 text-rose-700 hover:bg-rose-100'
                                            }`}
                                        >
                                            <FontAwesomeIcon icon={account.isActive ? faCircleCheck : faXmark} className="text-xs" />
                                            {account.isActive ? 'Active' : 'Inactive'}
                                        </button>
                                    </td>
                                    <td className="px-4 py-2">
                                        <button
                                            onClick={() => {
                                                setSelectedAccount(account);
                                                setActionType('hold');
                                                setIsModalOpen(true);
                                            }}
                                            disabled={isProcessing}
                                            className={`px-3 py-1 rounded-lg text-xs font-medium whitespace-nowrap flex items-center gap-1 ${
                                                account.isHeld 
                                                    ? 'bg-amber-50 text-amber-700 hover:bg-amber-100' 
                                                    : 'bg-blue-50 text-blue-700 hover:bg-blue-100'
                                            }`}
                                        >
                                            <FontAwesomeIcon icon={account.isHeld ? faLock : faBolt} className="text-xs" />
                                            {account.isHeld ? 'Held' : 'Normal'}
                                        </button>
                                    </td>
                                    <td className="px-4 py-2">
                                        <button
                                            onClick={() => {
                                                setSelectedAccount(account);
                                                setActionType('balance');
                                                setIsModalOpen(true);
                                            }}
                                            disabled={isProcessing}
                                            className="px-3 py-1 bg-gray-50 text-gray-700 hover:bg-gray-100 rounded-lg text-xs font-medium whitespace-nowrap flex items-center gap-1"
                                        >
                                            <FontAwesomeIcon icon={faPen} className="text-xs" />
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-md border border-dashed border-gray-200 shadow">
                        <div className="flex items-center gap-2 mb-4">
                            <div className="p-2 rounded-lg bg-emerald-50">
                                {getModalConfig().icon}
                            </div>
                            <h3 className="text-lg font-bold text-gray-800">
                                {getModalConfig().title}
                            </h3>
                        </div>
                        
                        <div className="mb-4 text-sm text-gray-600">
                            {typeof getModalConfig().message === 'string' ? (
                                <p>{getModalConfig().message}</p>
                            ) : (
                                getModalConfig().message
                            )}
                        </div>

                        <div className="border-t border-dashed pt-4">
                            <div className="flex justify-end gap-2">
                                <button
                                    onClick={resetModal}
                                    disabled={isProcessing}
                                    className="px-3 py-1.5 text-gray-600 hover:bg-gray-50 rounded-lg text-sm font-medium border border-dashed flex items-center gap-1"
                                >
                                    <FontAwesomeIcon icon={faXmark} />
                                    Cancel
                                </button>
                                <button
                                    onClick={handleAction}
                                    disabled={isProcessing}
                                    className={`px-3 py-1.5 text-white rounded-lg text-sm font-medium flex items-center gap-1 ${getModalConfig().buttonClass}`}
                                >
                                    {isProcessing ? (
                                        <>
                                            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                                            Processing...
                                        </>
                                    ) : (
                                        <>
                                            {actionType === 'balance' && <FontAwesomeIcon icon={faCoins} />}
                                            {actionType === 'active' && <FontAwesomeIcon icon={selectedAccount?.isActive ? faXmark : faCircleCheck} />}
                                            {actionType === 'hold' && <FontAwesomeIcon icon={selectedAccount?.isHeld ? faBolt : faLock} />}
                                            {getModalConfig().button}
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserAccounts;