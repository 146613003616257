// src/components/Profile/ProfilePictureEnforcementModal.jsx
import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useUser } from '../context';
import ProfilePhotoUploader from './ProfilePhotoUploader';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaCamera, 
  FaLightbulb, 
  FaBan, 
  FaImage, 
  FaCheckCircle, 
  FaExclamationTriangle,
  FaSpinner,
  FaSignOutAlt,
  FaLock
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const ProfilePictureEnforcementModal = ({ open, onSuccess }) => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    if (uploadSuccess) {
      const timer = setTimeout(() => {
        onSuccess(); // This triggers the parent to close the modal
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [uploadSuccess, onSuccess]);
  
  return (
    <Dialog
      open={open} // Use the open prop from parent
      onClose={() => {}}
      className="relative z-[100]"
    >
      <AnimatePresence>
        {/* Backdrop */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-emerald-100/90 backdrop-blur-sm"
        />

        {/* Main Modal */}
        <div className="fixed inset-0 flex items-center justify-center p-2 sm:p-4">
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-[95%] sm:max-w-xl"
          >
            <div className="bg-white rounded-xl sm:rounded-2xl shadow-xl sm:shadow-2xl ring-1 ring-emerald-100 overflow-hidden flex flex-col max-h-[90vh]">
              {/* Header */}
              <div className="bg-emerald-600 p-4 sm:p-6">
                <Dialog.Title className="flex items-center gap-2 sm:gap-4">
                  <div className="p-2 sm:p-3 bg-emerald-700 rounded-lg sm:rounded-xl">
                    <FaCamera className="text-lg sm:text-xl text-white" />
                  </div>
                  <div>
                    <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-white">Profile Verification Required</h3>
                    <p className="text-emerald-100 text-xs sm:text-sm mt-0.5 sm:mt-1">
                      Required for account security and community access
                    </p>
                  </div>
                </Dialog.Title>
              </div>

              {/* Scrollable Content */}
              <div className="p-3 sm:p-4 md:p-6 space-y-4 sm:space-y-6 overflow-y-auto">
                {/* Requirements Grid */}
                <div className="space-y-2 sm:space-y-4">
                <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <li className="flex items-center gap-2 p-2 sm:p-3 bg-emerald-50 rounded-md sm:rounded-lg">
                      <div className="p-1 sm:p-2 text-emerald-600">
                        <FaCamera className="text-lg sm:text-xl" />
                      </div>
                      <span className="text-sm sm:text-base font-medium">Clear frontal photo</span>
                    </li>
                    <li className="flex items-center gap-2 p-2 sm:p-3 bg-emerald-50 rounded-md sm:rounded-lg">
                      <div className="p-1 sm:p-2 text-emerald-600">
                        <FaLightbulb className="text-lg sm:text-xl" />
                      </div>
                      <span className="text-sm sm:text-base font-medium">Good lighting</span>
                    </li>
                    <li className="flex items-center gap-2 p-2 sm:p-3 bg-emerald-50 rounded-md sm:rounded-lg">
                      <div className="p-1 sm:p-2 text-emerald-600">
                        <FaBan className="text-lg sm:text-xl" />
                      </div>
                      <span className="text-sm sm:text-base font-medium">Appropriate Picture</span>
                    </li>
                    <li className="flex items-center gap-2 p-2 sm:p-3 bg-emerald-50 rounded-md sm:rounded-lg">
                      <div className="p-1 sm:p-2 text-emerald-600">
                        <FaImage className="text-lg sm:text-xl" />
                      </div>
                      <span className="text-sm sm:text-base font-medium">High Quality </span>
                    </li>
                  </ul>
                </div>

                {/* Upload Section */}
                <div className="border-2 border-dashed border-emerald-100 rounded-lg p-2 sm:p-4">
                  <ProfilePhotoUploader
                    photoType="profile"
                    enforceRequirements={true}
                    onSave={() => setUploadSuccess(true)}
                    showCancel={false}
                  />
                </div>

                {/* Privacy Notice */}
                <div className="text-xs sm:text-sm text-gray-500 p-2 sm:p-3 bg-gray-50 rounded-lg">
                  <div className="flex gap-2 sm:gap-3 mb-1 sm:mb-2">
                    <FaLock className="text-gray-400 mt-0.5 sm:mt-1 flex-shrink-0" />
                    <div>
                      Your profile photo is used <strong>exclusively</strong> for:
                      <ul className="list-disc pl-4 sm:pl-5 mt-1 sm:mt-2 space-y-0.5 sm:space-y-1">
                        <li>Identity verification within Verdant Charity</li>
                        <li>Community visibility features</li>
                        <li>Fraud prevention measures</li>
                      </ul>
                    </div>
                  </div>
                  <p className="mt-1 sm:mt-2 text-[0.7rem] sm:text-xs">
                    We never share biometric data with third parties. 
                    <a href="/privacy" className="text-emerald-600 hover:underline ml-1">
                      Learn more
                    </a>
                  </p>
                </div>

                {/* Success Message */}
                {uploadSuccess && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="flex items-center gap-2 sm:gap-3 bg-emerald-50 p-2 sm:p-3 rounded-lg text-emerald-700 text-sm sm:text-base"
                  >
                    <FaCheckCircle className="text-emerald-500 animate-pop-in text-lg" />
                    <span>Verification successful! Applying changes...</span>
                  </motion.div>
                )}

                {/* Cancel Section */}
                <div className="border-t pt-2 sm:pt-3">
                  <button
                    onClick={() => setShowLogoutConfirm(true)}
                    className="w-full py-1.5 sm:py-2 border-2 border-dashed border-gray-300 rounded-lg text-gray-500 hover:border-red-200 hover:text-red-600 transition-all text-sm sm:text-base"
                  >
                    <div className="flex items-center justify-center gap-1 sm:gap-2">
                      <FaSignOutAlt className="text-sm sm:text-base" />
                      <span>Cancel Verification</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Logout Confirmation Modal */}
        {showLogoutConfirm && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-2 sm:p-4"
          >
            <div className="bg-white rounded-lg sm:rounded-xl p-4 sm:p-6 max-w-[90%] sm:max-w-sm w-full shadow-xl">
              <div className="text-center">
                <FaExclamationTriangle className="text-red-500 text-3xl sm:text-4xl mx-auto mb-3 sm:mb-4" />
                <h3 className="text-lg sm:text-xl font-bold mb-1.5 sm:mb-2">Cancel Verification?</h3>
                <p className="text-gray-600 text-sm sm:text-base mb-3 sm:mb-4">
                  You'll need to complete this step to access Verdant Charity features.
                </p>
                <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 justify-center">
                  <button
                    onClick={() => setShowLogoutConfirm(false)}
                    className="px-3 sm:px-4 py-1.5 sm:py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-gray-700 text-sm sm:text-base"
                  >
                    Continue Verification
                  </button>
                  <button
                    onClick={handleLogout}
                    className="px-3 sm:px-4 py-1.5 sm:py-2 bg-red-50 hover:bg-red-100 rounded-lg text-red-600 flex items-center justify-center gap-1 sm:gap-2 text-sm sm:text-base"
                  >
                    <FaSpinner className="animate-spin text-sm sm:text-base" />
                    <span>Confirm Logout</span>
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Dialog>
  );
};

export default ProfilePictureEnforcementModal;