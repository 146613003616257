import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { 
  FaUser, 
  FaEnvelope, 
  FaPhoneAlt, 
  FaFingerprint, 
  FaMapMarkerAlt, 
  FaEye, 
  FaBan,
  FaSpinner,
  FaQuestionCircle
} from 'react-icons/fa';

const AssociatedAccounts = ({ userId }) => {
    const { user } = useUser();
    const [associatedAccounts, setAssociatedAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentProcessingId, setCurrentProcessingId] = useState(null);
    const navigate = useNavigate();

    const fetchAssociatedAccounts = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`/api/customer/${userId}/associated-accounts`, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            if (response.status === 200) {
                setAssociatedAccounts(response.data);
            } else {
                toast.error('Failed to load associated accounts');
            }
        } catch (error) {
            toast.error(`Failed to load associated accounts: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAssociatedAccounts();
    }, [userId, user.token]);

    const toggleBan = async (accountId, isBanned) => {
        setIsProcessing(true);
        setCurrentProcessingId(accountId);
        try {
            await api.patch(`/api/admin/${accountId}/toggleBan`, {}, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            toast.success(`User ${isBanned ? 'unbanned' : 'banned'} successfully`);
            fetchAssociatedAccounts();
        } catch (error) {
            const message = error.response?.data?.message || "Failed to toggle ban status";
            toast.error(message);
        } finally {
            setIsProcessing(false);
            setCurrentProcessingId(null);
        }
    };

    const getAssociationIcon = (type) => {
        switch(type) {
            case 'fingerprint': 
                return <FaFingerprint className="text-purple-500 inline mr-1" />;
            case 'location': 
                return <FaMapMarkerAlt className="text-blue-500 inline mr-1" />;
            default: 
                return <FaQuestionCircle className="text-gray-500 inline mr-1" />;
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-100">
            <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
                <FaUser className="text-blue-500 mr-2" />
                Associated Accounts
            </h3>
            
            <div className="border border-gray-200 rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full min-w-[600px]">
                        <thead className="bg-gray-50">
                            <tr className="border-b border-gray-200">
                                {['Username', 'Email', 'Phone', 'Association', 'Actions'].map((header) => (
                                    <th 
                                        key={header} 
                                        className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="5" className="px-3 py-4 text-center">
                                        <FaSpinner className="animate-spin mx-auto text-gray-400" />
                                    </td>
                                </tr>
                            ) : associatedAccounts.length > 0 ? (
                                associatedAccounts.map((account) => (
                                    <tr 
                                        key={account._id} 
                                        className="border-b border-gray-100 hover:bg-gray-50 transition-colors"
                                    >
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            <FaUser className="text-gray-400 inline mr-1" />
                                            {account.username || 'N/A'}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            <FaEnvelope className="text-gray-400 inline mr-1" />
                                            {account.email || 'N/A'}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            <FaPhoneAlt className="text-gray-400 inline mr-1" />
                                            {account.phoneNumber || 'N/A'}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            {getAssociationIcon(account.associationType)}
                                            {account.associationType === 'fingerprint' ? 'Fingerprint' : 
                                             account.associationType === 'location' ? 'Location' : 'Unknown'}
                                        </td>
                                        <td className="px-3 py-2">
                                            <div className="flex gap-1">
                                                <button
                                                    onClick={() => navigate(`/user-details/${account._id}`)}
                                                    className="px-2 py-1 border border-gray-200 rounded text-xs hover:bg-gray-100 flex items-center gap-1 text-gray-700"
                                                >
                                                    <FaEye className="text-xs text-blue-500" />
                                                    View
                                                </button>
                                                <button
                                                    onClick={() => toggleBan(account._id, account.isBanned)}
                                                    disabled={isProcessing && currentProcessingId === account._id}
                                                    className={`px-2 py-1 rounded text-xs flex items-center gap-1 ${
                                                        account.isBanned
                                                            ? 'bg-green-50 text-green-700 hover:bg-green-100 border border-green-100'
                                                            : 'bg-red-50 text-red-700 hover:bg-red-100 border border-red-100'
                                                    }`}
                                                >
                                                    {isProcessing && currentProcessingId === account._id ? (
                                                        <FaSpinner className="animate-spin" />
                                                    ) : (
                                                        <FaBan className={account.isBanned ? "text-green-600" : "text-red-600"} />
                                                    )}
                                                    {account.isBanned ? 'Unban' : 'Ban'}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="px-3 py-4 text-center text-xs text-gray-500">
                                        No associated accounts found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AssociatedAccounts;