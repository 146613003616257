import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { 
  FaTrashAlt, 
  FaSpinner, 
  FaTimes, 
  FaInfoCircle, 
  FaChevronLeft, 
  FaChevronRight,
  FaMobileAlt,
  FaLaptop,
  FaTabletAlt,
  FaDesktop,
  FaApple,
  FaWindows
} from 'react-icons/fa';

const AccessHistory = ({ userId }) => {
    const { user } = useUser();
    const [accessHistory, setAccessHistory] = useState([]);
    const [selectedHistory, setSelectedHistory] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedRecord, setSelectedRecord] = useState(null);

    useEffect(() => {
        const fetchAccessHistory = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/api/admin/loginhistory/${userId}/access-history?page=${page}`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                if (response.status === 200) {
                    setAccessHistory(response.data.accessHistory);
                    setTotalPages(response.data.totalPages);
                } else {
                    toast.error('Failed to load access history');
                }
            } catch (error) {
                toast.error(`Failed to load access history: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAccessHistory();
    }, [userId, user.token, page]);

    const handleCheckboxChange = (historyId) => {
        setSelectedHistory(prev => 
            prev.includes(historyId) 
                ? prev.filter(id => id !== historyId) 
                : [...prev, historyId]
        );
    };

    const handleDeleteClick = () => {
        if (selectedHistory.length > 0) {
            setShowDeleteModal(true);
        } else {
            toast.error("Please select at least one record to delete");
        }
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            const response = await api.delete(`/api/admin/loginhistory/${userId}/access-history`, {
                headers: { Authorization: `Bearer ${user.token}` },
                data: { historyIds: selectedHistory },
            });
            if (response.status === 200) {
                toast.success(`${selectedHistory.length} record(s) deleted`);
                setAccessHistory(accessHistory.filter(history => !selectedHistory.includes(history._id)));
                setSelectedHistory([]);
                setShowDeleteModal(false);
            } else {
                toast.error('Failed to delete records');
            }
        } catch (error) {
            toast.error(`Failed to delete records: ${error.message}`);
        } finally {
            setIsDeleting(false);
        }
    };

    const formatLoginTime = (loginTime) => {
        const date = new Date(loginTime);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    const formatCoordinates = (coordinates) => {
        if (!coordinates || coordinates.length !== 2) return 'N/A';
        return `${coordinates[1].toFixed(4)}, ${coordinates[0].toFixed(4)}`;
    };

    const goToNextPage = () => setPage(prev => Math.min(prev + 1, totalPages));
    const goToPrevPage = () => setPage(prev => Math.max(prev - 1, 1));

    const getDeviceIcon = (device) => {
        if (!device) return <FaDesktop className="text-gray-400" />;
        
        const deviceLower = device.toLowerCase();
        if (deviceLower.includes('mobile')) return <FaMobileAlt className="text-blue-500" />;
        if (deviceLower.includes('tablet')) return <FaTabletAlt className="text-purple-500" />;
        if (deviceLower.includes('windows')) return <FaWindows className="text-blue-600" />;
        if (deviceLower.includes('mac')) return <FaApple className="text-gray-600" />;
        if (deviceLower.includes('iphone') || deviceLower.includes('ipad')) return <FaApple className="text-gray-700" />;
        return <FaLaptop className="text-gray-500" />;
    };

    const DetailRow = ({ label, value }) => (
        <div className="border-b border-dashed py-1">
            <div className="grid grid-cols-3 text-xs">
                <span className="font-medium">{label}:</span>
                <span className="col-span-2">{value || 'N/A'}</span>
            </div>
        </div>
    );

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Access History</h3>
            
            <div className="flex items-center justify-between mb-4">
                {selectedHistory.length > 0 && (
                    <button
                        onClick={handleDeleteClick}
                        disabled={isDeleting}
                        className={`bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1.5 rounded-lg text-sm font-medium flex items-center gap-1 ${
                            isDeleting ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        <FaTrashAlt className="text-xs" />
                        <span>Delete ({selectedHistory.length})</span>
                    </button>
                )}
            </div>

            <div className="border border-dashed border-gray-200 rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full min-w-[800px]">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            setSelectedHistory(e.target.checked 
                                                ? accessHistory.map(history => history._id) 
                                                : []);
                                        }}
                                        checked={selectedHistory.length === accessHistory.length && accessHistory.length > 0}
                                    />
                                </th>
                                {['Login Time', 'Email', 'Platform', 'Device', 'Location', 'Actions'].map((header) => (
                                    <th key={header} className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="7" className="px-3 py-4 text-center">
                                        <FaSpinner className="animate-spin mx-auto text-gray-400" />
                                    </td>
                                </tr>
                            ) : accessHistory.length > 0 ? (
                                accessHistory.map((history) => (
                                    <tr key={history._id} className="border-t border-dashed hover:bg-gray-50">
                                        <td className="px-3 py-2">
                                            <input
                                                type="checkbox"
                                                checked={selectedHistory.includes(history._id)}
                                                onChange={() => handleCheckboxChange(history._id)}
                                                className="rounded"
                                            />
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            {formatLoginTime(history.loginTime)}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">{history.email}</td>
                                        <td className="px-3 py-2 text-xs text-gray-700">{history.platform}</td>
                                        <td className="px-3 py-2 text-xs text-gray-700 flex items-center gap-1">
                                            <span className="flex-shrink-0">{getDeviceIcon(history.device)}</span>
                                            <span>{history.device}</span>
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            {history.locationName || 'N/A'}
                                            {history.location?.coordinates && (
                                                <div className="text-xs text-gray-500">
                                                    {formatCoordinates(history.location.coordinates)}
                                                </div>
                                            )}
                                        </td>
                                        <td className="px-3 py-2">
                                            <button
                                                onClick={() => setSelectedRecord(history)}
                                                className="text-xs text-blue-600 hover:text-blue-800 flex items-center gap-1"
                                            >
                                                <FaInfoCircle className="text-xs" />
                                                Details
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="px-3 py-4 text-center text-xs text-gray-500">
                                        No access records found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination */}
            <div className="flex items-center justify-between mt-4">
                <button
                    onClick={goToPrevPage}
                    disabled={page === 1}
                    className={`flex items-center gap-1 px-3 py-1.5 rounded-lg border border-dashed text-sm ${page === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
                >
                    <FaChevronLeft className="text-xs" />
                    Previous
                </button>
                <span className="text-sm text-gray-600">
                    Page {page} of {totalPages}
                </span>
                <button
                    onClick={goToNextPage}
                    disabled={page === totalPages}
                    className={`flex items-center gap-1 px-3 py-1.5 rounded-lg border border-dashed text-sm ${page === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
                >
                    Next
                    <FaChevronRight className="text-xs" />
                </button>
            </div>

            {/* Record Detail Modal */}
            {selectedRecord && (
                <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-md border border-dashed border-gray-200 shadow">
                        <div className="flex justify-between items-center mb-3 pb-2 border-b border-dashed">
                            <div className="flex items-center gap-2">
                                <div className="p-1 rounded-full bg-blue-50 text-blue-600">
                                    <FaInfoCircle />
                                </div>
                                <h3 className="text-sm font-semibold">Access Record Details</h3>
                            </div>
                            <button 
                                onClick={() => setSelectedRecord(null)}
                                className="text-gray-400 hover:text-gray-600"
                            >
                                <FaTimes className="text-sm" />
                            </button>
                        </div>

                        <div className="space-y-2 text-xs">
                            <div className="border-b border-dashed py-1">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">Login Time:</span>
                                    <span className="col-span-2">{formatLoginTime(selectedRecord.loginTime)}</span>
                                </div>
                            </div>
                            <div className="border-b border-dashed py-1">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">Email:</span>
                                    <span className="col-span-2">{selectedRecord.email}</span>
                                </div>
                            </div>
                            <div className="border-b border-dashed py-1">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">Platform:</span>
                                    <span className="col-span-2">{selectedRecord.platform}</span>
                                </div>
                            </div>
                            <div className="border-b border-dashed py-1">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">Device:</span>
                                    <span className="col-span-2 flex items-center gap-1">
                                        {getDeviceIcon(selectedRecord.device)} {selectedRecord.device}
                                    </span>
                                </div>
                            </div>
                            <div className="border-b border-dashed py-1">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">Location:</span>
                                    <span className="col-span-2">
                                        {selectedRecord.locationName || 'N/A'}
                                        {selectedRecord.location?.coordinates && (
                                            <div className="text-gray-500">
                                                {formatCoordinates(selectedRecord.location.coordinates)}
                                            </div>
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="border-b border-dashed py-1">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">IP Address:</span>
                                    <span className="col-span-2">{selectedRecord.ipAddress || 'N/A'}</span>
                                </div>
                            </div>
                            <div className="border-b border-dashed py-1">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">User Agent:</span>
                                    <span className="col-span-2 text-xs break-all">{selectedRecord.userAgent || 'N/A'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-md border border-dashed border-gray-200 shadow">
                        <div className="flex items-center gap-2 mb-3">
                            <div className="p-2 rounded-lg bg-red-50 text-red-600">
                                <FaTrashAlt />
                            </div>
                            <h3 className="text-sm font-semibold">Confirm Delete</h3>
                        </div>
                        
                        <p className="mb-4 text-sm text-gray-600">
                            Are you sure you want to delete {selectedHistory.length} selected record(s)?
                        </p>

                        <div className="border-t border-dashed pt-3">
                            <div className="flex justify-end gap-2">
                                <button
                                    onClick={() => setShowDeleteModal(false)}
                                    disabled={isDeleting}
                                    className="px-3 py-1.5 text-gray-600 hover:bg-gray-50 rounded-lg text-sm font-medium border border-dashed"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDelete}
                                    disabled={isDeleting}
                                    className={`px-3 py-1.5 bg-red-50 text-red-600 hover:bg-red-100 rounded-lg text-sm font-medium flex items-center gap-1 ${
                                        isDeleting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                >
                                    {isDeleting ? (
                                        <>
                                            <FaSpinner className="animate-spin" />
                                            Deleting...
                                        </>
                                    ) : (
                                        <>
                                            <FaTrashAlt />
                                            Confirm
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccessHistory;