import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faEdit, faUser, faIdCard, faEnvelope, faPhone, faGlobe, 
  faCalendarAlt, faMapMarkerAlt, faShieldAlt, faWallet,
  faCheckCircle, faTimesCircle, faClock, faLock,
  faBuilding, faFlag, faCreditCard, faChartLine,
  faBan, faUnlock, faGift, faHistory, faStar, faBell,
  faHandshake, faQrcode, faExchangeAlt, faShieldVirus,
  faUserShield, faMoneyBillWave, faPercent, faCoins,
  faUserTag, faUserCheck, faUserClock, faCopy,
  faUserSlash, faUserPlus, faUserMinus, faUserEdit,faInfoCircle, faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { countries } from 'country-data-list';

const KYC_CONFIG = {
  US: {
    fields: ['ssn', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'state', 'postalCode', 'countryCode', 'timezone'],
    fieldLabels: {
      ssn: 'Social Security Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      streetAddress: 'Street Address',
      city: 'City',
      state: 'State',
      postalCode: 'ZIP Code',
      countryCode: 'Country',
      timezone: 'Timezone',
    },
  },
  UK: {
    fields: ['nationalInsurance', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'county', 'postalCode', 'countryCode', 'timezone'],
    fieldLabels: {
      nationalInsurance: 'National Insurance Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      streetAddress: 'Street Address',
      city: 'City/Town',
      county: 'County',
      postalCode: 'Postal Code',
      countryCode: 'Country',
      timezone: 'Timezone',
    },
  },
  KE: {
    fields: ['idNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'town', 'region', 'countryCode', 'timezone'],
    fieldLabels: {
      idNumber: 'National ID Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      town: 'Town/City',
      region: 'County',
      countryCode: 'Country',
      timezone: 'Timezone',
    },
  },
  fallback: {
    fields: ['identificationNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'city', 'region', 'countryCode', 'timezone'],
    fieldLabels: {
      identificationNumber: 'Identification Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      city: 'City/Town',
      region: 'Region',
      countryCode: 'Country',
      timezone: 'Timezone',
    },
  },
};

const UserInfo = ({ userId }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [userInfo, setUserInfo] = useState(null);
  const [userKyc, setUserKyc] = useState(null);
  const [kycConfig, setKycConfig] = useState(KYC_CONFIG.fallback);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setLoading(true);
        const [userResponse, kycResponse] = await Promise.all([
          api.get(`/api/customer/user/${userId}`, {
            headers: { Authorization: `Bearer ${user.token}` },
          }),
          api.get(`/api/customer/kyc/${userId}`, {
            headers: { Authorization: `Bearer ${user.token}` },
          })
        ]);

        if (userResponse.status === 200) {
          setUserInfo(userResponse.data);
        } else {
          toast.error('Failed to load user data');
        }

        if (kycResponse.status === 200) {
          setUserKyc(kycResponse.data);
          const countryCode = kycResponse.data.countryCode;
          setKycConfig(
            countryCode === 'US' ? KYC_CONFIG.US :
            (countryCode === 'UK' || countryCode === 'GB') ? KYC_CONFIG.UK :
            countryCode === 'KE' ? KYC_CONFIG.KE :
            KYC_CONFIG.fallback
          );
        } else {
          toast.error('Failed to load KYC data');
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error(`Failed to load data: ${error.response?.data?.message || error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [userId, user.token]);

  const handleCopy = (text) => {
    if (!text) return;
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to clipboard!');
    }).catch(() => {
      toast.error('Failed to copy');
    });
  };

  const getInitials = (name) => {
    if (!userInfo) return '';
    return (userInfo.username || '')
      .split(' ')
      .filter(n => n.length > 0)
      .map(n => n[0])
      .join('')
      .toUpperCase()
      .substring(0, 2);
  };

  const getCountryName = (code) => {
    const country = countries[code];
    return country ? country.name : code;
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Not specified';
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return 'Never';
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const renderField = (icon, label, value, transform = null, extraClasses = '', showCopy = false) => (
    <div className={`flex items-center p-2 bg-white/20 backdrop-blur-sm rounded-lg mb-2 transition-all hover:bg-white/30 ${extraClasses}`}>
      <div className="flex-shrink-0 w-6 text-emerald-600">
        <FontAwesomeIcon icon={icon} className="text-sm" />
      </div>
      <div className="ml-2 flex-1 min-w-0">
        <p className="text-[11px] font-semibold text-gray-500 uppercase tracking-wider">{label}</p>
        <div className="flex items-center justify-between">
          <p className="text-xs font-medium text-gray-900 truncate">
            {transform ? transform(value) : value || 'Not specified'}
          </p>
          {showCopy && value && (
            <button 
              onClick={() => handleCopy(value)}
              className="ml-2 p-1 hover:bg-gray-100 rounded"
              aria-label={`Copy ${label}`}
            >
              <FontAwesomeIcon icon={faCopy} className="text-gray-500 text-xs" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
  
  const renderGridField = (icon, label, value, transform = null, colSpan = 1, showCopy = false) => (
    <div className={`col-span-${colSpan}`}>
      {renderField(icon, label, value, transform, '', showCopy)}
    </div>
  );

  const renderStatusBadge = (condition, trueText, falseText, icon = null) => (
    <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-[11px] font-medium ${
      condition ? 'bg-emerald-100/80 text-emerald-800' : 'bg-rose-100/80 text-rose-800'
    }`}>
      {icon && <FontAwesomeIcon 
        icon={icon || (condition ? faCheckCircle : faTimesCircle)} 
        className={`mr-1 ${condition ? 'text-emerald-500' : 'text-rose-500'}`} 
      />}
      {condition ? trueText : falseText}
    </span>
  );

  const renderKycField = (field) => {
    if (!userKyc) return null;
    
    const fieldIcons = {
      ssn: faShieldAlt,
      nationalInsurance: faShieldAlt,
      idNumber: faShieldAlt,
      identificationNumber: faShieldAlt,
      firstName: faUser,
      lastName: faUser,
      dob: faCalendarAlt,
      phone: faPhone,
      email: faEnvelope,
      streetAddress: faMapMarkerAlt,
      city: faMapMarkerAlt,
      town: faMapMarkerAlt,
      state: faMapMarkerAlt,
      county: faMapMarkerAlt,
      region: faMapMarkerAlt,
      postalCode: faMapMarkerAlt,
      countryCode: faGlobe,
      timezone: faClock,
    };

    return (
      <div key={field} className="p-2 bg-white/20 backdrop-blur-sm rounded-lg mb-2 transition-all hover:bg-white/30 border-b border-dotted border-gray-200 last:border-0">
        <div className="flex items-center">
          <div className="flex-shrink-0 w-6 text-orange-600">
            <FontAwesomeIcon icon={fieldIcons[field] || faIdCard} className="text-sm" />
          </div>
          <div className="ml-2 flex-1 min-w-0">
            <p className="text-[11px] font-semibold text-gray-500 uppercase tracking-wider">
              {kycConfig.fieldLabels[field]}
            </p>
            <p className="text-xs font-medium text-gray-900">
              {field === 'dob' && userKyc[field] 
                ? new Date(userKyc[field]).toLocaleDateString()
                : field === 'countryCode'
                  ? getCountryName(userKyc[field])
                  : userKyc[field] || 'Not specified'}
            </p>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-emerald-50/40 to-orange-50/40 flex items-center justify-center p-4">
        <div className="text-center space-y-4">
          <div className="relative mx-auto w-16 h-16">
            {/* Outer ring with gradient */}
            <div className="absolute inset-0 border-4 border-emerald-100 rounded-full"></div>
            {/* Animated spinner with gradient */}
            <div className="absolute inset-0 border-4 border-transparent border-t-emerald-400 border-r-emerald-400 rounded-full animate-spin [animation-duration:1.2s]"></div>
            {/* Optional inner dot */}
            <div className="absolute inset-4 bg-emerald-400/20 rounded-full animate-pulse"></div>
          </div>
          <div>
            <p className="text-sm font-medium text-gray-700">Loading user information</p>
            <p className="text-xs text-gray-500 mt-1">Please wait a moment...</p>
          </div>
          {/* Optional progress bar */}
          <div className="w-32 h-1.5 bg-emerald-100 rounded-full overflow-hidden mx-auto">
            <div className="h-full bg-emerald-400 rounded-full animate-[progress_1.5s_ease-in-out_infinite]"></div>
          </div>
        </div>
      </div>
    );
  }

  const getCardColor = () => {
    if (userInfo?.isBanned) return 'bg-red-50/30';
    if (userInfo?.accessBlocked) return 'bg-orange-50/30';
    return 'bg-yellow-50/30';
  };

  const renderFieldGroup = (title, icon, fields) => (
    <div className="pb-3 border-b border-dotted border-gray-300">
      <div className="p-3 bg-white/20 backdrop-blur-sm rounded-lg space-y-2">
        <p className="text-[11px] font-semibold text-gray-500 uppercase mb-1 flex items-center">
          <FontAwesomeIcon icon={icon} className="text-emerald-600 mr-2" />
          {title}
        </p>
        {fields}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50/30 to-orange-50/30 p-3 md:p-4">
      <ToastContainer 
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      
      {/* Profile Header */}
      <div className="relative mb-12 max-w-6xl mx-auto">
        <div 
          className="h-40 md:h-48 rounded-t-xl bg-gradient-to-r from-emerald-100/50 to-orange-100/50 relative overflow-hidden"
          style={{ 
            backgroundImage: userKyc?.coverPhotoUrl ? `url(${userKyc.coverPhotoUrl})` : undefined,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-t from-black/10 to-black/5 rounded-t-xl" />
        </div>
        
        <div className="absolute -bottom-10 left-1/2 transform -translate-x-1/2">
          <div className="relative group">
            {userKyc?.profilePhotoUrl ? (
              <img 
                src={userKyc.profilePhotoUrl}
                alt="Profile"
                className="w-20 h-20 md:w-24 md:h-24 rounded-full border-3 border-white/80 shadow-lg object-cover"
              />
            ) : (
              <div className="w-20 h-20 md:w-24 md:h-24 rounded-full border-3 border-white/80 bg-gradient-to-br from-emerald-100/80 to-orange-100/80 flex items-center justify-center shadow-lg">
                <span className="text-xl font-bold text-emerald-800">{getInitials()}</span>
              </div>
            )}
            <button 
              onClick={() => navigate(`/user-edit/${userId}`)}
              className="absolute -bottom-1 -right-1 bg-white/90 p-1.5 rounded-full shadow-sm hover:bg-white transition-all group-hover:opacity-100 opacity-0 focus:opacity-100"
              aria-label="Edit profile"
            >
              <FontAwesomeIcon icon={faEdit} className="text-emerald-600 text-xs" />
            </button>
          </div>
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 max-w-6xl mx-auto">
        {/* Account Details Card */}
        <div className={`${getCardColor()} backdrop-blur-sm rounded-xl p-4 shadow-lg border border-white/50`}>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-sm font-bold text-gray-800 flex items-center">
              <FontAwesomeIcon icon={faUser} className="text-emerald-600 mr-2" />
              Account Details
            </h2>
            <button 
              onClick={() => navigate(`/user-edit/${userId}`)}
              className="p-1.5 hover:bg-white/30 rounded-full transition-all"
              aria-label="Edit account"
            >
              <FontAwesomeIcon icon={faEdit} className="text-emerald-600 text-xs" />
            </button>
          </div>

          <div className="space-y-3">
            {/* Basic Info */}
            {renderFieldGroup('Basic Information', faUser, (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {renderGridField(faIdCard, 'Username', userInfo?.username)}
{renderGridField(faQrcode, 'Pay ID', userInfo?.payId, null, 1, true)}
{renderGridField(faEnvelope, 'Email', userInfo?.email, null, 1, true)}
{renderGridField(faPhone, 'Phone', userInfo?.phoneNumber, null, 1, true)}
                {renderGridField(faGlobe, 'Country', userInfo?.country)}
                {renderGridField(faWallet, 'Balance', userInfo?.balance, (val) => `$${(val || 0).toLocaleString()}`)}
                {renderGridField(faCoins, 'Currency', userInfo?.currency)}
                {renderGridField(faBuilding, 'Account Type', userInfo?.accountType, (val) => val?.charAt(0).toUpperCase() + val?.slice(1))}
                {renderGridField(faUserTag, 'Role', userInfo?.role, (val) => val?.charAt(0).toUpperCase() + val?.slice(1))}
           
                {renderGridField(faIdCard, 'Unique ID', userInfo?.uniqueId)}
              </div>
            ))}

            {/* Status */}
            {renderFieldGroup('Account Status', faShieldAlt, (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {renderGridField(faBan, 'Banned', userInfo?.isBanned ? 'Yes' : 'No')}
                {renderGridField(faUserCheck, 'Verified', userInfo?.isVerified ? 'Yes' : 'No')}
                {renderGridField(faPhone, 'Phone Verified', userInfo?.isPhoneVerified ? 'Yes' : 'No')}
                {renderGridField(faStar, 'Premium', userInfo?.isPremium ? 'Yes' : 'No')}
                {renderGridField(faShieldVirus, 'Access Blocked', userInfo?.accessBlocked ? 'Yes' : 'No')}
                {renderGridField(faUserShield, 'Immunity', userInfo?.immunity ? 'Yes' : 'No')}
                {renderGridField(faExchangeAlt, 'Upgrade Request', userInfo?.upgradeRequest ? 'Yes' : 'No')}
                {renderGridField(faUserClock, 'Online Status', userInfo?.isOnline ? 'Online' : 'Offline')}
                {renderGridField(faBan, 'Ban Count', userInfo?.banCount)}
              </div>
            ))}

            {/* Security */}
            {renderFieldGroup('Security & Access', faLock, (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {renderGridField(faLock, '2FA Status', userInfo?.otp ? 'Enabled' : 'Disabled')}
                {renderGridField(faHistory, 'Last Login', userInfo?.lastLogin, formatDateTime)}
                {renderGridField(faClock, 'Created At', userInfo?.createdAt, formatDate)}
                {renderGridField(faBan, 'Ban Release', userInfo?.banReleaseDate, formatDate)}
                {renderGridField(faBell, 'Last Reminder', userInfo?.lastReminder, formatDateTime)}
                {renderGridField(faUserClock, 'Last Active', userInfo?.lastActive, formatDateTime)}
              </div>
            ))}

            {/* Tracking Info */}
            {userInfo?.trackingInfo?.map((info, index) => (
              renderFieldGroup(`Device Information ${index + 1}`, faGlobe, (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  {renderGridField(faLock, 'Fingerprint ID', info.fingerprintId)}
                  {renderGridField(faMapMarkerAlt, 'IP Address', info.userIp)}
                  {renderGridField(faIdCard, 'Browser', info.browser)}
                  {renderGridField(faBuilding, 'Operating System', info.os)}
                  {renderGridField(faCreditCard, 'Device', info.device)}
                  {renderGridField(faGlobe, 'Platform', info.platform)}
                </div>
              ))
            ))}

            {/* Additional Info */}
            {renderFieldGroup('Additional Information', faInfoCircle, (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {renderGridField(faHandshake, 'Referral Code', userInfo?.referralCode)}
                {renderGridField(faChartLine, 'Points', userInfo?.points)}
                {renderGridField(faMoneyBillWave, 'Backoffice Withdrawal', userInfo?.backofficeWithdrawal ? 'Enabled' : 'Disabled')}
                {renderGridField(faPercent, 'OTP Resend Attempts', userInfo?.otpResendAttempts)}
                {renderGridField(faClock, 'OTP Next Resend', userInfo?.otpNextResendTime, formatDateTime)}
                {renderGridField(faUserEdit, 'Profile Image', userInfo?.profileImage ? 'Uploaded' : 'Not Uploaded')}
              </div>
            ))}

            {/* Disclaimer */}
            {renderFieldGroup('Legal', faFileSignature, (
              <div className="grid grid-cols-1 gap-2">
                {renderGridField(faShieldAlt, 'Anti-Fraud Disclaimer', 
                  userInfo?.antiFraudDisclaimer?.accepted ? `Accepted on ${formatDate(userInfo.antiFraudDisclaimer.acceptedAt)}` : 'Not Accepted',
                  null,
                  2
                )}
              </div>
            ))}
          </div>
        </div>

       {/* KYC Details Card */}
<div className="bg-white/30 backdrop-blur-sm rounded-xl p-4 shadow-lg border border-white/50">
  <div className="flex justify-between items-center mb-4">
    <h2 className="text-sm font-bold text-gray-800 flex items-center">
      <FontAwesomeIcon icon={faIdCard} className="text-orange-600 mr-2" />
      KYC Information
    </h2>
    <button 
      onClick={() => navigate(`/kyc-edit/${userId}`)}
      className="p-1.5 hover:bg-white/30 rounded-full transition-all"
      aria-label="Edit KYC"
    >
      <FontAwesomeIcon icon={faEdit} className="text-orange-600 text-xs" />
    </button>
  </div>

  <div className="space-y-3">
    {userKyc ? (
      <div className="space-y-2">
        {kycConfig.fields.map((field) => renderKycField(field))}
        
        {/* Additional KYC verification status */}
        <div className="p-3 bg-white/20 backdrop-blur-sm rounded-lg border border-dotted border-gray-300">
          <p className="text-[11px] font-semibold text-gray-500 uppercase mb-2 flex items-center">
            <FontAwesomeIcon icon={faShieldAlt} className="text-orange-600 mr-2" />
            Verification Status
          </p>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <p className="text-[11px] font-semibold text-gray-500 uppercase mb-1">Document Status</p>
              {renderStatusBadge(userKyc?.documentVerified, 'Verified', 'Pending')}
            </div>
            <div>
              <p className="text-[11px] font-semibold text-gray-500 uppercase mb-1">Selfie Status</p>
              {renderStatusBadge(userKyc?.selfieVerified, 'Verified', 'Pending')}
            </div>
            <div>
              <p className="text-[11px] font-semibold text-gray-500 uppercase mb-1">Address Status</p>
              {renderStatusBadge(userKyc?.addressVerified, 'Verified', 'Pending')}
            </div>
            <div>
              <p className="text-[11px] font-semibold text-gray-500 uppercase mb-1">Overall Status</p>
              {renderStatusBadge(
                userKyc?.documentVerified && userKyc?.selfieVerified && userKyc?.addressVerified,
                'Complete',
                'Incomplete'
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="text-center p-4 bg-white/20 rounded-lg border border-dotted border-gray-300">
        <div className="w-10 h-10 border-3 border-orange-500/30 border-t-orange-500 rounded-full animate-spin mx-auto mb-3"></div>
        <p className="text-xs text-gray-500">No KYC information available</p>
        <button 
          onClick={() => navigate(`/kyc-edit/${userId}`)}
          className="mt-3 px-3 py-1.5 bg-orange-500/90 text-white rounded-md hover:bg-orange-600 transition-colors text-xs"
        >
          Add KYC Information
        </button>
      </div>
    )}
  </div>
</div>
</div>
</div>
  );
};

export default UserInfo;