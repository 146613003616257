// File: PublicCampaignsGrid.jsx
//lists all campaigns
import React, { useEffect, useState } from "react";
import { FaSearch, FaHeart, FaEye, FaUserFriends } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import api from "../../api";
import { useUser } from "../context";
import CrowdfundLikesModal from "./Modals/CrowdfundLikesModal"; // Reuse the modal

const FOUNDER_PLACEHOLDER =
  "https://d25oniaj7o2jcw.cloudfront.net/photo-category-medical@2x.jpg";

const PublicCampaignsGrid = () => {
  const { user } = useUser();
  const [campaigns, setCampaigns] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit] = useState(9);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // State for the Likes Modal
  const [showLikersModal, setShowLikersModal] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  // ----------------------------
  // Fetch Campaigns (Load More)
  // ----------------------------
  const fetchCampaigns = async (pageNum = 1, searchTerm = "", append = false) => {
    setLoading(true);
    try {
      const res = await api.get("/api/crowdfund/campaigns", {
        params: { page: pageNum, limit, search: searchTerm },
      });
      if (res.data.success) {
        const newCampaigns = res.data.data || [];
        const total = res.data.pagination?.total || 0;
        const totalPages = Math.ceil(total / limit);

        // For each campaign, fetch the first 3 likers to display on the card.
        const campaignsWithLikers = await Promise.all(
          newCampaigns.map(async (camp) => {
            try {
              const resLikes = await api.get(`/api/crowdfund/campaigns/${camp._id}/likes`, {
                params: { page: 1, limit: 3 },
              });
              if (resLikes.data.success) {
                camp.likers = resLikes.data.data.likers; // attach first 3 likers
                camp.likeCount = resLikes.data.data.totalLikes; // attach total like count
              } else {
                camp.likers = [];
                camp.likeCount = 0;
              }
            } catch (err) {
              console.error("Error fetching likers for campaign:", camp._id, err);
              camp.likers = [];
              camp.likeCount = 0;
            }
            return camp;
          })
        );

        if (append) {
          setCampaigns((prev) => [...prev, ...campaignsWithLikers]);
        } else {
          setCampaigns(campaignsWithLikers);
        }
        setHasMore(pageNum < totalPages);
        console.log(`Fetched page ${pageNum} of campaigns (Total pages: ${totalPages})`);
      }
    } catch (err) {
      console.error("Error fetching campaigns", err);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------
  // Initial load (and page changes)
  // --------------------------------
  useEffect(() => {
    fetchCampaigns(page, search, page > 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // -------------------------
  // Helpers
  // -------------------------
  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    setHasMore(true);
    fetchCampaigns(1, search, false);
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  const getDaysLeft = (endDate) => {
    if (!endDate) return 0;
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;
    return diff > 0 ? Math.ceil(diff / (1000 * 60 * 60 * 24)) : 0;
  };

  const getProgress = (goal, current) => {
    if (!goal || goal <= 0) return 0;
    const ratio = (current / goal) * 100;
    return ratio > 100 ? 100 : ratio;
  };

  const handleViewDetails = (campSlug) => {
    window.location.href = `/crowdfund/${campSlug}`;
  };

  // Open Likers Modal for a campaign
  const openLikersModal = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setShowLikersModal(true);
  };

  return (
    <div className="w-full mx-auto">
      {/* Heading & Search */}
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <form onSubmit={handleSearch} className="mt-3 sm:mt-0">
          <div className="flex items-center border border-gray-300 rounded-md overflow-hidden">
            <input
              type="text"
              placeholder="Search campaigns..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="px-2 py-2 focus:outline-none flex-1 text-sm text-gray-700"
            />
            <button
              type="submit"
              className="px-3 py-2 text-sm bg-gray-100 hover:bg-gray-200 text-gray-600"
            >
              <FaSearch />
            </button>
          </div>
        </form>
      </div>

      {/* Loading Spinner for Initial Load */}
      {loading && campaigns.length === 0 && (
        <div className="flex flex-col items-center justify-center min-h-screen bg-emerald-50">
          <svg
            className="animate-spin h-10 w-10 text-emerald-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8z"
            ></path>
          </svg>
          <p className="mt-4 text-lg text-emerald-600">Loading campaigns...</p>
        </div>
      )}

      {/* Campaign Cards */}
      {!loading && campaigns.length === 0 ? (
        <p className="text-center text-emerald-600">No campaigns found.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {campaigns.map((camp) => {
            const daysLeft = getDaysLeft(camp.endDate);
            const progress = getProgress(camp.fundingGoal, camp.currentAmount);
            const shortDesc =
              camp.campaignDescription?.replace(/<[^>]*>?/gm, "") || "No description";
            const tagsArr = camp.tags
              ? camp.tags.split(",").map((t) => t.trim())
              : [];

            return (
              <div
                key={camp._id}
                className="border border-gray-200 rounded-md bg-gradient-to-r from-emerald-50 to-blue-50 relative cursor-pointer shadow-sm hover:shadow-md transition"
                onClick={() => handleViewDetails(camp.slug)}
              >
                {/* Cover Image */}
                {camp.coverImage ? (
                  <img
                    src={camp.coverImage}
                    alt={camp.campaignTitle}
                    className="w-full h-44 object-cover rounded-t-md"
                  />
                ) : (
                  <div className="w-full h-44 bg-gray-100 flex items-center justify-center text-gray-500 text-sm rounded-t-md">
                    No Image
                  </div>
                )}

                {/* Business Info */}
                {camp.businessProfile ? (
                  <div className="absolute top-2 left-2 flex items-center space-x-2 bg-white bg-opacity-90 rounded-full px-2 py-1 shadow-sm">
                    <img
                      src={camp.businessProfile.companyLogo || FOUNDER_PLACEHOLDER}
                      alt="Business Logo"
                      className="w-6 h-6 rounded-full object-cover"
                    />
                    <span className="text-xs text-gray-600">
                      {camp.businessProfile.businessName
                        ? camp.businessProfile.businessName.split(" ")[0]
                        : "Business"}
                    </span>
                  </div>
                ) : (
                  // Fallback to Founder Info if no business info is available
                  <div className="absolute top-2 left-2 flex items-center space-x-2 bg-white bg-opacity-90 rounded-full px-2 py-1 shadow-sm">
                    <img
                      src={FOUNDER_PLACEHOLDER}
                      alt="founder avatar"
                      className="w-6 h-6 rounded-full object-cover"
                    />
                    <span className="text-xs text-gray-600">
                      {camp.founderName || "John Doe"}
                    </span>
                  </div>
                )}

                {/* Days Left */}
                <div className="absolute top-2 right-2">
                  <div
                    className={`h-10 w-10 flex items-center justify-center rounded-full text-xs font-bold shadow ${
                      daysLeft <= 0
                        ? "bg-red-100 text-red-600"
                        : "bg-emerald-100 text-emerald-700"
                    }`}
                  >
                    {daysLeft <= 0 ? "Ended" : `${daysLeft}d`}
                  </div>
                </div>

                <div className="p-4 space-y-2">
                  <h3 className="text-sm sm:text-base font-semibold text-gray-800 line-clamp-2">
                    {camp.campaignTitle}
                  </h3>
                  <p className="text-xs text-gray-600 line-clamp-2">
                    {shortDesc}
                  </p>

                  {/* Tags */}
                  {tagsArr.length > 0 && (
                    <div className="flex flex-wrap gap-1 mt-1">
                      {tagsArr.map((tag, i) => (
                        <span
                          key={i}
                          className="text-xs bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}

                  {/* Progress Bar */}
                  <div className="mt-3">
                    <div className="flex justify-between text-xs text-gray-500 mb-1">
                      <span>Raised: ${camp.currentAmount || 0}</span>
                      <span>Goal: ${camp.fundingGoal}</span>
                    </div>
                    <div className="w-full h-2 bg-gray-200 rounded">
                      <div
                        className="h-full bg-emerald-500 rounded"
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>

                  {/* Like & View Section */}
                  <div className="flex justify-between items-center mt-3 text-xs text-gray-400">
                    <div className="flex items-center space-x-2">
                      <div
                        className="flex items-center space-x-1 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          openLikersModal(camp._id);
                        }}
                      >
                        <FaHeart className="text-red-500" />
                        <span>{camp.likeCount || 0}</span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <FaEye />
                        <span>{camp.views || 0}</span>
                      </div>
                    </div>
                    {daysLeft > 0 && (
                      <span className="text-emerald-600 font-semibold">
                        {daysLeft} days left
                      </span>
                    )}
                  </div>

                  {/* Clumped Likers Display (Instagram Style) */}
                  {camp.likeCount > 0 && (
                    <div className="mt-2 bg-white p-2 rounded-lg">
                      <div className="flex -space-x-2 overflow-hidden">
                        {camp.likers &&
                          camp.likers.slice(0, 3).map((liker) => (
                            <img
                              key={liker._id}
                              src={liker.fakeUser.profilePicture}
                              alt={`${liker.fakeUser.firstName} ${liker.fakeUser.lastName}`}
                              className="inline-block h-6 w-6 rounded-full ring-2 ring-white object-cover"
                            />
                          ))}
                        {camp.likeCount > 3 && (
                          <span className="inline-block h-6 w-6 rounded-full ring-2 ring-white bg-gray-200 text-gray-600 flex items-center justify-center text-xs">
                            +{camp.likeCount - 3}
                          </span>
                        )}
                      </div>
                      <div className="mt-2 flex items-center">
                        <FaUserFriends className="text-emerald-600 mr-1" />
                        <span className="text-xs text-gray-600">
                          Liked by{" "}
                          {camp.likers &&
                            camp.likers
                              .slice(0, 2)
                              .map((liker) => liker.fakeUser.firstName)
                              .join(", ")}
                          {camp.likeCount > 2 ? " and others" : ""}
                        </span>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            openLikersModal(camp._id);
                          }}
                          className="ml-auto text-xs text-blue-500 hover:underline flex items-center"
                        >
                          See All <AiOutlineArrowRight className="ml-1" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Load More Campaigns Button */}
      {!loading && hasMore && campaigns.length > 0 && (
        <div className="flex justify-center mt-6">
          <button
            onClick={handleLoadMore}
            className="px-4 py-2 text-sm bg-emerald-100 hover:bg-emerald-200 text-emerald-700 rounded-md font-semibold shadow"
          >
            Load More
          </button>
        </div>
      )}

      {/* Loading More Indicator */}
      {loading && campaigns.length > 0 && (
        <p className="text-center text-emerald-600 mt-4">Loading more...</p>
      )}

      {/* Likers Modal (Reused) */}
      <CrowdfundLikesModal
        show={showLikersModal}
        campaignId={selectedCampaignId}
        onClose={() => {
          setShowLikersModal(false);
          setSelectedCampaignId(null);
        }}
      />
    </div>
  );
};

export default PublicCampaignsGrid;
