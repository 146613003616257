import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { FaReceipt, FaSpinner, FaTimes } from 'react-icons/fa';

const UserDeposits = ({ userId }) => {
    const { user } = useUser();
    const [deposits, setDeposits] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDeposit, setSelectedDeposit] = useState(null);

    useEffect(() => {
        const fetchDeposits = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/api/customer/${userId}/deposits`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                if (response.status === 200) {
                    setDeposits(response.data);
                } else {
                    toast.error('Failed to load deposit data');
                }
            } catch (error) {
                toast.error(`Failed to load deposit data: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDeposits();
    }, [userId, user.token]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    const DetailRow = ({ label, value }) => (
        <div className="border-b border-dashed py-1">
            <div className="grid grid-cols-3 text-xs">
                <span className="font-medium">{label}:</span>
                <span className="col-span-2">{value || 'N/A'}</span>
            </div>
        </div>
    );

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Deposit History</h3>
            
            <div className="border border-dashed border-gray-200 rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full min-w-[700px]">
                        <thead className="bg-gray-50">
                            <tr>
                                {['Deposit ID', 'Phone', 'Amount', 'Date', 'Transaction ID', 'Receipt', 'Status', 'Action'].map((header) => (
                                    <th key={header} className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="8" className="px-3 py-4 text-center">
                                        <FaSpinner className="animate-spin mx-auto text-gray-400" />
                                    </td>
                                </tr>
                            ) : deposits.length > 0 ? (
                                deposits.map((deposit) => (
                                    <tr key={deposit.transactionId} className="border-t border-dashed hover:bg-gray-50">
                                        <td className="px-3 py-2 text-xs text-gray-700">{deposit.depositId}</td>
                                        <td className="px-3 py-2 text-xs text-gray-700">{deposit.initiatorPhoneNumber}</td>
                                        <td className="px-3 py-2 text-xs font-medium text-gray-700">
                                            {deposit.currency || '$'} {deposit.amount?.toFixed(2)}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            {formatDate(deposit.transactionDate)}
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">{deposit.transactionId}</td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            {deposit.mpesaReceiptNumber ? (
                                                <div className="flex items-center gap-1">
                                                    <FaReceipt className="text-blue-500 text-xs" />
                                                    <span>{deposit.mpesaReceiptNumber}</span>
                                                </div>
                                            ) : 'N/A'}
                                        </td>
                                        <td className="px-3 py-2">
                                            <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                                deposit.isSuccess 
                                                    ? 'bg-green-100 text-green-800' 
                                                    : 'bg-red-100 text-red-800'
                                            }`}>
                                                {deposit.isSuccess ? 'Success' : 'Failed'}
                                            </span>
                                        </td>
                                        <td className="px-3 py-2">
                                            <button 
                                                onClick={() => setSelectedDeposit(deposit)}
                                                className="text-xs text-blue-600 hover:text-blue-800"
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="px-3 py-4 text-center text-xs text-gray-500">
                                        No deposit records found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Transaction Detail Modal */}
            {selectedDeposit && (
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-xl">
                        <div className="flex justify-between items-center mb-3 pb-2 border-b border-dashed">
                            <div className="flex items-center gap-2">
                                <FaReceipt className="text-green-600" />
                                <h3 className="text-sm font-semibold">Transaction Details</h3>
                            </div>
                            <button 
                                onClick={() => setSelectedDeposit(null)}
                                className="text-gray-400 hover:text-gray-600"
                            >
                                <FaTimes className="text-sm" />
                            </button>
                        </div>

                        <div className="space-y-2 text-xs">
                            <DetailRow label="Deposit ID" value={selectedDeposit.depositId} />
                            <DetailRow label="Phone Number" value={selectedDeposit.phoneNumber} />
                            <DetailRow label="Amount" value={`${selectedDeposit.currency || '$'} ${selectedDeposit.amount?.toFixed(2)}`} />
                            <DetailRow label="Transaction Date" value={formatDate(selectedDeposit.transactionDate)} />
                            <DetailRow label="Transaction ID" value={selectedDeposit.transactionId} />
                            <DetailRow label="Merchant Request ID" value={selectedDeposit.merchantRequestId} />
                            <DetailRow label="Checkout Request ID" value={selectedDeposit.checkoutRequestId} />
                            <DetailRow label="Currency" value={selectedDeposit.currency} />
                            <DetailRow label="Response Code" value={selectedDeposit.responseCode} />
                            <DetailRow label="Response Description" value={selectedDeposit.responseDescription} />
                            <DetailRow label="Customer Message" value={selectedDeposit.customerMessage} />
                            <DetailRow label="MPesa Receipt" value={selectedDeposit.mpesaReceiptNumber} />
                            <DetailRow label="Error Code" value={selectedDeposit.errorCode} />
                            <DetailRow label="Error Message" value={selectedDeposit.error} />
                            
                            <div className="pt-2 border-t border-dashed">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">Status:</span>
                                    <span className={`col-span-2 ${
                                        selectedDeposit.isSuccess ? 'text-green-600' : 'text-red-600'
                                    }`}>
                                        {selectedDeposit.isSuccess ? 'Completed Successfully' : 'Failed'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserDeposits;