//src/components/wallet/history/TransferHistory.js

import React, { useEffect, useState } from 'react';
import api from '../../../api';
import Lottie from 'lottie-react';
import unavailableAnimation from '../../lottie/noLinks.json';
import loadingAnimation from '../../lottie/loading.json';
import { useUser } from "../../context";
import { FaCheckCircle, FaTimesCircle, FaClock, FaInfoCircle } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';

const TransferHistory = ({ onViewDetails }) => {
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);


    useEffect(() => {
        const fetchTransactions = async () => {
            setIsLoading(true);
            try {
                const response = await api.get('/api/transfers/transfers/', {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                setTransactions(response.data);
            } catch (error) {
                console.error("Error fetching transactions: ", error);
            }
            setIsLoading(false);
        };

        fetchTransactions();
    }, [user.token]);

    const getStatusBackground = (status) => {
        switch (status) {
            case 'pending':
                return 'bg-yellow-50';
            case 'processing':
                return 'bg-blue-50';
            case 'completed':
                return 'bg-green-50';
            case 'failed':
                return 'bg-red-50';
            default:
                return 'bg-gray-50';
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    };

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg shadow flex flex-col">
            {isLoading ? (
                <div className="flex justify-center items-center">
                    <Lottie animationData={loadingAnimation} style={{ width: 100, height: 100 }} />
                </div>
            ) : transactions.length > 0 ? (
                transactions.map((transaction, index) => (
                    <div
                        key={index}
                        className={`flex items-center justify-between p-2 mt-3 border rounded ${getStatusBackground(transaction.status)}`}
                        onClick={() => onViewDetails(transaction._id)}
                    >
                        <div className="flex items-center space-x-3">
                            {transaction.sender?._id === user._id ? (
                                transaction.receiver?.kyc?.profilePhotoUrl ? (
                                    <img
                                        src={transaction.receiver.kyc.profilePhotoUrl}
                                        alt="Receiver's Profile"
                                        className="h-10 w-10 rounded-full border border-emerald-500 shadow-md object-cover"
                                    />
                                ) : (
                                    <div className="flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-full border border-emerald-500 bg-opacity-10 shadow-md">
                                        <span className="text-xl font-bold text-emerald-500">
                                            {transaction.receiver?.kyc?.firstName[0]?.toUpperCase() || 'U'}
                                        </span>
                                    </div>
                                )
                            ) : (
                                transaction.sender?.kyc?.profilePhotoUrl ? (
                                    <img
                                        src={transaction.sender.kyc.profilePhotoUrl}
                                        alt="Sender's Profile"
                                        className="h-10 w-10 rounded-full border border-emerald-500 shadow-md object-cover"
                                    />
                                ) : (
                                    <div className="flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-full border border-emerald-500 bg-opacity-10 shadow-md">
                                        <span className="text-xl font-bold text-emerald-500">
                                            {transaction.sender?.kyc?.firstName[0]?.toUpperCase() || 'U'}
                                        </span>
                                    </div>
                                )
                            )}
                            <div>
                                <p className="font-semibold text-xs">
                                    {transaction.sender?._id === user._id ? transaction.receiverFirstName : transaction.senderFirstName}
                                </p>
                                <p className="flex items-center space-x-2 text-xs text-gray-500">
                                    <FiClock className="text-gray-400" />
                                    <span>{formatDate(transaction.createdAt)} · {transaction.status}</span>
                                </p>
                            </div>
                        </div>
                        <span className={`font-bold text-xs ${transaction.sender?._id === user?._id ? 'text-red-500' : 'text-green-500'}`}>
                            {transaction.sender?._id === user._id ? '-' : '+'}{transaction.currency} {transaction.amount}
                        </span>
                    </div>
                ))
            ) : (
                <div className="flex justify-center items-center flex-col">
                    <Lottie animationData={unavailableAnimation} style={{ width: 200, height: 200 }} />
                    <p className="text-gray-500 font-semibold mt-4">No transactions found</p>
                </div>
            )}
        </div>
    );
};

export default TransferHistory;
