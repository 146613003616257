import React, { useEffect, useState } from "react";
import {
    FaSearch,
    FaTrash,
    FaEye,
    FaSpinner,
    FaPlus,
    FaTimes,
    FaPause,
    FaPlay,
    FaCheck
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import api from "../../../api";
import { useUser } from "../../context";

/**
 * AdminDonationCampaignList
 *
 * - Fetches all donation campaigns from /api/admin/donations/donation-campaigns
 * - Allows toggling notifications, pausing/resuming, deleting (shared user routes)
 * - Allows changing status (admin route: /api/admin/donation-campaigns/:id/status)
 * - Provides fancy modals for confirmations & better UX
 */
const AdminDonationCampaignList = () => {
    const { user } = useUser();
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searching, setSearching] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");

    const [page, setPage] = useState(1);
    const limit = 10;
    const [totalPages, setTotalPages] = useState(1);

    // ============ "Details" Modal ============
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [detailsCampaign, setDetailsCampaign] = useState(null);
    const [detailActionLoading, setDetailActionLoading] = useState(false);

    // ============ Delete Confirmation Modal ============
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [campaignToDelete, setCampaignToDelete] = useState(null);
    const [deletingId, setDeletingId] = useState(null);

    // ============ Confirm Status Update Modal ============
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [pendingStatus, setPendingStatus] = useState("");
    const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);

    // Add state for editing targets
    const [editingTargets, setEditingTargets] = useState(false);
    const [tempTargets, setTempTargets] = useState({
        likes: 0,
        views: 0,
        amount: 0,
        dailyCap: 0,
    });

    useEffect(() => {
        fetchCampaigns();
        // eslint-disable-next-line
    }, [page]);

    // ================ FETCH CAMPAIGNS ================
    const fetchCampaigns = async () => {
        setLoading(true);
        try {
            const config = { headers: { Authorization: `Bearer ${user.token}` } };
            const params = { page, limit };
            if (searchTerm) params.search = searchTerm;
            if (selectedStatus) params.status = selectedStatus; // Add status
    
            const res = await api.get("/api/admin/donations/donation-campaigns", {
                params,
                ...config
            });
            if (res.data.success) {
                setCampaigns(res.data.data);
                setTotalPages(res.data.pagination.pages);
            } else {
                toast.error("Failed to fetch admin campaigns.");
            }
        } catch (err) {
            console.error("Error fetching admin campaigns:", err);
            toast.error("Error loading campaigns.");
        } finally {
            setLoading(false);
            setSearching(false);
        }
    };

    // ============== SEARCH ===============
    const handleSearch = async (e) => {
        e.preventDefault();
        setSearching(true);
        setPage(1);
        await fetchCampaigns();
    };

    // ============== Days Left Helper ===============
    const getDaysLeft = (endDate) => {
        const now = new Date();
        const e = new Date(endDate);
        const diff = e - now;
        return diff > 0 ? Math.ceil(diff / (1000 * 60 * 60 * 24)) : 0;
    };

    // ============== SHOW DETAILS ===============
    const openDetailsModal = (camp) => {
        setDetailsCampaign(camp);
        setShowDetailsModal(true);
    };
    const closeDetailsModal = () => {
        setShowDetailsModal(false);
        setDetailsCampaign(null);
    };

    // ============== OPEN/CLOSE DELETE MODAL ===============
    const openDeleteModal = (camp) => {
        setCampaignToDelete(camp);
        setShowDeleteModal(true);
    };
    const closeDeleteModal = () => {
        setCampaignToDelete(null);
        setShowDeleteModal(false);
    };

    // ============== DELETE ===============
    const confirmDelete = async () => {
        if (!campaignToDelete) return;
        const campId = campaignToDelete._id;
        setDeletingId(campId);
        try {
            const config = { headers: { Authorization: `Bearer ${user.token}` } };
            const res = await api.delete(`/api/donationcampaigns/${campId}`, config);
            if (res.data.success) {
                toast.success("Campaign deleted successfully.");
                setCampaigns((prev) => prev.filter((c) => c._id !== campId));
            } else {
                toast.error(res.data.message || "Failed to delete campaign.");
            }
        } catch (err) {
            console.error("Error deleting campaign:", err);
            toast.error("Error deleting campaign.");
        } finally {
            setDeletingId(null);
            closeDeleteModal();
        }
    };

    // ============== NOTIFICATIONS TOGGLE (User route) ===============
    const toggleNotifications = async (campId) => {
        setDetailActionLoading(true);
        try {
            const config = { headers: { Authorization: `Bearer ${user.token}` } };
            const res = await api.put(`/api/donationcampaigns/${campId}/toggle-notifications`, {}, config);
            if (res.data.success) {
                toast.info(res.data.message);
                setCampaigns((prev) =>
                    prev.map((c) =>
                        c._id === campId
                            ? { ...c, notificationsEnabled: !c.notificationsEnabled }
                            : c
                    )
                );
                if (detailsCampaign && detailsCampaign._id === campId) {
                    setDetailsCampaign((dc) => ({
                        ...dc,
                        notificationsEnabled: !dc.notificationsEnabled
                    }));
                }
            } else {
                toast.error(res.data.message || "Failed to toggle notifications.");
            }
        } catch (err) {
            console.error("Error toggling notifications:", err);
            toast.error("Error toggling notifications.");
        } finally {
            setDetailActionLoading(false);
        }
    };

    // ============== PAUSE/RESUME (User route) ===============
    const handlePauseResume = async (camp) => {
        if (!camp) return;
        setDetailActionLoading(true);
        const endpoint =
            camp.status === "live" || camp.status === "approved" ? "pause" : "resume";
        try {
            const config = { headers: { Authorization: `Bearer ${user.token}` } };
            const res = await api.put(`/api/donationcampaigns/${camp._id}/${endpoint}`, {}, config);
            if (res.data.success) {
                toast.success(
                    endpoint === "pause" ? "Campaign paused." : "Campaign resumed!"
                );
                const updated = res.data.data;
                setCampaigns((prev) => prev.map((c) => (c._id === updated._id ? updated : c)));
                setDetailsCampaign(updated);
            } else {
                toast.error(res.data.message || "Failed to update campaign status.");
            }
        } catch (err) {
            console.error("Pause/Resume error:", err);
            toast.error("Error pausing/resuming campaign.");
        } finally {
            setDetailActionLoading(false);
        }
    };

    // ============== STATUS UPDATE (Admin route) ===============
    const handleStatusChange = (newStatus) => {
        // We store "newStatus" in state, then open a confirm modal
        setPendingStatus(newStatus);
        setShowStatusModal(true);
    };
    const closeStatusModal = () => {
        setPendingStatus("");
        setShowStatusModal(false);
    };
    const confirmStatusUpdate = async () => {
        if (!detailsCampaign || !pendingStatus) return;
        setStatusUpdateLoading(true);
        try {
            const config = { headers: { Authorization: `Bearer ${user.token}` } };
            const res = await api.put(
                `/api/admin/donations/donation-campaigns/${detailsCampaign._id}/status`,
                { status: pendingStatus },
                config
            );
            if (res.data.success) {
                toast.success(`Status updated to '${pendingStatus}'`);
                const updated = res.data.data;
                setCampaigns((prev) =>
                    prev.map((c) => (c._id === updated._id ? updated : c))
                );
                setDetailsCampaign(updated);
                closeStatusModal();
            } else {
                toast.error(res.data.message || "Failed to update status.");
            }
        } catch (err) {
            console.error("Error updating campaign status:", err);
            toast.error("Error updating campaign status.");
        } finally {
            setStatusUpdateLoading(false);
        }
    };

    useEffect(() => {
        if (detailsCampaign) {
            setTempTargets({
                likes: detailsCampaign.targetLikes || 0,
                views: detailsCampaign.targetViews || 0,
                amount: detailsCampaign.targetAmount || 0,
                dailyCap: detailsCampaign.trackingDoc?.currentDailyCap || 0,
            });
        }
    }, [detailsCampaign]);


    // ============== UPDATE TARGETS ===============
    const handleUpdateTargets = async () => {
        if (!detailsCampaign) return;

        setDetailActionLoading(true);
        try {
            const config = { headers: { Authorization: `Bearer ${user.token}` } };
            const res = await api.put(
                `/api/admin/donations/donation-campaigns/${detailsCampaign._id}/targets`,
                {
                    targetLikes: tempTargets.likes,
                    targetViews: tempTargets.views,
                    targetAmount: tempTargets.amount,
                    dailyCap: tempTargets.dailyCap,
                },
                config
            );

            if (res.data.success) {
                toast.success("Targets updated successfully");
                // Update local state with new targets
                setDetailsCampaign((prev) => ({
                    ...prev,
                    targetLikes: tempTargets.likes,
                    targetViews: tempTargets.views,
                    targetAmount: tempTargets.amount,
                    trackingDoc: {
                        ...prev.trackingDoc,
                        currentDailyCap: tempTargets.dailyCap,
                    },
                }));
                setEditingTargets(false); // Exit edit mode
            } else {
                toast.error(res.data.message || "Failed to update targets");
            }
        } catch (err) {
            console.error("Error updating targets:", err);
            toast.error("Error updating targets");
        } finally {
            setDetailActionLoading(false);
        }
    };


    const generatePageNumbers = () => {
        const current = page;
        const total = totalPages;
        const siblingCount = 1;
        const totalPageNumbers = siblingCount * 2 + 5; // Show up to 7 pages
    
        if (total <= totalPageNumbers) {
            return Array.from({ length: total }, (_, i) => i + 1);
        }
    
        const leftSibling = Math.max(current - siblingCount, 1);
        const rightSibling = Math.min(current + siblingCount, total);
    
        const showLeftEllipsis = leftSibling > 2;
        const showRightEllipsis = rightSibling < total - 1;
    
        if (!showLeftEllipsis && showRightEllipsis) {
            const leftRange = Array.from({ length: 5 }, (_, i) => i + 1);
            return [...leftRange, '...', total];
        }
    
        if (showLeftEllipsis && !showRightEllipsis) {
            const rightRange = Array.from({ length: 5 }, (_, i) => total - 4 + i);
            return [1, '...', ...rightRange];
        }
    
        if (showLeftEllipsis && showRightEllipsis) {
            const middleRange = Array.from({ length: rightSibling - leftSibling + 1 }, (_, i) => leftSibling + i);
            return [1, '...', ...middleRange, '...', total];
        }
    };



    // ============ RENDERING =============
    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
                <FaSpinner className="animate-spin text-emerald-600 text-4xl mb-3" />
                <p className="text-lg text-emerald-600">Loading campaigns...</p>
            </div>
        );
    }

    return (
        <div className="p-4 pb-20 max-w-6xl mx-auto">
            <ToastContainer />

            {/* Header + Search */}
            <div className="mb-4 flex items-center justify-between gap-2 flex-wrap">
                <h1 className="text-2xl font-bold text-gray-700">Admin - Donation Campaigns</h1>
                <form onSubmit={handleSearch} className="flex items-center space-x-2">
    <input
        type="text"
        placeholder="Search by title..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="border rounded p-2 text-sm"
    />
    <select
        value={selectedStatus}
        onChange={(e) => setSelectedStatus(e.target.value)}
        className="border rounded p-2 text-sm"
    >
        <option value="">All Statuses</option>
        <option value="pending_approval">Pending Approval</option>
        <option value="approved">Approved</option>
        <option value="rejected">Rejected</option>
        <option value="live">Live</option>
        <option value="paused">Paused</option>
        <option value="closed">Closed</option>
        <option value="successful">Successful</option>
        <option value="suspended">Suspended</option>
    </select>
    <button
        type="submit"
        disabled={searching}
        className="px-3 py-2 bg-emerald-500 text-white text-sm rounded flex items-center disabled:opacity-50"
    >
                        {searching ? (
                            <>
                                <FaSpinner className="animate-spin mr-1" />
                                Searching
                            </>
                        ) : (
                            <>
                                <FaSearch className="mr-1" />
                                Search
                            </>
                        )}
                    </button>
                </form>
            </div>

            {/* TABLE LIST */}
            <div className="overflow-x-auto bg-white border rounded-md shadow-sm">
                <table className="min-w-full text-sm text-left">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-3 py-2 border-b">Title</th>
                            <th className="px-3 py-2 border-b">Owner Email</th>
                            <th className="px-3 py-2 border-b">#Donations</th>
                            <th className="px-3 py-2 border-b">Raised</th>
                            <th className="px-3 py-2 border-b">Goal</th>
                            <th className="px-3 py-2 border-b">Days Left</th>
                            <th className="px-3 py-2 border-b">Status</th>
                            <th className="px-3 py-2 border-b">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaigns.map((c) => {
                            const daysLeft = getDaysLeft(c.endDate);
                            return (
                                <tr key={c._id} className="hover:bg-gray-50">
                                    <td className="px-3 py-2 border-b">{c.campaignTitle}</td>
                                    <td className="px-3 py-2 border-b">
                                        {c.ownerDoc?.email || "N/A"}
                                        {c.ownerDoc?.isPremium && (
                                            <span className="ml-1 text-green-600 font-semibold">✔</span>
                                        )}
                                    </td>
                                    <td className="px-3 py-2 border-b text-center">{c.totalDonations || 0}</td>
                                    <td className="px-3 py-2 border-b">${c.currentAmount}</td>
                                    <td className="px-3 py-2 border-b">${c.fundingGoal}</td>
                                    <td className="px-3 py-2 border-b">{daysLeft}d</td>
                                    <td className="px-3 py-2 border-b">{c.status}</td>
                                    <td className="px-3 py-2 border-b">
                                        <div className="flex items-center space-x-3">
                                            <button
                                                className="text-blue-600 text-sm flex items-center"
                                                onClick={() => window.open(`/donation/${c.slug}`, "_blank")}
                                            >
                                                <FaEye className="mr-1" />
                                                View
                                            </button>
                                            <button
                                                className="text-indigo-600 text-sm flex items-center"
                                                onClick={() => openDetailsModal(c)}
                                            >
                                                <FaPlus className="mr-1" />
                                                Details
                                            </button>
                                            <button
                                                className="text-red-600 text-sm flex items-center disabled:opacity-50"
                                                onClick={() => openDeleteModal(c)}
                                            >
                                                <FaTrash className="mr-1" />
                                                Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        {campaigns.length === 0 && (
                            <tr>
                                <td colSpan={8} className="text-center py-4 text-gray-500">
                                    No campaigns found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* PAGINATION */}
            <div className="mt-4 flex items-center justify-center space-x-2">
    <button
        disabled={page <= 1}
        onClick={() => setPage((p) => p - 1)}
        className="px-3 py-1 border rounded disabled:opacity-50"
    >
        Prev
    </button>

    {generatePageNumbers().map((pageNumber, index) =>
        pageNumber === '...' ? (
            <span key={index} className="px-2 py-1">
                ...
            </span>
        ) : (
            <button
                key={index}
                onClick={() => setPage(pageNumber)}
                className={`px-3 py-1 border rounded ${
                    page === pageNumber ? 'bg-emerald-500 text-white' : ''
                }`}
            >
                {pageNumber}
            </button>
        )
    )}

    <button
        disabled={page >= totalPages}
        onClick={() => setPage((p) => p + 1)}
        className="px-3 py-1 border rounded disabled:opacity-50"
    >
        Next
    </button>
</div>
            {/* ============ DELETE CONFIRMATION MODAL ============ */}
            {showDeleteModal && campaignToDelete && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
                    onClick={closeDeleteModal}
                >
                    <div
                        className="bg-white rounded-lg p-6 w-full max-w-sm relative"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={closeDeleteModal}
                            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                        >
                            <FaTimes />
                        </button>
                        <h2 className="text-lg font-bold text-gray-700 mb-3">Confirm Delete</h2>
                        <p className="text-sm text-gray-600 mb-4">
                            Are you sure you want to delete{" "}
                            <strong>{campaignToDelete.campaignTitle}</strong>?
                        </p>
                        <div className="flex items-center justify-end space-x-2">
                            <button
                                onClick={closeDeleteModal}
                                className="px-4 py-2 text-sm bg-gray-200 rounded hover:bg-gray-300 text-gray-700"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmDelete}
                                disabled={deletingId === campaignToDelete._id}
                                className="px-4 py-2 text-sm bg-red-500 text-white rounded hover:bg-red-600 disabled:opacity-70 flex items-center"
                            >
                                {deletingId === campaignToDelete._id && (
                                    <FaSpinner className="animate-spin mr-2" />
                                )}
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* ============ DETAILS MODAL ============ */}
            {showDetailsModal && detailsCampaign && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4"
                    onClick={closeDetailsModal}
                >
                    <div
                        className="relative bg-white w-full max-w-lg p-6 rounded-lg shadow-xl max-h-[80vh] overflow-y-auto"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Close Button */}
                        <button
                            onClick={closeDetailsModal}
                            className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
                        >
                            <FaTimes />
                        </button>

                        <h2 className="text-xl font-bold mb-4 text-gray-800">
                            {detailsCampaign.campaignTitle}
                        </h2>

                        {/* Stats Section */}
                        <div className="bg-gradient-to-r from-emerald-50 to-blue-50 rounded-lg p-4 mb-4 shadow-inner">
                            {/* Use a responsive grid: one column on extra-small screens, two on small+ */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 text-sm text-gray-700">
                                {/* Current Likes */}
                                <div className="flex flex-col p-2 border rounded-md">
                                    <strong className="text-xs text-gray-500">Current Likes</strong>
                                    <span>{detailsCampaign.currentLikes ?? 0}</span>
                                </div>

                                {/* Target Likes */}
                                <div className="flex flex-col p-2 border rounded-md">
                                    <div className="flex justify-between items-center mb-1">
                                        <strong className="text-xs text-gray-500">Target Likes</strong>
                                        {editingTargets ? (
                                            <input
                                                type="number"
                                                value={tempTargets.likes}
                                                onChange={(e) =>
                                                    setTempTargets((prev) => ({
                                                        ...prev,
                                                        likes: parseInt(e.target.value) || 0,
                                                    }))
                                                }
                                                className="w-full sm:w-20 px-1 py-0.5 text-xs border rounded"
                                            />
                                        ) : (
                                            <span>{detailsCampaign.targetLikes ?? 0}</span>
                                        )}
                                    </div>
                                    <span className="text-xs text-gray-400">
                                        Remaining:{" "}
                                        {Math.max(
                                            0,
                                            (detailsCampaign.targetLikes || 0) -
                                            (detailsCampaign.currentLikes || 0)
                                        )}
                                    </span>
                                </div>

                                {/* Current Views */}
                                <div className="flex flex-col p-2 border rounded-md">
                                    <strong className="text-xs text-gray-500">Current Views</strong>
                                    <span>{detailsCampaign.views ?? 0}</span>
                                </div>

                                {/* Target Views */}
                                <div className="flex flex-col p-2 border rounded-md">
                                    <div className="flex justify-between items-center mb-1">
                                        <strong className="text-xs text-gray-500">Target Views</strong>
                                        {editingTargets ? (
                                            <input
                                                type="number"
                                                value={tempTargets.views}
                                                onChange={(e) =>
                                                    setTempTargets((prev) => ({
                                                        ...prev,
                                                        views: parseInt(e.target.value) || 0,
                                                    }))
                                                }
                                                className="w-full sm:w-20 px-1 py-0.5 text-xs border rounded"
                                            />
                                        ) : (
                                            <span>{detailsCampaign.targetViews ?? 0}</span>
                                        )}
                                    </div>
                                    <span className="text-xs text-gray-400">
                                        Remaining:{" "}
                                        {Math.max(
                                            0,
                                            (detailsCampaign.targetViews || 0) -
                                            (detailsCampaign.views || 0)
                                        )}
                                    </span>
                                </div>

                                {/* Current Amount */}
                                <div className="flex flex-col p-2 border rounded-md">
                                    <strong className="text-xs text-gray-500">Current Amount</strong>
                                    <span>${detailsCampaign.currentAmount ?? 0}</span>
                                </div>

                                {/* Target Amount */}
                                <div className="flex flex-col p-2 border rounded-md">
                                    <div className="flex justify-between items-center mb-1">
                                        <strong className="text-xs text-gray-500">Target Amount</strong>
                                        {editingTargets ? (
                                            <input
                                                type="number"
                                                value={tempTargets.amount}
                                                onChange={(e) =>
                                                    setTempTargets((prev) => ({
                                                        ...prev,
                                                        amount: parseInt(e.target.value) || 0,
                                                    }))
                                                }
                                                className="w-full sm:w-20 px-1 py-0.5 text-xs border rounded"
                                            />
                                        ) : (
                                            <span>${detailsCampaign.targetAmount ?? 0}</span>
                                        )}
                                    </div>
                                    <span className="text-xs text-gray-400">
                                        Remaining: $
                                        {Math.max(
                                            0,
                                            (detailsCampaign.targetAmount || 0) -
                                            (detailsCampaign.currentAmount || 0)
                                        )}
                                    </span>
                                </div>

                                {/* Daily Cap */}
                                <div className="flex flex-col p-2 border rounded-md">
                                    <div className="flex justify-between items-center mb-1">
                                        <strong className="text-xs text-gray-500">Daily Cap</strong>
                                        {editingTargets ? (
                                            <input
                                                type="number"
                                                value={tempTargets.dailyCap}
                                                onChange={(e) =>
                                                    setTempTargets((prev) => ({
                                                        ...prev,
                                                        dailyCap: parseInt(e.target.value) || 0,
                                                    }))
                                                }
                                                className="w-full sm:w-20 px-1 py-0.5 text-xs border rounded"
                                            />
                                        ) : (
                                            <span>${detailsCampaign.trackingDoc?.currentDailyCap ?? 0}</span>
                                        )}
                                    </div>
                                    <span className="text-xs text-gray-400">
                                        Used Today: $
                                        {detailsCampaign.trackingDoc?.donationsGeneratedToday ?? 0}
                                    </span>
                                </div>
                            </div>

                            {/* Edit/Save Controls */}
                            <div className="mt-3 flex justify-end gap-2">
                                {editingTargets ? (
                                    <>
                                        <button
                                            onClick={() => setEditingTargets(false)}
                                            className="px-2 py-1 text-xs bg-gray-200 rounded hover:bg-gray-300"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={handleUpdateTargets}
                                            disabled={detailActionLoading}
                                            className="px-2 py-1 text-xs bg-emerald-500 text-white rounded hover:bg-emerald-600 flex items-center"
                                        >
                                            {detailActionLoading && (
                                                <FaSpinner className="animate-spin mr-1" />
                                            )}
                                            Save Targets
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        onClick={() => setEditingTargets(true)}
                                        className="px-2 py-1 text-xs bg-blue-500 text-white rounded hover:bg-blue-600"
                                    >
                                        Edit Targets
                                    </button>
                                )}
                            </div>
                        </div>

                        {/* Notifications Toggle */}
                        <div className="flex items-center justify-between mb-4">
                            <label className="text-sm font-medium text-gray-700">
                                Notifications
                            </label>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    disabled={detailActionLoading}
                                    checked={detailsCampaign.notificationsEnabled}
                                    onChange={() => toggleNotifications(detailsCampaign._id)}
                                    className="sr-only peer"
                                />
                                <div
                                    className={`w-11 h-6 rounded-full peer peer-focus:ring-4 peer-focus:ring-emerald-300 transition-colors ${detailsCampaign.notificationsEnabled
                                        ? "bg-emerald-500"
                                        : "bg-gray-300"
                                        }`}
                                ></div>
                                <span
                                    className={`peer-checked:translate-x-5 absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform ${detailActionLoading && "opacity-50"
                                        }`}
                                ></span>
                            </label>
                        </div>

                        {/* Pause/Resume Switch */}
                        {["live", "approved", "paused"].includes(detailsCampaign.status) && (
                            <div className="flex items-center justify-between mb-4">
                                <label className="text-sm font-medium text-gray-700">
                                    {detailsCampaign.status === "paused"
                                        ? "Resume Campaign"
                                        : "Pause Campaign"}
                                </label>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        disabled={detailActionLoading}
                                        checked={detailsCampaign.status !== "paused"}
                                        onChange={() => handlePauseResume(detailsCampaign)}
                                        className="sr-only peer"
                                    />
                                    <div
                                        className={`w-11 h-6 rounded-full peer peer-focus:ring-4 peer-focus:ring-yellow-300 transition-colors ${detailsCampaign.status !== "paused"
                                            ? "bg-yellow-500"
                                            : "bg-gray-300"
                                            }`}
                                    ></div>
                                    <span
                                        className={`peer-checked:translate-x-5 absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform ${detailActionLoading && "opacity-50"
                                            }`}
                                    ></span>
                                </label>
                            </div>
                        )}

                        {/* Admin Status Update */}
                        <div className="border-t pt-4 mt-4">
                            <label className="text-sm font-medium text-gray-700 mb-1 block">
                                Update Status
                            </label>
                            <p className="text-xs text-gray-500 mb-2">
                                Pick a new status and click "Save" to confirm.
                            </p>
                            <div className="flex items-center space-x-2">
                                <select
                                    disabled={detailActionLoading}
                                    value={detailsCampaign.status}
                                    onChange={(e) => handleStatusChange(e.target.value)}
                                    className="border p-1 rounded text-sm"
                                >
                                    <option value="pending_approval">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                    <option value="live">Live</option>
                                    <option value="paused">Paused</option>
                                    <option value="closed">Closed</option>
                                    <option value="successful">Successful</option>
                                    <option value="suspended">Suspended</option>
                                </select>
                                {detailActionLoading && (
                                    <FaSpinner className="animate-spin text-emerald-500" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* ============ STATUS CONFIRMATION MODAL ============ */}
            {showStatusModal && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
                    onClick={closeStatusModal}
                >
                    <div
                        className="bg-white rounded-lg p-6 w-full max-w-sm relative"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={closeStatusModal}
                            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                        >
                            <FaTimes />
                        </button>
                        <h2 className="text-lg font-bold text-gray-700 mb-3">Confirm Status</h2>
                        <p className="text-sm text-gray-600 mb-4">
                            Are you sure you want to set the campaign status to{" "}
                            <strong>{pendingStatus}</strong>?
                        </p>
                        <div className="flex items-center justify-end space-x-2">
                            <button
                                onClick={closeStatusModal}
                                className="px-4 py-2 text-sm bg-gray-200 rounded hover:bg-gray-300 text-gray-700"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmStatusUpdate}
                                disabled={statusUpdateLoading}
                                className="px-4 py-2 text-sm bg-emerald-500 text-white rounded hover:bg-emerald-600 disabled:opacity-70 flex items-center"
                            >
                                {statusUpdateLoading && (
                                    <FaSpinner className="animate-spin mr-2" />
                                )}
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminDonationCampaignList;
