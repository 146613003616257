import React from 'react';
import {
  FaRegClock,
  FaDollarSign,
  FaRegPaperPlane,
  FaRegCheckCircle,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaDownload,
  FaHandshake,
  FaChartLine
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function HowToApply() {
  const navigate = useNavigate();

  const steps = [
    {
      id: 'step1',
      title: 'Who Qualifies for Verdant Charity Grants in North America & UK?',
      description:
        `Verdant Charity empowers businesses registered in the United States (including territories), Canada (all provinces), and the United Kingdom (England, Scotland, Wales, Northern Ireland). To be eligible, your organization must:\n\n• Be legally registered in your operating jurisdiction\n• Demonstrate clear social/environmental impact\n• Have commercial operations between 6-36 months\n• Maintain good standing with local regulators (SEC/FCA/FINTRAC)\n• Show potential for sustainable growth\n\nWe prioritize underrepresented entrepreneurs and green initiatives across all regions.`,
      image: 'https://images.unsplash.com/photo-1518609878373-06d740f60d8b',
      icon: (
        <div className="bg-emerald-100 bg-opacity-40 p-4 rounded-full">
          <FaRegCheckCircle className="text-emerald-600 text-3xl" />
        </div>
      ),
    },
    {
      id: 'step2',
      title: 'What Funding Can Businesses Access?',
      description:
        `Our multi-currency grants support growth at every stage:\n\n• Seed Funding: $5,000-$15,000 USD | £4,000-£12,000 | CAD 6,500-19,500\n• Growth Funding: $16,000-$35,000 USD | £13,000-£28,000 | CAD 21,000-46,000\n• Scale Funding: $36,000-$50,000 USD | £29,000-£40,000 | CAD 47,000-65,000\n\nFunds are disbursed through secure local transfers within 5 business days of approval. All awards include complimentary access to our North American/UK mentorship network.`,
      image: 'https://bridgingthegap.org/wp-content/uploads/2023/10/HTA_Timber-Valley_Alpha-Kappa-Alpha_April-15-2023.jpg',
      icon: (
        <div className="bg-blue-100 bg-opacity-40 p-4 rounded-full">
          <FaDollarSign className="text-blue-600 text-3xl" />
        </div>
      ),
    },
    {
      id: 'step3',
      title: 'Our Streamlined Cross-Border Application Process',
      description:
        `Three-Step Regional Application:\n\n1. Preliminary Assessment (8 minutes)\n   - Verify business registration\n   - Confirm jurisdiction compliance\n2. Impact Proposal (12 minutes)\n   - Outline your social/environmental mission\n   - Present financial projections\n3. Document Submission (5 minutes)\n   - Upload regional compliance certificates\n   - Provide executive team profiles\n\nOur platform auto-converts currencies and complies with GDPR/CCPA regulations.`,
      image: 'https://images.unsplash.com/photo-1504384308090-c894fdcc538d',
      icon: (
        <div className="bg-purple-100 bg-opacity-40 p-4 rounded-full">
          <FaRegClock className="text-purple-600 text-3xl" />
        </div>
      ),
    },
    {
      id: 'step4',
      title: 'Transparent Review Process & Timeline',
      description:
        `Regional Evaluation Protocol:\n\n• US Applications: Reviewed within 24hrs (EST business hours)\n• CA Applications: Processed within 36hrs (CST/MST/PST)\n• UK Applications: Assessed within 48hrs (GMT/BST)\n\n92% of complete applications receive preliminary feedback within our service level agreement. Need urgent review? Contact our North American (1-888-555-1234) or UK (+44 20 1234 5678) priority lines.`,
      image: 'https://www.brec.org/assets/volunteer/GroupBeautificationProject.jpg',
      icon: (
        <div className="bg-yellow-100 bg-opacity-40 p-4 rounded-full">
          <FaRegPaperPlane className="text-yellow-600 text-3xl" />
        </div>
      ),
    },
    {
      id: 'step5',
      title: 'Start Your Application in 3 Simple Steps',
      description:
        `1. Create Your Regional Profile (2 minutes)\n   - Select country: US/CA/UK\n   - Choose preferred currency\n2. Activate Grant Dashboard\n   - Access sector-specific templates\n   - Save progress across timezones\n3. Submit & Track\n   - Real-time status updates\n   - Direct regulator Q&A access\n\nOver 1,300 businesses launched successfully through our program last fiscal year. Join our 89% success rate cohort today.`,
      image: 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2',
      icon: (
        <div className="bg-teal-100 bg-opacity-40 p-4 rounded-full">
          <FaRegCheckCircle className="text-teal-600 text-3xl" />
        </div>
      ),
    },
  ];

  const handleApplyNow = () => navigate('/signup');

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = 'https://verdantcharity.s3.us-east-1.amazonaws.com/grant_application.pdf';
    link.download = 'grant_application.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="relative">
        <img
          src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f"
          alt="Verdant Charity Business Grants"
          className="w-full h-96 object-cover"
        />
        <div className="absolute inset-0 bg-emerald-700 bg-opacity-50 flex flex-col items-center justify-center px-4">
          <h1 className="text-4xl md:text-6xl text-white font-bold text-center mb-4">
            Strategic Growth Funding for US, Canadian & UK Businesses
          </h1>
          <p className="text-xl text-white mb-8 text-center max-w-3xl">
            SEC-Compliant (US) • FINTRAC-Registered (CA) • FCA-Authorized (UK)
          </p>
          <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 w-full max-w-md">
            <button
              onClick={handleApplyNow}
              className="w-auto sm:flex-1 px-6 py-3 bg-white text-emerald-700 rounded-lg font-semibold uppercase tracking-widest
               hover:bg-emerald-600 hover:text-white transition duration-300 flex items-center justify-center whitespace-nowrap"
            >
              <FaRegPaperPlane className="mr-2" />
              <span>Start Application</span>
            </button>
            <button
              onClick={handleDownload}
              className="w-auto sm:flex-1 px-6 py-3 bg-white text-emerald-700 rounded-lg font-semibold uppercase tracking-widest
               hover:bg-emerald-600 hover:text-white transition duration-300 flex items-center justify-center whitespace-nowrap"
            >
              <FaDownload className="mr-2" />
              <span>Download Guide</span>
            </button>
          </div>
        </div>
      </div>

      {/* Content Section - Maintained original structure with enhanced text */}
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        {steps.map((step, index) => (
          <div
            key={index}
            id={step.id}
            className={`flex flex-col md:flex-row items-center mb-16 ${index % 2 === 0 ? '' : 'md:flex-row-reverse'}`}
          >
            <div className="md:w-1/2">
              <img
                src={step.image}
                alt={step.title}
                className="w-full h-80 object-cover rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300"
              />
            </div>
            <div className="md:w-1/2 md:pl-12 md:pr-12 mt-8 md:mt-0">
              <div className="flex items-center">
                {step.icon}
                <h2 className="text-2xl md:text-3xl font-bold text-emerald-700 ml-3">
                  {step.title}
                </h2>
              </div>
              <p className="mt-4 text-gray-700 whitespace-pre-line">
                {step.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Strategic CTA */}
      <div className="relative bg-gray-900 py-28 overflow-hidden">
        <div className="absolute inset-0 opacity-20">
          <img
            src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40"
            className="w-full h-full object-cover"
            alt="Background"
          />
        </div>
        <div className="max-w-4xl mx-auto text-center relative z-10 px-4">
          <FaHandshake className="text-6xl text-emerald-500 mx-auto mb-8" />
          <h2 className="text-4xl font-bold text-white mb-6">
            83% of Applicants Secure Funding - Will You Be Next?
          </h2>
          <div className="flex flex-col md:flex-row justify-center gap-6">
            <button
              onClick={handleApplyNow}
              className="bg-emerald-600 hover:bg-emerald-700 text-white px-12 py-5 rounded-xl
                       font-semibold text-lg transition-colors duration-300 flex items-center"
            >
              <FaChartLine className="mr-3" />
              Begin Application (US/CA/UK)
            </button>
          </div>
          <p className="mt-6 text-gray-300">
            Average completion time: 22 minutes | 98% satisfaction rate
          </p>
        </div>
      </div>

      {/* Compliance Footer - Maintained structure with enhanced content */}
      <footer className="bg-gray-800 text-gray-400 py-12">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
            {/* Regional Contacts */}
            <div>
              <h3 className="text-white font-semibold mb-4">Regional Support</h3>
              <ul className="space-y-2">
                <li>US: 1-888-555-1234</li>
                <li>CA: 1-800-555-5678</li>
                <li>UK: +44 20 1234 5678</li>
                <li>24/5 Multilingual Support</li>
              </ul>
            </div>
            
            {/* Compliance Badges */}
            <div>
              <h3 className="text-white font-semibold mb-4">Regulatory Compliance</h3>
              <ul className="space-y-2">
                <li>SEC 506(c) Verified</li>
                <li>FINTRAC MSB Registration</li>
                <li>FCA EMIR Compliance</li>
                <li>GDPR Data Protection</li>
              </ul>
            </div>

            {/* Success Metrics */}
            <div>
              <h3 className="text-white font-semibold mb-4">2024 Impact</h3>
              <ul className="space-y-2">
                <li>$47M Awarded</li>
                <li>1.2K Businesses Funded</li>
                <li>92% Renewal Rate</li>
                <li>4.8/5 Client Rating</li>
              </ul>
            </div>

            {/* Social Proof */}
            <div>
              <h3 className="text-white font-semibold mb-4">As Seen In</h3>
              <div className="flex space-x-4">
                <span className="text-sm">Forbes Impact</span>
                <span className="text-sm">FT Adviser</span>
                <span className="text-sm">Globe & Mail</span>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-700 pt-8 text-center">
            <p className="text-sm">
              © {new Date().getFullYear()} Verdant Charity Holdings. 
              NASDAQ: VCTY | CSE: VCT | LSE: VCH
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default HowToApply;