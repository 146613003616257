import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { FaSpinner, FaTimes, FaInfoCircle } from 'react-icons/fa';

const UserTransactions = ({ userId }) => {
    const { user } = useUser();
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/api/customer/${userId}/transactions`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                if (response.status === 200) {
                    setTransactions(response.data);
                } else {
                    toast.error('Failed to load transaction data');
                }
            } catch (error) {
                toast.error(`Failed to load transaction data: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTransactions();
    }, [userId, user.token]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const DetailRow = ({ label, value }) => (
        <div className="border-b border-dashed py-1">
            <div className="grid grid-cols-3 text-xs">
                <span className="font-medium">{label}:</span>
                <span className="col-span-2">{value || 'N/A'}</span>
            </div>
        </div>
    );

    const getStatusColor = (status) => {
        switch(status.toLowerCase()) {
            case 'completed': return 'bg-green-100 text-green-800';
            case 'failed': return 'bg-red-100 text-red-800';
            case 'pending': return 'bg-yellow-100 text-yellow-800';
            default: return 'bg-gray-100 text-gray-800';
        }
    };

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Transaction History</h3>
            
            <div className="border border-dashed border-gray-200 rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full min-w-[800px]">
                        <thead className="bg-gray-50">
                            <tr>
                                {['Transaction ID', 'From', 'To', 'Type', 'Amount', 'Status', 'Date', 'Action'].map((header) => (
                                    <th key={header} className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="8" className="px-3 py-4 text-center">
                                        <FaSpinner className="animate-spin mx-auto text-gray-400" />
                                    </td>
                                </tr>
                            ) : transactions.length > 0 ? (
                                transactions.map((transaction) => (
                                    <tr key={transaction.transactionId} className="border-t border-dashed hover:bg-gray-50">
                                        <td className="px-3 py-2 text-xs text-gray-700">{transaction.transactionId}</td>
                                        <td className="px-3 py-2 text-xs text-gray-700">{transaction.senderFirstName}</td>
                                        <td className="px-3 py-2 text-xs text-gray-700">{transaction.receiverFirstName}</td>
                                        <td className="px-3 py-2 text-xs text-gray-700">{transaction.transactionType}</td>
                                        <td className="px-3 py-2 text-xs font-medium text-gray-700">
                                            {transaction.currency || '$'} {transaction.amount?.toFixed(2)}
                                        </td>
                                        <td className="px-3 py-2">
                                            <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${getStatusColor(transaction.status)}`}>
                                                {transaction.status}
                                            </span>
                                        </td>
                                        <td className="px-3 py-2 text-xs text-gray-700">
                                            {formatDate(transaction.createdAt)}
                                        </td>
                                        <td className="px-3 py-2">
                                            <button 
                                                onClick={() => setSelectedTransaction(transaction)}
                                                className="text-xs text-blue-600 hover:text-blue-800 flex items-center gap-1"
                                            >
                                                <FaInfoCircle className="text-xs" />
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="px-3 py-4 text-center text-xs text-gray-500">
                                        No transaction records found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Transaction Detail Modal */}
            {selectedTransaction && (
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-4 w-full max-w-xl">
                        <div className="flex justify-between items-center mb-3 pb-2 border-b border-dashed">
                            <div className="flex items-center gap-2">
                                <div className={`p-1 rounded-full ${getStatusColor(selectedTransaction.status)}`}>
                                    <FaInfoCircle className="text-xs" />
                                </div>
                                <h3 className="text-sm font-semibold">Transaction Details</h3>
                            </div>
                            <button 
                                onClick={() => setSelectedTransaction(null)}
                                className="text-gray-400 hover:text-gray-600"
                            >
                                <FaTimes className="text-sm" />
                            </button>
                        </div>

                        <div className="space-y-2 text-xs">
                            <DetailRow label="Transaction ID" value={selectedTransaction.transactionId} />
                            <DetailRow label="Type" value={selectedTransaction.transactionType} />
                            
                            <div className="border-b border-dashed py-1">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">From:</span>
                                    <span className="col-span-2">
                                        {selectedTransaction.senderFirstName} ({selectedTransaction.senderAccountId})
                                    </span>
                                </div>
                            </div>
                            
                            <div className="border-b border-dashed py-1">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">To:</span>
                                    <span className="col-span-2">
                                        {selectedTransaction.receiverFirstName} ({selectedTransaction.receiverAccountId})
                                    </span>
                                </div>
                            </div>

                            <DetailRow label="Amount" value={`${selectedTransaction.currency || '$'} ${selectedTransaction.amount?.toFixed(2)}`} />
                            <DetailRow label="Fee" value={`${selectedTransaction.currency || '$'} ${selectedTransaction.fee?.toFixed(2) || '0.00'}`} />
                            <DetailRow label="Net Amount" value={`${selectedTransaction.currency || '$'} ${(selectedTransaction.amount - (selectedTransaction.fee || 0))?.toFixed(2)}`} />
                            <DetailRow label="Currency" value={selectedTransaction.currency} />
                            <DetailRow label="Reference" value={selectedTransaction.reference} />
                            <DetailRow label="Description" value={selectedTransaction.description} />
                            <DetailRow label="Initiated At" value={formatDate(selectedTransaction.createdAt)} />
                            <DetailRow label="Completed At" value={selectedTransaction.completedAt ? formatDate(selectedTransaction.completedAt) : 'N/A'} />
                            
                            <div className="pt-2 border-t border-dashed">
                                <div className="grid grid-cols-3">
                                    <span className="font-medium">Status:</span>
                                    <span className={`col-span-2 ${getStatusColor(selectedTransaction.status).replace('bg-', 'text-')}`}>
                                        {selectedTransaction.status}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserTransactions;